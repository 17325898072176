import React from "react";
import {Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    SubTitleMatchDetails,
    TitleMatchDetails
} from "../../../styles/adminArea/matchesDetailsStyles/MatchesDetailsScreenStyles";
import {Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {
    colorBlue,
    colorBlueGreen,
    colorBluePurple,
    colorPrimary,
    colorPrimaryTranslucid,
    colorTernary
} from "../../../EnvironmentVars";
import {DataGridStyled} from "../../../styles/adminArea/companiesDetailsScreen/CompanyDetailsScreenStyles";
import IconButton from "@material-ui/core/IconButton";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const matchesDataGridcolumns = [
    {
        field: 'companyOneName',
        headerName: 'Entidad 1',
        description: 'Nombre de la compañía uno',
        flex: 1,
        valueGetter: (params) =>
            `${params.getValue('companyOne').name}`
    },
    {
        field: 'companyOnePosition',
        headerName: 'Posición 1',
        description: 'Posición de la compañía uno',
        flex: 0.5,
    },
    {
        field: 'companyTwoName',
        headerName: 'Entidad 2',
        description: 'Nombre de la compañía dos',
        flex: 1,
        valueGetter: (params) =>
            `  ${params.getValue('companyTwo').name}`
    },
    {
        field: 'companyTwoPosition',
        headerName: 'Posición 2',
        description: 'Posición de la compañía dos',
        flex: 0.5,
    },

    {
        field: 'matchType',
        headerName: 'Tipo de match',
        width: 150,
        valueGetter: (params) => {
            if (params.value === "ASSET") {
                return "Activo"
            } else if (params.value === "CAPACITY") {
                return "Capacidad"
            } else if (params.value === "TECHLINE") {
                return "Línea tecnológica"
            }
        },

    },
    {
        field: 'matchLevel',
        headerName: 'Nivel de match',
        flex: 1
    },
    {
        field: 'matchingElementName',
        headerName: 'Elemento coincidente',
        flex: 1
    },
    {
        field: 'managed',
        headerName: 'Gestionado',
        valueGetter: (params) => {
            if (params.value === true) {
                return "Sí"
            } else {
                return "No"
            }
        }
    },
    {
        field: 'achieved',
        headerName: 'Satisfactorio',
        valueGetter: (params) => {
            if (params.value === true) {
                return "Sí"
            } else {
                return "No"
            }
        }
    },
    {
        field: 'matchDate',
        headerName: 'Fecha',
        width: 110,
        valueGetter: (params) => {
            let dateString = new Date(params.value).toLocaleDateString();
            let dateArray = dateString.split("T");
            return dateArray[0];

        }
    },
];

const barColors = [colorBlueGreen, colorBlue, colorBluePurple]

export const MatchesDetailsScreenView = (props) => {

    return (
        <Grid container spacing={0} align={"center"} justify={"center"}>

            {
                props.matchesByTypeData && !props.loading ?
                    <>
                        <Grid item xs={12}>
                            <TitleMatchDetails>Detalles de matches</TitleMatchDetails>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SubTitleMatchDetails>Matches por nivel</SubTitleMatchDetails>
                            <ResponsiveContainer width="80%" height="75%">
                                <BarChart
                                    data={props.matchesByTypeData}
                                >
                                    <XAxis dataKey="name"/>
                                    <YAxis dataKey="value"/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip cursor={{fill: colorPrimaryTranslucid}}/>
                                    <defs>
                                        <linearGradient
                                            id="colorsPlatform"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="100%"
                                            spreadMethod="reflect"
                                        >
                                            <stop offset="0" stopColor={colorPrimary}/>
                                            <stop offset="1" stopColor={colorTernary}/>
                                        </linearGradient>
                                    </defs>
                                    <Bar
                                        dataKey="value"
                                        strokeWidth={1}
                                    >
                                        {
                                            props.matchesByTypeData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={barColors[index % 20]}/>
                                            ))
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SubTitleMatchDetails>Matches Gestionados</SubTitleMatchDetails>
                            <ResponsiveContainer width="80%" height="75%">
                                <BarChart
                                    data={props.matchesManagedAndAchieved}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="name"/>
                                    <YAxis/>
                                    <Tooltip cursor={{fill: colorPrimaryTranslucid}}/>
                                    <Legend/>
                                    <defs>
                                        <linearGradient
                                            id="colorsPlatform"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="100%"
                                            spreadMethod="reflect"
                                        >
                                            <stop offset="0" stopColor={colorPrimary}/>
                                            <stop offset="1" stopColor={colorTernary}/>
                                        </linearGradient>
                                    </defs>
                                    <Bar dataKey="Gestionados" stackId="a" fill={colorBlue}/>
                                    <Bar dataKey="Satisfactorios" stackId="a" fill={colorBluePurple}/>
                                    <Bar dataKey="Fallidos" stackId="a" fill={colorTernary}/>
                                    <Bar dataKey="Total" stackId="a" fill="url(#colorsPlatform)"/>
                                </BarChart>
                            </ResponsiveContainer>
                        </Grid>
                    </>
                    : <CircularProgress/>
            }
            {props.matches && !props.loading ?
                <>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <IconButton variant="contained" style={{}} onClick={props.handleCSVDownload}>
                            <DownloadForOfflineIcon sx={{color: colorPrimary}}/>&nbsp;CSV
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{height: "100vh", width: '100%'}}>
                            <DataGridStyled
                                rows={props.matches}
                                columns={matchesDataGridcolumns}
                                pageSize={22}
                                loading={props.loading}
                                color={'linear-gradient(52deg, rgba(118,181,157,1) 0%, rgba(204,252,203,1) 100%)'}
                            />
                        </div>
                    </Grid>
                </>
                :
                <Grid item xs={12}>
                    <CircularProgress/>
                </Grid>
            }
        </Grid>
    )
}