import React, {useEffect} from 'react';
import NavBar from "./components/commonComponents/NavBar";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import AccessPoint from "./components/commonComponents/accountAccessComponents/AccessPoint";
import CompanyCard from "./components/userArea/companyCardComponents/CompanyCard";
import Forbidden from "./components/commonComponents/userManagementComponents/Forbidden";
import CompanyForm from "./components/userArea/companyCardComponents/CompanyForm";
import StepperTechLineForm from "./components/userArea/stepperTechLineFormComponents/StepperTechLine";
import {CompanyProvider} from "./context/CompanyContext";
import {UserContextProvider} from "./context/UserContext";
import StepperAsset from "./components/userArea/stepperAssetFormComponents/StepperAsset";
import StepperCapacity from "./components/userArea/stepperCapacitiesFormComponents/StepperCapacity";
import AdditionalInfoForPartnershipForm
    from "./components/userArea/companyCardComponents/AdditionalInfoForPartnershipForm";
import ReactGa from "react-ga"
import TrackEvent from "./tracking/EventTracking"
import Footer from "./components/commonComponents/Footer";
import Home from "./components/commonComponents/homeComponents/Home";
import theme from "./Theme";
import {ThemeProvider} from "@material-ui/styles";
import ResetPasswordChangePassword
    from "./components/commonComponents/userManagementComponents/ResetPasswordChangePassword";
import PrivateRoute from "./components/commonComponents/userManagementComponents/PrivateRoute";
import AdminRoute from "./components/commonComponents/userManagementComponents/AdminRoute";
import AdminHome from "./components/adminArea/AdminHome/AdminHome";
import {MatchesPanel} from "./components/adminArea/matchesPanelComponents/MatchesPanel";
import {MatchesDnDContextProvider} from "./context/MatchesDnDContext";
import {MatchesDetailsScreen} from "./components/adminArea/matchesDetailsComponents/MatchesDetailsScreen";
import {CompaniesDetailsScreen} from "./components/adminArea/companiesDetailsScreen/CompaniesDetailsScreen";
import {TechLinesDetailsScreen} from "./components/adminArea/techlinesDetailsScreen/TechLinesDetailsScreen";
import {
    MatchesCompletedDetailsScreen
} from "./components/adminArea/matchesCompletedDetailsScreen/MatchesCompletedDetailsScreen";
import {StatisticsScreen} from "./components/adminArea/statisticsScreen/StatisticsScreen";
import {InformationSourcesScreen} from "./components/adminArea/informationSources/informationSourcesScreen";
import AssetsInfoCompanyForm from "./components/userArea/companyCardComponents/AssetsInfoCompanyForm";
import CapacitiesInfoCompanyForm from "./components/userArea/companyCardComponents/CapacitiesInfoCompanyForm";
import {CapacitiesInfoDetailsScreen} from "./components/adminArea/CapacitiesInfoDetailsScreen";


function App() {

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            ReactGa.initialize('UA-183506891-1');
            ReactGa.pageview(window.location.pathname + window.location.search);
            TrackEvent("Visited_page", "Visitada página home", "Visited_page");
        }
    }, [window.location.pathname + window.location.search])

    return (
        <div className={"mainPage"}>
            <ThemeProvider theme={theme}>
                <UserContextProvider>
                    <Router>
                        <div className={"navBar"}>
                            <NavBar/>
                        </div>
                        <div className={"contentArea"}>
                            <Switch>
                                <Route exact path='/' component={Home}/>
                                <Route path="/login" component={AccessPoint}/>
                                <Route path="/changepassword/**" component={ResetPasswordChangePassword}/>
                                <Route
                                    path="/company"
                                    render={routeProps => (
                                        <CompanyProvider>
                                            <CompanyCard {...routeProps} />
                                        </CompanyProvider>
                                    )}
                                />
                                <PrivateRoute path="/additionalinfo" component={AdditionalInfoForPartnershipForm}/>
                                <PrivateRoute path="/assetsinfo" component={AssetsInfoCompanyForm}/>
                                <PrivateRoute path="/capacitiesinfo" component={CapacitiesInfoCompanyForm}/>
                                <PrivateRoute path="/companyform" component={CompanyForm}/>
                                <PrivateRoute
                                    path="/steppertechlineform"
                                    render={routeProps => (
                                        <CompanyProvider>
                                            <StepperTechLineForm {...routeProps} />
                                        </CompanyProvider>
                                    )}
                                />
                                <Route
                                    path="/stepperassetform"
                                    render={routeProps => (
                                        <CompanyProvider>
                                            <StepperAsset {...routeProps} />
                                        </CompanyProvider>
                                    )}
                                />
                                <Route
                                    path="/steppercapacityform"
                                    render={routeProps => (
                                        <CompanyProvider>
                                            <StepperCapacity {...routeProps} />
                                        </CompanyProvider>
                                    )}
                                />
                                <PrivateRoute path={"/informationsources"} component={InformationSourcesScreen}/>
                                <AdminRoute path="/admin" component={AdminHome}/>
                                <AdminRoute path={"/capacitiesinfocompaniesdetail"}
                                            component={CapacitiesInfoDetailsScreen}/>
                                <MatchesDnDContextProvider>
                                    <AdminRoute path={"/matchespanel"} component={MatchesPanel}/>
                                    <AdminRoute path={"/matchesdetailsscreen"} component={MatchesDetailsScreen}/>
                                    <AdminRoute path={"/matchescompleteddetailsscreen"}
                                                component={MatchesCompletedDetailsScreen}/>
                                    <AdminRoute path={"/companiesdetailsscreen"} component={CompaniesDetailsScreen}/>
                                    <AdminRoute path={"/techlinesdetailsscreen"} component={TechLinesDetailsScreen}/>
                                    <AdminRoute path={"/statisticsscreen"} component={StatisticsScreen}/>

                                </MatchesDnDContextProvider>


                                <Route path="/forbidden" component={Forbidden}/>
                            </Switch>
                        </div>
                    </Router>
                    <div className={"footer"}>
                        <Footer/>
                    </div>
                </UserContextProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
