import React, {useEffect, useState} from "react";
import SearchInputView from "../../../views/commonArea/utilComponentViews/SearchInputView";

function SearchInputOnBack(props) {
    const [searchText, setSearchText] = useState("");
    const [timeOutSearch, setTimeOutSearch] = useState(0)

    useEffect(() => {
            clearTimeout(timeOutSearch);
        }
        , []
    )

    function handleDeleteSearchInput() {
        setSearchText("")
    }

    async function getFilteredTechLines(textEntered) {
        if (timeOutSearch) {
            clearTimeout(timeOutSearch)
        }

        setTimeOutSearch(setTimeout(async () => {
                    if (textEntered.length === 0) {
                    } else {
                        await props.getDataFromBack(textEntered);
                    }
                }, 800
            )
        )

    }


    async function handleChangeSearchText(event) {
        let textEntered = event.target.value;

        setSearchText(textEntered);

        if (textEntered.length >= 4 || textEntered.length === 0) {
            await getFilteredTechLines(textEntered)
        }
    }

    return (
        <SearchInputView
            handleDeleteSearchInput={handleDeleteSearchInput}
            handleChangeSearchText={handleChangeSearchText}
            searchText={searchText}
        />
    )
}

export default SearchInputOnBack;