import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from 'axios';

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? "https://mapitec.es/backend" : "http://localhost:8080/backend";
axios.defaults.headers.post["Content-Type"] = 'application/json; charset=utf-8';
axios.defaults.headers.get["Content-Type"] = 'application/json; charset=utf-8';
axios.defaults.responseType = "json";

axios.interceptors.request.use(
    config => {
        config.headers.Authorization = localStorage.getItem("token")
        return config;
    },
    error => Promise.reject(error)
);

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
