import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {SliderCustom} from "../../stepperTechLineFormViews/SliderCustom";

export default function CapacitiesInfoCompanyValoration(props, classes) {

    props = props.props;
    return (
        <Grid container spacing={2} align={"left"} alignItems={"center"}>
            <Grid item xs={12} align={"center"} style={{margin: "30px", marginTop: "10px"}}>
                <h6 component={'div'}>
                    Valore el nivel de fortaleza de su empresa en relación a la competencia en las siguientes
                </h6>
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad para anticipar cambios tecnológicos
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"anticipateTechChangesCapacity"}
                              value={props.anticipateTechChangesCapacity}
                />
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad para diversificar en nuevos negocios
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"newBusinessDiversificationCapacity"}
                              value={props.newBusinessDiversificationCapacity}
                />
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad para la digitalización de los procesos
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"digitalizationCapacity"}
                              value={props.digitalizationCapacity}
                />
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad para impulsar proyectos de sostenibilidad
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"sustainabilityProjectsCapacity"}
                              value={props.sustainabilityProjectsCapacity}
                />
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad para lograr altos niveles de calidad
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"highQualityLevelsCapacity"}
                              value={props.highQualityLevelsCapacity}
                />
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad creativa e innovadora
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"innovationAndCreativityCapacity"}
                              value={props.innovationAndCreativityCapacity}
                />
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad para combinar las tecnologías y conocimiento de otros agentes con los propios
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"combinationKnowledgeWithOthersCapacity"}
                              value={props.combinationKnowledgeWithOthersCapacity}
                />
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad para asimilar las experiencias de éxito y mejores prácticas de innovación
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"useOfExperiencesToImproveCapacity"}
                              value={props.useOfExperiencesToImproveCapacity}
                />
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad para lanzar rápidamente nuevos productos al mercado
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"quickReleaseProductsCapacity"}
                              value={props.quickReleaseProductsCapacity}
                />
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad de resolución de problemas técnicos
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"techProblemsResolutionCapacity"}
                              value={props.techProblemsResolutionCapacity}
                />
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad para generar fidelidad y confianza en los clientes
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"fidelityCapacity"}
                              value={props.fidelityCapacity}
                />
            </Grid>

            <Grid item xs={6} md={6} align={"right"}>
                <Typography component={'div'}>
                    Capacidad para crear reputación corporativa
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <SliderCustom control={props.control} name={"createReputationCapacity"}
                              value={props.createReputationCapacity}
                />
            </Grid>

        </Grid>
    );
}