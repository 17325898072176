import {Droppable} from "react-beautiful-dnd";
import React from "react";
import {ColumnDivStyles, DivButtonAddCards} from "../../../styles/adminArea/matchesPanelStyles/ColumnDivStyles";
import {Decoration, Title} from "../../../styles/adminArea/matchesPanelStyles/CommonMatchesPanelStyles";
import {MatchCard} from "../../../components/adminArea/matchesPanelComponents/MatchCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import {colorPrimary, colorSecondary} from "../../../EnvironmentVars";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import {Add} from "@material-ui/icons";

export const ColumnView = (props) => {

    return (
        <Droppable droppableId={props.columnId}>
            {
                provided => (
                    <ColumnDivStyles
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        <Title>
                            {props.columns[props.columnId].title}
                            <Decoration style={{background: props.columns[props.columnId].backgroundColor}}/>
                        </Title>
                        {props.matches !== undefined ?
                            props.matches.filter((match) => match.matchStatus === props.columns[props.columnId].columnType).sort((first, second) => first.index > second.index ? 1 : -1).map((match, index) => {
                                if (match.matchStatus === props.columns[props.columnId].columnType)
                                    return (
                                        <MatchCard key={match.id} match={match} index={index}/>
                                    )
                            }) :
                            <CircularProgress/>
                        }
                        <div>&nbsp;&nbsp;</div>
                        <div>&nbsp;&nbsp;</div>
                        <div>&nbsp;&nbsp;</div>
                        {props.columns[props.columnId].columnType === "INITIAL" && (
                            <Grid item xs={12}>
                                <DivButtonAddCards>
                                    <IconButton aria-label="add"
                                                onClick={(event) => props.onAddNewCardsToKanban(event)}
                                                style={{
                                                    color: "white",
                                                    background: "linear-gradient(45deg, " + colorPrimary + " -40%," + colorSecondary + " 120%)",
                                                    boxShadow: "10px 10px 11px #d9d9d9, -10px -10px 11px #ffffff"
                                                }}>
                                        <Add fontSize={"large"}/>
                                    </IconButton>
                                </DivButtonAddCards>
                            </Grid>
                        )
                        }
                        {provided.placeholder}
                    </ColumnDivStyles>
                )
            }
        </Droppable>
    )
}