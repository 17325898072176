import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {Add} from "@material-ui/icons";
import AdditionalInfoForPartnershipStyles
    from "../../../../styles/userArea/companyCardComponentsStyles/AdditionalInfoForPartnershipStyles";
import Typography from "@material-ui/core/Typography";
import {Accordion, AccordionDetails, Grid} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function CapacitiesResumeInfoView(props) {
    const classes = AdditionalInfoForPartnershipStyles();
    return (
        <Grid container>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12} style={{marginTop: '20px'}}>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container align={"center"} alignItems={"center"} spacing={0}>
                            <Grid item xs={2}>
                                <IconButton aria-label="add" onClick={props.addAdditionalInfo}>
                                    <Add/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography>Información de capacidades</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>

                        <ul>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Resolución
                                    problemas: {props.techProblemsResolutionCapacity ? props.techProblemsResolutionCapacity : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Generar fidelidad: {props.fidelityCapacity ? props.fidelityCapacity : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Cambios
                                    tecnológicos: {props.anticipateTechChangesCapacity ? props.anticipateTechChangesCapacity : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Diversificar: {props.newBusinessDiversificationCapacity ? props.newBusinessDiversificationCapacity : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Digitalización: {props.digitalizationCapacity ? props.digitalizationCapacity : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Sostenibilidad: {props.sustainabilityProjectsCapacity ? props.digitalizationCapacity : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Calidad: {props.highQualityLevelsCapacity ? props.highQualityLevelsCapacity : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Innovación
                                    : {props.innovationAndCreativityCapacity ? props.innovationAndCreativityCapacity : 0}
                                </Typography>
                            </li>

                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Combinar
                                    tecnologías: {props.combinationKnowledgeWithOthersCapacity ? props.combinationKnowledgeWithOthersCapacity : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Uso de
                                    experiencias: {props.useOfExperiencesToImproveCapacity ? props.useOfExperiencesToImproveCapacity : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Lanzamiento: {props.quickReleaseProductsCapacity ? props.quickReleaseProductsCapacity : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Lanzamiento: {props.createReputationCapacity ? props.createReputationCapacity : 0}
                                </Typography>
                            </li>
                        </ul>

                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    )
}

export default CapacitiesResumeInfoView;