import React, {useEffect, useState} from "react";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import AssetsInfoFormView
    from "../../../views/userArea/companyCardComponentsViews/assetsCapacitiesInfo/AssetsInfoFormView";
import axios from "axios";


function AssetsInfoCompanyForm(props) {
    const [loading, setLoading] = useState(true);
    const [patents, setPatents] = useState();
    const [software, setSoftware] = useState();
    const [dataBases, setDataBases] = useState();
    const [tradeMark, setTradeMark] = useState();
    const [certifications, setCertifications] = useState();

    let validationSchema = yup.object().shape(
        {
            certifications: yup.number().transform((currentValue, originalValue) => {
                return originalValue === '' ? null : currentValue;
            }).notRequired().nullable().test(
                'Is positive?',
                'ERROR: El número debe ser positivo!',
                (value) => value >= 0
            ),
            tradeMark: yup.number().transform((currentValue, originalValue) => {
                return originalValue === '' ? null : currentValue;
            }).notRequired().nullable().test(
                'Is positive?',
                'ERROR: El número debe ser positivo!',
                (value) => value >= 0
            ),
            dataBases: yup.number().transform((currentValue, originalValue) => {
                return originalValue === '' ? null : currentValue;
            }).notRequired().nullable().test(
                'Is positive?',
                'ERROR: El número debe ser positivo!',
                (value) => value >= 0
            ),
            software: yup.number().transform((currentValue, originalValue) => {
                return originalValue === '' ? null : currentValue;
            }).notRequired().nullable().test(
                'Is positive?',
                'ERROR: El número debe ser positivo!',
                (value) => value >= 0
            ),
            patents: yup.number().transform((currentValue, originalValue) => {
                return originalValue === '' ? null : currentValue;
            }).notRequired().nullable().test(
                'Is positive?',
                'ERROR: El número debe ser positivo!',
                (value) => value >= 0
            ),
        }
    );

    const {register, handleSubmit, errors, control} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    useEffect(async () => {
        await getAssetsInfoCompany();
        setLoading(false);
    }, [])

    async function getAssetsInfoCompany() {
        const url = "assetsinfocompany/" + props.location.state.company.id
        let assetsInfoResponse = await axios({
            method: 'GET',
            url,
            data: {},
        })
        setValuesOfAssets(assetsInfoResponse.data);
    }


    function setValuesOfAssets(assetsCapacitiesData) {
        setPatents(assetsCapacitiesData.patents);
        setSoftware(assetsCapacitiesData.software);
        setDataBases(assetsCapacitiesData.dataBases);
        setTradeMark(assetsCapacitiesData.tradeMark);
        setCertifications(assetsCapacitiesData.certifications);
    }

    async function customSubmit(data) {
        let assetsCompany = {
            companyId: props.location.state.company.id,
            ...data
        }

        await axios.post("/assetsinfocompany", assetsCompany);

        props.history.push("company")

    }

    return (
        <ThemeProvider theme={theme}>
            <AssetsInfoFormView
                loading={loading}
                handleSubmit={handleSubmit}
                customSubmit={customSubmit}
                register={register}
                errors={errors}
                control={control}

                patents={patents}
                software={software}
                dataBases={dataBases}
                tradeMark={tradeMark}
                certifications={certifications}
            />
        </ThemeProvider>
    );
}

export default AssetsInfoCompanyForm