import React from "react";
import {FormControlLabel, Grid} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";

function CollaborationAgentsView(props) {
    return (
        <Grid container justify={"center"} alignItems={"center"} alignContent={"center"}>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={props.register}
                            onChange={(event) => props.handleChange(event)}
                            color="primary"
                            icon={<RadioButtonUncheckedOutlinedIcon/>}
                            checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                            checked={props.chosenOptions.techInstitutes}
                        />

                    }

                    name={props.nameOfOptions + "['techInstitutes']"}
                    label="Institutos tecnológicos"

                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={props.register}
                            onChange={(event) => props.handleChange(event)}
                            color="primary"
                            icon={<RadioButtonUncheckedOutlinedIcon/>}
                            checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                            checked={props.chosenOptions.otherCompanies}
                        />
                    }
                    name={props.nameOfOptions + "['otherCompanies']"}
                    label="Otras empresas"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={props.register}
                            onChange={(event) => props.handleChange(event)}
                            color="primary"
                            icon={<RadioButtonUncheckedOutlinedIcon/>}
                            checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                            checked={props.chosenOptions.universities}
                        />
                    }
                    name={props.nameOfOptions + "['universities']"}
                    label="Universidades y otros centros de I+D"

                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={props.register}
                            onChange={(event) => props.handleChange(event)}
                            color="primary"
                            icon={<RadioButtonUncheckedOutlinedIcon/>}
                            checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                            checked={props.chosenOptions.startUps}
                        />
                    }
                    name={props.nameOfOptions + "['startUps']"}
                    label="Start-ups y/o Spin off de universidades"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={props.register}
                            onChange={(event) => props.handleChange(event)}
                            color="primary"
                            icon={<RadioButtonUncheckedOutlinedIcon/>}
                            checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                            checked={props.chosenOptions.specializedCentres}
                        />
                    }
                    name={props.nameOfOptions + "['specializedCentres']"}
                    label="Centros especializados de I+D+I públicos o privados"

                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={props.register}
                            onChange={(event) => props.handleChange(event)}
                            color="primary"
                            icon={<RadioButtonUncheckedOutlinedIcon/>}
                            checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                            checked={props.chosenOptions.managementEntities}
                        />
                    }
                    name={props.nameOfOptions + "['managementEntities']"}
                    label="Entidades de gestión de las áreas empresariales"

                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={props.register}
                            onChange={(event) => props.handleChange(event)}
                            color="primary"
                            icon={<RadioButtonUncheckedOutlinedIcon/>}
                            checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                            checked={props.chosenOptions.europeanCentres}
                        />
                    }
                    name={props.nameOfOptions + "['europeanCentres']"}
                    label="Centros Europeos de Empresas Innovadoras"

                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={props.register}
                            onChange={(event) => props.handleChange(event)}
                            color="primary"
                            icon={<RadioButtonUncheckedOutlinedIcon/>}
                            checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                            checked={props.chosenOptions.businessAssociations}
                        />
                    }
                    name={props.nameOfOptions + "['businessAssociations']"}
                    label="Asociaciones empresariales"

                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            inputRef={props.register}
                            name={props.nameOfOptions + "['others']"}
                            onChange={(event) => props.handleChange(event)}
                            color="primary"
                            icon={<RadioButtonUncheckedOutlinedIcon/>}
                            checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                            checked={props.chosenOptions.others}
                        />
                    }
                    name={props.nameOfOptions + "['others']"}
                    label="Otros"
                />
            </Grid>
        </Grid>

    )
}

export default CollaborationAgentsView