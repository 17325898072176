import {makeStyles} from "@material-ui/core/styles";
import {colorPrimary, colorSecondary} from "../../../EnvironmentVars";

const forbiddenStyles = makeStyles((theme) => ({

    forbiddenWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "300px"
    },
    buttons: {
        background: 'linear-gradient(45deg, ' + colorPrimary + ', ' + colorSecondary + '  200%)',
    }

}));
export default forbiddenStyles;