import {createContext, useEffect, useState} from "react";
import axios from "axios";
import {colorPrimary} from "../EnvironmentVars";
import Alert from "sweetalert2";

export const MatchesDnDContext = createContext({});

const columnTypes = {
    INITIAL: "INITIAL",
    CONTACTED_FIRST_COMPANY: "CONTACTED_FIRST_COMPANY",
    CONTACTED_SECOND_COMPANY: "CONTACTED_SECOND_COMPANY",
    FINISHED: "FINISHED"
}

let columnsData = {
    'initial': {
        id: 'initial',
        title: 'Creado',
        columnType: columnTypes.INITIAL,
        backgroundColor: "linear-gradient(52deg, rgba(138,196,255,1) 0%, rgba(204,252,203,1) 100%)"
    },
    'contactedFirstCompany': {
        id: 'contactedFirstCompany',
        title: 'Contacto primera empresa',
        columnType: columnTypes.CONTACTED_FIRST_COMPANY,
        backgroundColor: "linear-gradient(52deg, rgba(204,252,203,1) 0%, rgba(118,181,157,1) 100%)"

    },
    'contactedSecondCompany': {
        id: 'contactedSecondCompany',
        title: 'Contacto segunda empresa',
        columnType: columnTypes.CONTACTED_SECOND_COMPANY,
        backgroundColor: "linear-gradient(52deg, rgba(118,181,157,1) 0%, rgba(171, 224, 229,1) 100%)"
    },
    'finished': {
        id: 'finished',
        title: 'Finalizado contacto',
        columnType: columnTypes.FINISHED,
        backgroundColor: "linear-gradient(52deg, rgba(171, 224, 229,1) 0%, rgba(145, 146, 204,1)  100%)"

    },
    columnOrder: ['initial', 'contactedFirstCompany', "contactedSecondCompany", "finished"]
}

export const MatchesDnDContextProvider = (props) => {
    const [matches, setMatches] = useState();
    const [columns, setColumns] = useState(columnsData);
    let matchesAux = matches ? [...matches] : [];
    const [loading, setLoading] = useState(false);

    async function getMatchesInKanban() {
        setLoading(true);
        let data = {};
        const url = "matches/matchesinkanban"
        await axios({
            method: 'GET',
            url,
            data: data,
        }).then(
            res => {
                setMatches(res.data);
                matchesAux = [...res.data]
                setLoading(false);
            },
            error => {
                Alert.fire({
                        title: 'Ha ocurrido un error al cargar los matches',
                        text: "Si persiste el problema contacta con servicio técnico",
                        icon: 'error',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch(
            error => {
            }
        )
    }

    useEffect(async () => {
        await getMatchesInKanban();
    }, []);

    function updateMatchColumnState(matchId, oldColumn, newColumn, oldIndex, newIndex) {
        let columnChange = true;
        for (let i = 0; i < matchesAux.length; i++) {
            if (matchesAux[i].id + "" === matchId) {
                matchesAux[i].matchStatus = newColumn.columnType;
            }
        }

        updateMatchIndexState(matchId, oldIndex, newIndex, newColumn.columnType, columnChange, oldColumn, newColumn)
    }

    function updateMatchIndexState(matchId, oldIndex, newIndex, newColumnType, columnChange, oldColumn) {
        let matchesFiltered = matchesAux.filter((match) => match.id + "" === matchId)
        let changingColumn = columnChange ? columnChange : false;
        let match = matchesFiltered[0]
        if (match !== undefined) {
            for (let i = 0; i < matchesAux.length; i++) {
                let matchToUpdate = matchesAux[i];
                if (matchToUpdate.id === match.id) {
                    matchToUpdate.index = newIndex;
                    matchToUpdate.modified = true;
                } else if (changingColumn && oldColumn && oldColumn.columnType === matchToUpdate.matchStatus && matchToUpdate.index > oldIndex && matchToUpdate.index !== 0) {
                    matchToUpdate.index -= 1
                    matchToUpdate.modified = true;
                } else if (changingColumn && newColumnType === matchToUpdate.matchStatus && matchToUpdate.index >= newIndex) {
                    matchToUpdate.index += 1
                    matchToUpdate.modified = true;
                } else if (newIndex < oldIndex && !changingColumn && newColumnType === matchToUpdate.matchStatus && matchToUpdate.index >= newIndex && matchToUpdate.index <= oldIndex) {
                    matchToUpdate.index += 1
                    matchToUpdate.modified = true;
                } else if (newIndex > oldIndex && !changingColumn && newColumnType === matchToUpdate.matchStatus && matchToUpdate.index <= newIndex && matchToUpdate.index !== 0) {
                    matchToUpdate.index -= 1
                    matchToUpdate.modified = true;
                }

            }
        }
        setMatches(matchesAux);
    }

    async function updateMatchDataInBack() {
        let matchData = matchesAux.filter(match => match.modified);
        await axios.put("/matches/updatematchesinkanban", matchData)
            .then(res => {
                },
                error => {
                    if (error.response.status === 403) {
                        this.navigateToForbidden();
                    }
                }
            ).catch(error => {
                }
            );
    }

    return (
        <MatchesDnDContext.Provider
            value={{
                matches,
                setMatches,
                columns,
                setColumns,
                updateMatchColumnState,
                updateMatchIndexState,
                updateMatchDataInBack,
                getMatchesInKanban,
                columnTypes
            }}>
            {props.children}
        </MatchesDnDContext.Provider>
    );
}