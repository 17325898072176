import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {ATagRrssStyled} from "./ATagRrssStyled";

export default function RrssView() {
    return (
        <>
            <div style={{display: "inline-block", color: "#ffffff"}}> Síguenos en:
            </div>
            <ATagRrssStyled href={"https://www.instagram.com/"} target={"_blank"}>
                <InstagramIcon/>
                Instagram
            </ATagRrssStyled>
            <ATagRrssStyled href={"https://www.facebook.com/"} target={"_blank"}>
                <FacebookIcon/>
                Facebook
            </ATagRrssStyled>
            <ATagRrssStyled href={"https://www.linkedin.com/"} target={"_blank"}>
                <LinkedInIcon/>
                Linkedin
            </ATagRrssStyled>
            <ATagRrssStyled href={"https://twitter.com/"} target={"_blank"}>
                <TwitterIcon/>
                Twitter
            </ATagRrssStyled>
        </>
    )
}