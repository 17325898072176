import axios from "axios";
import {useState} from "react";

function LoginService() {

    const [isLogged, setIsLogged] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState();

    const login = async function (username, password) {
        const data = {username: username, password: password};
        await axios.post("/login", data)
            .then((res) => {
                    let jwtRetrieved = res.headers.authorization
                    let userRolesRetrieved = res.headers.user_roles;
                    localStorage.setItem("authorities", userRolesRetrieved);
                    localStorage.setItem("token", jwtRetrieved);
                    localStorage.setItem("username", data.username);
                    setIsLogged(true);
                },
                error => {
                    setIsLogged(false);
                    localStorage.removeItem("token");
                    localStorage.removeItem("authorities");
                    if (error.response.status === 403) {
                        setLoginErrorMessage("El usuario o la contraseña son incorrectos")
                        console.error("Error 403")
                    }
                }
            ).catch(error => {

                }
            );
    }

    const logout = function () {
        localStorage.clear();
        setIsLogged(false);
    }

    return {
        isLogged,
        login,
        logout,
        loginErrorMessage,
    }
}

export default LoginService;