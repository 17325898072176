import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import StepperFormView from "../../../views/userArea/stepperViews/StepperFormView";
import CapacitiesForm from "./CapacitiesForm";
import CapacitiesGeneralForm from "./CapacitiesGeneralForm";
import axios from "axios";
import TrackEvent from "../../../tracking/EventTracking";

export default function StepperCapacity(props) {
    function getSteps() {
        return ['Capacidades', 'información'];
    }

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const history = useHistory();
    const [capacityForm, setCapacityForm] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            localStorage.removeItem("capacityForm");
            localStorage.removeItem("capacityGeneralForm");
            history.push("company")
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleReset = async () => {
        setLoading(true)
        let capacityCompany = {
            techLineCompany: {id: capacityForm.capacityData.techLineId},
            companyId: capacityForm.capacityData.companyId,
            capacityId: capacityForm.capacityData.capacityId,
            name: capacityForm.capacityData.name,
            collaborationWillingness: capacityForm.capacityGeneralData.collaborationWillingness,
            collaborationWillingnessPreferenceCapacity: capacityForm.capacityGeneralData.collaborationWillingnessPreferenceCapacity,
            collabsWithCapacity: capacityForm.capacityGeneralData.collabsWithCapacity,
            hasCollabs: capacityForm.capacityGeneralData.hasCollabs === "1", true: false,
            amountOfPeopleRelated: capacityForm.capacityGeneralData.amountOfPeopleRelated,
            importanceOfCapacity: capacityForm.capacityGeneralData.importanceOfCapacity
        }
        await axios.post("/capacitiescompany", capacityCompany)
            .then(res => {
                    TrackEvent("store_capacity", "Guarda capacidad", "store_capacity");
                    props.handleNext();
                },
                error => {
                    if (error.response.status === 403) {
                        this.navigateToForbidden();
                    }
                }
            ).catch(error => {
                }
            );
        localStorage.removeItem("capacityForm");
        localStorage.removeItem("capacityGeneralForm");
        setLoading(false);
        history.push('company')
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <CapacitiesForm
                    capacityForm={capacityForm}
                    setCapacityForm={setCapacityForm}
                    activeStep={activeStep}
                    handleNext={handleNext}
                    techLineId={props.techLineId ? props.techLineId : localStorage.getItem("techLineId")}
                    company={props.company ? props.company : localStorage.getItem("company")}
                />;
            case 1:
                return <CapacitiesGeneralForm
                    capacityForm={capacityForm}
                    setCapacityForm={setCapacityForm}
                    activeStep={activeStep}
                    handleNext={handleNext}
                />;

            default:
                return 'Unknown stepIndex';
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <StepperFormView
                steps={steps}
                activeStep={activeStep}
                handleReset={handleReset}
                getStepContent={getStepContent}
                handleBack={handleBack}
                handleNext={handleNext}
                loading={loading}
            />
        </ThemeProvider>
    );
}