import React, {useState} from "react";
import {AccountContext} from "../../../context/AccountContext";
import {expandingTransition} from "../../../styles/commonArea/accountAccessStyles/AccountBoxStyles";
import {AccountBoxView} from "../../../views/commonArea/accountAccessViews/AccountBoxView";

export function AccountBox(props) {
    const [isExpanded, setExpanded] = useState(false);
    const [active, setActive] = useState("signin");

    const playExpandingAnimation = () => {
        setExpanded(true);
        setTimeout(() => {
            setExpanded(false);
        }, expandingTransition.duration * 1000 - 1500);
    };

    const switchToSignup = () => {
        playExpandingAnimation();
        setTimeout(() => {
            setActive("signup");
        }, 500);
    };

    const switchToSignin = () => {
        playExpandingAnimation();
        setTimeout(() => {
            setActive("signin");
        }, 600);
    };


    const switchToResetPassword = () => {
        playExpandingAnimation();
        setTimeout(() => {
            setActive("resetPassword");
        }, 600);
    };

    const contextValue = {switchToSignup, switchToSignin, switchToResetPassword};

    return (
        <AccountContext.Provider value={contextValue}>
            <AccountBoxView
                isExpanded={isExpanded}
                active={active}
            />
        </AccountContext.Provider>
    );
}