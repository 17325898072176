import styled from "styled-components";
import {colorPrimary, colorSecondary} from "../../../EnvironmentVars";
import {motion} from "framer-motion";

export const BoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MutedLink = styled.a`
  font-size: 14px;
  color: rgba(200, 200, 200, 1);
  font-weight: 800;
  text-decoration: none;
  &:hover {
    color: ${colorPrimary};
  }
`;

export const TextGrey = styled.div`
  font-size: 14px;
  color: rgba(200, 200, 200, 1);
  font-weight: 800;
  text-decoration: none;

`;


export const BoldLink = styled(motion.li)`
  list-style-type: none;
  padding: 0;
  font-size: 16px;
  color: ${colorPrimary};
  font-weight: 800;
  text-decoration: none;
  cursor: pointer;
  margin: 0 4px;
  border-radius: 10px;
    &:hover {
    color: ${colorPrimary};
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 42px;
  outline: none;
  border: 1px solid rgba(200, 200, 200, 0.3);
  padding: 0px 10px;
  transition: all 200ms ease-in-out;
  font-size: 12px;
  padding-bottom:10px;
  &::placeholder {
    color: ${colorPrimary};
  }
  &:not(:last-of-type) {
  }
  &:focus {
    outline: none;
    border-bottom: 5px solid #76b59d;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 11px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border: none;
  border-radius: 100px 100px 100px 100px;
  cursor: pointer;
  transition: all, 240ms ease-in-out;
  background: rgb(241, 196, 15);
  background: linear-gradient(52deg, ${colorPrimary} 0%, ${colorSecondary} 120%);
  &:hover {
    filter: brightness(1.09);
  }
`;