import {makeStyles} from "@material-ui/core/styles";
import {teal} from '@material-ui/core/colors'

const techLineStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flexGrow: 1,
        alignContent: 'Center'

    },
    accordionSummaryNameContainer: {
        width: "90%",
        textAlign: "center",
    },

    accordionSummaryName: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        padding: '2px',
    },

    // assetsExpanText: {
    //     textSize: "10px"
    // },

    button: {
        "&:hover": {
            backgroundColor: "transparent"
        },
    },

    hrActive: {
        border: "2px solid " + teal[400],
        backgroundColor: teal[400],
        borderRadius: "8px",
        margin: '20px 20px'
    },

    pTechLineSeparator: {
        fontSize: '8px'
    },

    buttonGroup: {},

    editButton: {
        padding: "0px 1px"
    },

    deleteButton: {
        padding: "0px 15px 0px 0px"
    }


}));
export default techLineStyles;