import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import axios from "axios";
import CapacitiesFormView from "../../../views/userArea/stepperCapacitiesFormViews/CapacitiesFormView";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";

function CapacitiesForm(props) {

    const [capacities, setCapacities] = useState(undefined);
    const [selectedCapacityId, setSelectedCapacityId] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [capacityApplicant, setCapacityApplicant] = useState(false)

    let validationSchema = yup.object().shape(
        {
            selected: yup.string().required(),
        }
    );

    const {register, handleSubmit, errors, control} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    function handleChangeItemId(capacityId) {
        setSelectedCapacityId(capacityId);
    }

    function handleCheckCapacityApplicant(event) {
        setCapacityApplicant(event.target.checked)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (capacityApplicant) {
            Alert.fire({
                    text: "Se actualizado y modificado el formulario",
                    icon: 'success',
                    timer: 2500,
                    showConfirmButton: false,
                    iconColor: colorPrimary
                }
            )
        }
    }, [capacityApplicant]);

    useEffect(() => {

            async function getCapacities() {
                const url = "capacities"
                await axios({
                    method: 'GET',
                    url,
                    data: {},
                }).then(
                    res => {
                        setCapacities(res.data)
                        setLoading(false)
                    },
                    error => {

                    }
                ).catch(
                    error => {
                        console.error(error)
                    }
                )
            }

            getCapacities();
            let chosenCapacityId = selectedCapacityId ? selectedCapacityId : localStorageChosen !== null ? localStorageChosen.capacityId : "";
            let chosenCapacityApplicant = capacityApplicant ? capacityApplicant : localStorageChosen !== null ? localStorageChosen.capacityApplicant : false;
            setSelectedCapacityId(chosenCapacityId);
            setCapacityApplicant(chosenCapacityApplicant);
        }
        , [selectedCapacityId]);

    function customSubmit(data) {
        let company = JSON.parse(props.company);
        if (company === undefined) {
            company = JSON.parse(localStorage.getItem("company"));
        }
        let techLineId = props.techLineId;
        if (techLineId === undefined) {
            techLineId = JSON.parse(localStorage.getItem("techLineId"));
        }
        let capacityData = {
            companyId: company.id,
            techLineId: techLineId,
            name: data.selected,
            capacityId: selectedCapacityId,
            capacityApplicant: capacityApplicant
        };
        props.setCapacityForm({...props.capacityForm, capacityData: capacityData});
        localStorage.setItem("capacityForm", JSON.stringify(capacityData));
        setSelectedCapacityId(capacityData.capacityId)
        setCapacityApplicant(capacityApplicant)
        props.handleNext();
    }

    let localStorageChosen = JSON.parse(localStorage.getItem("capacityForm"));
    let chosen = localStorageChosen !== null ? localStorageChosen.name : "";

    return (
        <ThemeProvider theme={theme}>
            {
                loading ?
                    <CircularProgress color="primary"/>
                    :
                    <CapacitiesFormView handleSubmit={handleSubmit} control={control} register={register}
                                        customSubmit={customSubmit}
                                        errors={errors}
                                        handleChangeItemId={handleChangeItemId}
                                        capacities={capacities}
                                        selectedCapacityId={chosen}
                                        capacityApplicant={capacityApplicant}
                                        handleCheckCapacityApplicant={handleCheckCapacityApplicant}
                    />
            }
        </ThemeProvider>
    );
}

export default CapacitiesForm;