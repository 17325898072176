import styled from "styled-components";
import Button from "@material-ui/core/Button";
import {colorPrimary} from "../../../EnvironmentVars";

export const ButtonStyled = styled(Button)`
&&{
background:transparent;
text-color: ${colorPrimary};
box-shadow:none;
}
`