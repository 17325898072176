import styled from "styled-components";
import {colorPrimary, colorTernary} from "../../../EnvironmentVars";

export const CustomToolTipStyled = styled.div`
  border-radius: 15px;
  padding: 30px;
  border: 5px solid ${colorTernary};
  margin: 10px;
  min-height: 100px;
  background: white;
  color: ${colorPrimary};
  font-weight: 800;
  font-size: 15px;
  text-align: left;
  line-height: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`