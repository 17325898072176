import React, {useEffect, useState} from "react";
import CompanyFormView from "../../../views/userArea/companyCardComponentsViews/CompanyFormView";
import axios from "axios";
import LoadingComponent from "../../commonComponents/userManagementComponents/LoadingComponent";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useHistory} from 'react-router-dom'
import {allCompanyTypes} from "../StepperComponents/companyTypes";

export default function CompanyForm(props) {

    let [name, setName] = useState("");
    let [cif, setCif] = useState("");
    let [postalCode, setPostalCode] = useState("");
    const [companyType, setCompanyType] = React.useState("");
    let [isCompanyLoaded, setCompanyLoaded] = useState("");
    let [locatedInTechPark, setLocatedInTechPark] = useState(false);
    let [existingTechParks, setExistingParks] = useState();
    let [techParkSelected, setTechParkSelected] = useState();
    let [cnae, setCnae] = useState("");
    let [web, setWeb] = useState("");
    let [numberOfWorkers, setNumberOfWorkers] = useState("");


    let history = useHistory();

    const DIG_CONTROL_CIF = 'JABCDEFGHI';
    const CIF_CON_LETRA = 'KPQRSNW';

    const cifValidator = val => {
        let cif = val;

        if (cif.length !== 9) return false;

        const cifNum = cif.substring(1);
        const cifLet = cif.charAt(0);

        let sumA = 0;
        let sumB = 0;

        for (let i = 0; i < cifNum.length - 1; i++) {
            if (i % 2 === 1) {
                sumA = sumA + parseInt(cifNum[i]);
            } else {
                let v = parseInt(cifNum[i]);
                v = v * 2;
                v = Math.floor(v / 10) + (v % 10);
                //console.log('' + cifNum[i] + ' => ' + v);
                sumB = sumB + v;
            }
        }

        let sumC = sumA + sumB;
        let sumD = 10 - (sumC % 10);

        let codControl = 'X';
        if (CIF_CON_LETRA.includes(cifLet)) {
            codControl = DIG_CONTROL_CIF.charAt(sumD);
        } else {
            codControl = sumD.toString().charAt(0);
        }

        /*console.log('sumA = ' + sumA);
        console.log('sumB = ' + sumB);
        console.log('sumC = ' + sumC);
        console.log('sumD = ' + sumD);
        console.log('codControl = ' + codControl);*/

        return cif.charAt(8) === codControl;
    }

    const companyFormValidationSchema = yup.object().shape({
        name: yup.string()
            .required("campo requerido")
            .max(500, "El campo no puede exceder de 500 caracteres "),
        cif: yup.string()
            .required("campo requerido")
            .max(9, "El campo no puede exceder de 9 caracteres ")
            .matches(/^[A-Z]\d{7}[A-Z0-9]$/, "El formato del CIF no es válido")
            .test('cif', "El CIF no es válido", cifValidator),
        otherTypeCompany: yup.string()
            .max(500, "El campo no puede exceder de 500 caracteres "),
        postalCode: yup.number()
            .required("campo requerido")
            .test('len', "El campo debe contener exactamente 5 digitos", val => !isNaN(val) && val.toString().length === 5),
        cnae: yup.number().transform((currentValue, originalValue) => {
            return originalValue === '' ? null : currentValue;
        }).required()
    })

    const {register, errors, handleSubmit, control} = useForm(
        {
            resolver: yupResolver(companyFormValidationSchema),
        }
    );

    function getTechParksData() {
        const url = "techparks/"
        axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                let techParks = res.data;
                setExistingParks(techParks);
            },
            error => {
                if (error.response.status === 403) {
                    history.push("forbidden")
                }
            }
        ).catch((error) => {
        });
    }

    useEffect(() => {
        getTechParksData();
        if (props.location.state.wantToAddNewCompany) {
            setName("")
            setCif("")
            setPostalCode("")
            setCompanyType("")
            setLocatedInTechPark(false)
            setCompanyLoaded(true)
            setCnae("")
            setNumberOfWorkers("")
            setWeb("")
        } else {
            getCompanyData()
        }
    }, [isCompanyLoaded])

    function handleChangeTextInputs(event) {
        if (event.target.type === "text") {
            switch (event.target.name) {
                case "name":
                    setName(event.target.value);
                    break;
                case "cif":
                    setCif(event.target.value);
                    break;
                case "postalCode":
                    setPostalCode(event.target.value);
                    break;
                case "cnae":
                    setCnae(event.target.value);
                    break;
                case "web":
                    setWeb(event.target.value);
                    break;
                case "numberOfWorkers":
                    setNumberOfWorkers(event.target.value);
                    break;
            }
        }
    }

    const handleChangeRadioButton = (event) => {
        setCompanyType(event.target.value);
    };

    const handleChangeIsLocatedInTechPark = (event) => {
        setLocatedInTechPark(event.target.checked);
    };

    function getObjectFromMap() {
        let objectFromMap = {};
        for (let [key, value] of allCompanyTypes) {
            objectFromMap = {...objectFromMap, [key]: value};
        }
        return objectFromMap;
    }

    function setAllCompanyTypestoFalse(map) {
        for (let [key, value] of map.entries()) {
            map.set(key, false);
        }
    }

    function customSubmit(data) {
        if (props.location.state.wantToAddNewCompany) {
            let companyToRegisterData = {
                name: name,
                cif: cif,
                postalCode: postalCode,
                [data.companyType]: true,
                locatedInTechPark: locatedInTechPark,
                techPark: data.techPark,
                cnae: data.cnae,
                web: data.web,
                numberOfWorkers: data.numberOfWorkers
            }
            axios.post("/company", companyToRegisterData)
                .then(res => {
                        history.push("company")
                    },
                    error => {
                        if (error.response.status === 403) {
                            history.push("forbidden")
                        }
                    }
                ).catch(error => {

                }
            );
        } else {
            let {id, username} = props.location.state.company;
            setAllCompanyTypestoFalse(allCompanyTypes);
            allCompanyTypes.set(data.companyType, true);
            let objectFromMap = getObjectFromMap(allCompanyTypes);
            let companyData = {
                id: id,
                username: username,
                name: name,
                cif: cif,
                postalCode: postalCode,
                locatedInTechPark: locatedInTechPark,
                techPark: data.techPark,
                cnae: data.cnae,
                web: data.web,
                numberOfWorkers: data.numberOfWorkers,
                ...objectFromMap
            }
            axios.put("/company", companyData)
                .then(res => {
                        history.push("company")
                    },
                    error => {
                        if (error.response.status === 403) {
                            history.push("forbidden")
                        }
                    }
                ).catch(error => {

                }
            );
        }
    }

    function getByValue(map, searchValue) {
        for (let [key, value] of map.entries()) {
            if (value === searchValue)
                return key;
        }
    }

    function setCompanyTypeByLocationData(company) {
        let companyTypeAttributes = new Map()
        companyTypeAttributes.set('businessAssociation', company.businessAssociation);
        companyTypeAttributes.set('centerId', company.centerId);
        companyTypeAttributes.set('entityManagementBusinessArea', company.entityManagementBusinessArea);
        companyTypeAttributes.set('europeanCenter', company.europeanCenter);
        companyTypeAttributes.set('ong', company.ong);
        companyTypeAttributes.set('prefersConsortiums', company.prefersConsortiums);
        companyTypeAttributes.set('startUpCompany', company.startUpCompany);
        companyTypeAttributes.set('technologyInstitutes', company.technologyInstitutes);
        companyTypeAttributes.set('tractingCompany', company.tractingCompany);

        return getByValue(companyTypeAttributes, true);
    }

    function getCompanyData() {
        let locationdata = props.location;
        if (locationdata.state !== undefined) {
            let company = locationdata.state.company;

            let {name, cif, postalCode} = company;
            setName(name);
            setCif(cif);
            setPostalCode(postalCode);
            let companyTypeByLocationData = setCompanyTypeByLocationData(company);
            setCompanyType(companyTypeByLocationData);
            setLocatedInTechPark(company.locatedInTechPark)
            setTechParkSelected(company.techPark)
            setCnae(company.cnae)
            setWeb(company.web)
            setNumberOfWorkers(company.numberOfWorkers);
            setCompanyLoaded(true);
        } else {
            const url = "company/"
            axios({
                method: 'GET',
                url,
                data: {},
            }).then(res => {
                    let company = res.data;
                    setName(company.name)
                    setCif(company.cif)
                    setPostalCode(company.postalCode);
                    let companyTypeByLocationData = setCompanyTypeByLocationData(company);
                    setCompanyType(companyTypeByLocationData);
                    setTechParkSelected(company.techPark)
                    setLocatedInTechPark(company.isLocatedInTechPark)
                    setCnae(company.cnae);
                    setWeb(company.web);
                    setNumberOfWorkers(company.numberOfWorkers)
                    setCompanyLoaded(true)
                },
                error => {
                    if (error.response.status === 403) {
                        history.push("forbidden")
                    }
                }
            ).catch((error) => {
            });
        }
    }

    let companyEditScreen = !isCompanyLoaded ?
        <LoadingComponent/> :
        <ThemeProvider theme={theme}>
            <CompanyFormView handleChangeTextInputs={handleChangeTextInputs}
                             handleSubmit={handleSubmit}
                             customSubmit={customSubmit}
                             name={name}
                             cif={cif}
                             postalCode={postalCode}
                             handleChangeRadioButton={handleChangeRadioButton}
                             companyType={companyType}
                             register={register}
                             errors={errors}
                             control={control}
                             isLocatedInTechPark={locatedInTechPark}
                             handleChangeIsLocatedInTechPark={handleChangeIsLocatedInTechPark}
                             techParks={existingTechParks}
                             setTechParkSelected={setTechParkSelected}
                             techParkSelected={techParkSelected}
                             cnae={cnae}
                             web={web}
                             numberOfWorkers={numberOfWorkers}
            />

        </ThemeProvider>

    return (companyEditScreen);
}