import {makeStyles} from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

const companyFormStyle = makeStyles((theme) => ({

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },

    warmText: {
        color: red[500],
        fontSize: "0.8em",
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: "0.57em"
        },
    },

    textField: {
        flexGrow: 1,
        width: 250
    },

    formFields: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "3%",
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '20px',
        },
    },

    h4Style: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '20px',
        },
    },
    //
    // checkBoxesSection:{
    //     marginLeft:"100%"
    // },
    textFieldOtherType: {
        marginLeft: "30px"
    },

    saveButton: {
        marginTop: 16,
        marginBottom: 24,
        marginLeft: 0,
    },

    paper: {
        display: 'flex',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: "center",
        alignItems: "center",
        width: '85%',
        height: 'auto',
        padding: '5px 5px',
        maxWidth: '600px',
    }

}));
export default companyFormStyle;