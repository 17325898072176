import React from "react";
import styled from "styled-components";
import {DataGrid} from "@material-ui/data-grid";

export const DataGridStyled = styled(DataGrid)`
.MuiDataGrid-colCellWrapper{
  ${({color}) => color && `
 background:${color};
    `
}
 
}
`