import React, {useContext, useEffect, useState} from "react";
import {LoginFormView} from "../../../views/commonArea/accountAccessViews/LoginFormView";
import UserContext from "../../../context/UserContext";
import {useHistory} from "react-router-dom";
import LoginService from "../../../services/loginService";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Grid} from "@material-ui/core";
import {AccountContext} from "../../../context/AccountContext";
import aos from "aos";

export function LoginForm(props) {

    const {switchToSignup, switchToResetPassword} = useContext(AccountContext);
    let [isLogin, setIsLogin] = useState(false);
    const {setJWT, setUserRoles} = useContext(UserContext);
    let history = useHistory();

    const {login, isLogged, loginErrorMessage} = LoginService();

    useEffect(() => {
        aos.init({duration: 2000})
    }, []);

    const loginSchema = yup.object().shape({
        username: yup.string()
            .required("Campo requerido")
            .min(3, "El usuario debe contener al menos 3 caracteres.")
            .max(500, "El usuario debe contener menos de 500 caracteres."),
        password: yup.string()
            .required("Campo requerido")
            .min(3, "La contraseña debe contener al menos 3 caracteres.")
            .max(500, "La contraseña debe contener menos de 500 caracteres."),
    })

    const {register, errors, handleSubmit} = useForm(
        {
            resolver: yupResolver(loginSchema),
        }
    );

    async function customSubmit(data) {
        setIsLogin(true);
        await login(data.username, data.password);
        const userRolesRetrieved = localStorage.getItem("authorities");
        const jwtToken = localStorage.getItem("token");
        setUserRoles(userRolesRetrieved);
        setJWT(jwtToken);
        setIsLogin(false);
        if (userRolesRetrieved !== null && userRolesRetrieved.includes("ADMIN")) {
            history.push("admin")
        } else if (jwtToken !== null && jwtToken !== undefined) {
            history.push("company")
        } else if (!isLogged) {
            localStorage.clear()
            history.push("login")
        }
    }

    return (isLogin ?
            <Grid Container align={"center"} justify={"center"} spacing={0}>
                <Grid item
                      xs={12}>
                    <CircularProgress/>
                </Grid>
            </Grid>
            :
            <LoginFormView
                handleSubmit={handleSubmit}
                customSubmit={customSubmit}
                register={register}
                errors={errors}
                loginErrorMessage={loginErrorMessage}
                switchToSignup={switchToSignup}
                switchToResetPassword={switchToResetPassword}
            />
    );
}