import React from "react";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import {AccountBox} from "./AccountBox";
import {Grid} from "@material-ui/core";

export default function AccessPoint(props) {

    return (
        <ThemeProvider theme={theme}>
            <Grid container
                  spacing={0}
                  align="center"
                  justify="center"
                  direction="column"
                  style={{overflowX: "hidden", height: "100%", padding: "60px"}}
            >
                <Grid item xs={12}>
                    <AccountBox/>
                </Grid>
            </Grid>

        </ThemeProvider>
    );
}