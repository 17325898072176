import React, {useContext} from "react";
import UserContext from "../../../context/UserContext";
import LoginService from "../../../services/loginService";
import {NavMenuView} from "../../../views/commonArea/burguerMenuViews/NavMenuView";

export function NavMenu({isOpen}) {
    const {jwt, setJWT, userRoles, setUserRoles} = useContext(UserContext);
    const {logout} = LoginService();

    function handleLogout() {
        logout();
        setJWT(null);
        setUserRoles(null);
    }

    return (
        <NavMenuView userRoles={userRoles} jwt={jwt} isOpen={isOpen} handleLogout={handleLogout}/>
    );
}