import React from "react";
import Grid from "@material-ui/core/Grid";
// import {DataGridStyled} from "../../../styles/adminArea/companiesDetailsScreen/CompanyDetailsScreenStyles";
import {DataGridStyled} from "../../styles/adminArea/companiesDetailsScreen/CompanyDetailsScreenStyles";
import {Controller} from "react-hook-form";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import {CircularProgress} from "@material-ui/core";
import {TitleMatchDetails} from "../../styles/adminArea/matchesDetailsStyles/MatchesDetailsScreenStyles";

const companiesDataGridcolumns = [
    {
        field: 'name',
        headerName: 'Nombre entidad',
        description: 'Nombre entidad',
        flex: 0.5
    },
    {
        field: 'cif',
        headerName: 'CIF',
        description: 'CIF',
        flex: 0.4
    },
    {
        field: 'applicationUser',
        headerName: 'Nombre contacto',
        description: 'Nombre persona contacto',
        flex: 0.3,
        valueGetter: (params) => {
            return params.value.name + ", " + params.value.lastName
        }
    },
    {
        field: 'phone',
        headerName: 'Teléfono',
        description: 'Numero de teléfono',
        flex: 0.15,
        valueGetter: (params) => {
            return params.row.applicationUser.phone
        }
    },
    {
        field: 'email',
        headerName: 'Correo',
        description: 'Correo',
        flex: 0.3,
        valueGetter: (params) => {
            return params.row.applicationUser.email;
        }
    },
    {
        field: 'numberOfTechLines',
        headerName: 'Líneas',
        description: 'Líneas tecnológicas insertadas por la entidad',
        flex: 0.13,
    },
    {
        field: 'cnae',
        headerName: 'CNAE',
        description: 'Sector industrial',
        flex: 0.15,
    },
    {
        field: 'techPark',
        headerName: 'Parque o polígono',
        description: 'Parque o polígono',
        flex: 0.2,
        valueGetter: (params) => {
            return params.row.techPark !== null && params.row.techPark !== undefined ? params.row.techPark.name : 'N/A';
        }
    },
    {
        field: 'town',
        headerName: 'Población',
        description: 'Población',
        flex: 0.2,
        valueGetter: (params) => {
            return params.row.techPark !== null && params.row.techPark !== undefined ? params.row.techPark.town : 'N/A';
        }
    },

];


export const CapacitiesInfoDetailsScreenView = (props) => {
    return (
        <Grid container spacing={0} justify={"center"} align={"center"}>
            <Grid item xs={12}>
                <TitleMatchDetails>Buscador de compañías por capacidades</TitleMatchDetails>
            </Grid>
            {props.capacities ?
                <Grid item xs={12}>
                    <Controller
                        render={({onChange, ...args}) => (
                            <Autocomplete
                                options={props.capacities}
                                getOptionLabel={option => option.description}
                                // groupBy={option => option.town}
                                renderInput={(params) => <TextField {...params}
                                                                    label="Capacidad a buscar"
                                                                    margin="normal"/>}
                                onChange={(e, data) => {
                                    onChange(data)
                                    props.handleComboCapacityOnChange(data)
                                }}
                                style={{maxWidth: '800px', marginBottom: '80px'}}
                                {...args}
                            />
                        )}

                        // onChange={([, data]) => {
                        //     props.handleComboCapacityOnChange(data);
                        // }}
                        // defaultValue={props.techParkSelected}
                        name={"capacitySearch"}
                        control={props.control}
                    />

                </Grid> : <Grid item xs={12} style={{minHeight: '500px'}}><CircularProgress/></Grid>
            }
            {props.companies && !props.loading ?
                <>
                    <Grid container spacing={0} justify={"center"} align={"center"}>
                        {/*<Grid item xs={10}></Grid>*/}
                        {/*<Grid item xs={2}>*/}
                        {/*    <IconButton variant="contained" onClick={props.handleCSVDownload}>*/}
                        {/*        <DownloadForOfflineIcon sx={{color: colorPrimary}}/>&nbsp;CSV*/}
                        {/*    </IconButton>*/}
                        {/*</Grid>*/}
                        <div style={{height: "100vh", width: '100%'}}>
                            <DataGridStyled
                                rows={props.companies}
                                columns={companiesDataGridcolumns}
                                pageSize={22}
                                loading={props.loading}
                                color={'linear-gradient(52deg, rgba(138,196,255,1) 0%, rgba(204,252,203,1) 100%)'}
                            />
                        </div>
                    </Grid>
                </> : <Grid item xs={12} style={{height: "100vh", width: '100%'}}><CircularProgress/></Grid>
            }
            }
        </Grid>)
}