import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import TechLineViewStyle from "../../../styles/userArea/companyCardComponentsStyles/TechLineStyles"
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EditIcon from "@material-ui/icons/Edit";


export default function TechLineView(props) {
    const classes = TechLineViewStyle();
    return (
        <div className={classes.root}>
            <div className={classes.pTechLineSeparator}>&nbsp;</div>
            <Accordion key={props.techLine.id}
                // defaultExpanded={props.numberOfTechline === 0}
            >
                <AccordionSummary
                    expandIcon={
                        <div>
                            <ExpandMoreIcon/>
                        </div>}

                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className={classes.buttonGroup}>
                        <IconButton className={classes.editButton} variant="contained"
                                    onClick={(event) => props.handleEditButton(event, props.techLine)}>
                            <EditIcon color={"primary"}/>
                        </IconButton>

                        <IconButton className={classes.deleteButton} aria-label="delete"
                                    onClick={(event) => props.deleteTechLine(event, props.techLine.id)}
                        >
                            <HighlightOffIcon/>
                        </IconButton>
                    </div>
                    <div className={classes.accordionSummaryNameContainer}>
                        <Typography component={'div'} className={classes.accordionSummaryName}>
                            {props.techLine.name}
                        </Typography>
                    </div>
                </AccordionSummary>

                <br></br>
                {/*<Grid className={classes.root} container direction="row">*/}
                {/*    <Grid item xs={12}>*/}
                {/*        <motion.div*/}
                {/*            initial={false}*/}
                {/*            whileHover={{*/}
                {/*                scale: 1.2,*/}
                {/*                originX: 0,*/}
                {/*            }}*/}
                {/*            transition={{type: "spring", stiffness: 300}}*/}
                {/*        >*/}
                {/*            <IconButton className={classes.button} aria-label="add"*/}
                {/*                        onClick={(event) => props.addAsset(event, props.techLine.id)}>*/}
                {/*                <Add/>*/}
                {/*                <div>*/}
                {/*                    <Typography component={'div'}>Añadir activo</Typography>*/}
                {/*                </div>*/}
                {/*            </IconButton>*/}
                {/*        </motion.div>*/}
                {/*        <AccordionDetails>*/}
                {/*            <Grid container direction="column" item xs={12}>*/}
                {/*                {*/}
                {/*                    props.techLine.assetsCompany ? props.techLine.assetsCompany.map(assetCompany =>*/}
                {/*                        <CompanyResourceAccordion*/}
                {/*                            key={assetCompany.id}*/}
                {/*                            resource={assetCompany}*/}
                {/*                            techLineId={props.techLine.id}*/}
                {/*                            collaborationWillingnessPreference={assetCompany.collaborationWillingnessPreferenceAsset}*/}
                {/*                            deleteResource={props.deleteAssetCompany}*/}
                {/*                        />*/}
                {/*                    ) : ""*/}
                {/*                }*/}
                {/*            </Grid>*/}
                {/*        </AccordionDetails>*/}
                {/*        <div>*/}
                {/*            <hr className={classes.hrActive}></hr>*/}
                {/*        </div>*/}
                {/*    </Grid>*/}

                {/*    <Grid item xs={12}>*/}
                {/*        <motion.div*/}
                {/*            initial={false}*/}
                {/*            whileHover={{*/}
                {/*                scale: 1.2,*/}
                {/*                originX: 0,*/}
                {/*            }}*/}
                {/*            transition={{type: "spring", stiffness: 300}}*/}
                {/*        >*/}
                {/*            <IconButton className={classes.button} aria-label="add"*/}
                {/*                        onClick={(event) => props.addCapacity(event, props.techLine.id)}>*/}
                {/*                <Add/>*/}
                {/*                <div>*/}
                {/*                    <Typography component={'div'}>Añadir capacidad</Typography>*/}
                {/*                </div>*/}
                {/*            </IconButton>*/}
                {/*        </motion.div>*/}
                {/*        <AccordionDetails>*/}
                {/*            <Grid container direction="column">*/}
                {/*                {*/}
                {/*                    props.techLine.capacitiesCompany.map(capacity =>*/}
                {/*                        <CompanyResourceAccordion*/}
                {/*                            key={capacity.id}*/}
                {/*                            resource={capacity}*/}
                {/*                            techLineId={props.techLine.id}*/}
                {/*                            collaborationWillingnessPreference={capacity.collaborationWillingnessPreferenceCapacity}*/}
                {/*                            deleteResource={props.deleteCapacityCompany}*/}
                {/*                        />*/}
                {/*                    )*/}
                {/*                }*/}
                {/*            </Grid>*/}
                {/*        </AccordionDetails>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Accordion>
        </div>
    );
}