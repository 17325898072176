import {makeStyles} from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import {colorPrimary} from "../../../EnvironmentVars";

const loginStyles = makeStyles((theme) => ({

    titleLogin: {
        color: colorPrimary
    },

    loginFormSection: {
        display: "flex",
        alignItems: "center",
        height: "52vh"
    },

    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },

    warmText: {
        color: red[500],
        fontSize: "0.8em",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.55em"
        },
    },

    textField: {
        flexGrow: 1,
        width: 250,
        [theme.breakpoints.down('xs')]: {
            width: 200,
        },
    },

    formFields: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '30px',
        },
    },

    loginButton: {
        marginTop: 16,
        marginBottom: 24,
        marginLeft: 0,
    },

    paper: {
        display: 'flex',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: "center",
        alignItems: "center",
        width: '85%',
        height: 'auto',
        padding: '5px 5px',
        maxWidth: '400px',
    },

    forgotPasswordLink: {
        borderRadius: "7px",
        fontSize: "14px",
        '&:hover':
            {
                color: colorPrimary,
                textDecoration: "none",
            },
        '&:active': {
            background: "#ffffff",
            boxShadow: "inset 7px 7px 14px #c4c4c4,inset -7px -7px 14px #ffffff",
        },
    }

}));
export default loginStyles;