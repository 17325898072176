import React, {useEffect, useState} from "react";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import CapacitiesInfoFormView
    from "../../../views/userArea/companyCardComponentsViews/assetsCapacitiesInfo/CapacitiesInfoFormView";


function AssetsInfoCompanyForm(props) {
    const [loading, setLoading] = useState(true);

    const [anticipateTechChangesCapacity, setAnticipateTechChangesCapacity] = useState(1)
    const [newBusinessDiversificationCapacity, setNewBusinessDiversificationCapacity] = useState(1)
    const [digitalizationCapacity, setDigitalizationCapacity] = useState(1)
    const [sustainabilityProjectsCapacity, setSustainabilityProjectsCapacity] = useState(1)
    const [highQualityLevelsCapacity, setHighQualityLevelsCapacity] = useState(1)
    const [innovationAndCreativityCapacity, setInnovationAndCreativityCapacity] = useState(1)
    const [combinationKnowledgeWithOthersCapacity, setCombinationKnowledgeWithOthersCapacity] = useState(1)
    const [useOfExperiencesToImproveCapacity, setUseOfExperiencesToImproveCapacity] = useState(1)
    const [quickReleaseProductsCapacity, setQuickReleaseProductsCapacity] = useState(1)
    const [techProblemsResolutionCapacity, setTechProblemsResolutionCapacity] = useState(1)
    const [fidelityCapacity, setFidelityCapacity] = useState(1)
    const [createReputationCapacity, setCreateReputationCapacity] = useState(1)

    let validationSchema = yup.object().shape(
        {}
    );

    const {register, handleSubmit, errors, control} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    useEffect(async () => {
        await getCapacitiesInfoCompany();
        setLoading(false);
    }, [])

    async function getCapacitiesInfoCompany() {
        const url = "capacitiesinfocompany/" + props.location.state.company.id
        let capacitiesInfoResponse = await axios({
            method: 'GET',
            url,
            data: {},
        })
        setValuesOfCapacitiesInfoCompany(capacitiesInfoResponse.data);
    }


    function setValuesOfCapacitiesInfoCompany(capacitiesInfoCompanyData) {
        setAnticipateTechChangesCapacity(capacitiesInfoCompanyData.anticipateTechChangesCapacity)
        setNewBusinessDiversificationCapacity(capacitiesInfoCompanyData.newBusinessDiversificationCapacity)
        setDigitalizationCapacity(capacitiesInfoCompanyData.digitalizationCapacity)
        setSustainabilityProjectsCapacity(capacitiesInfoCompanyData.sustainabilityProjectsCapacity)
        setHighQualityLevelsCapacity(capacitiesInfoCompanyData.highQualityLevelsCapacity)
        setInnovationAndCreativityCapacity(capacitiesInfoCompanyData.innovationAndCreativityCapacity)
        setCombinationKnowledgeWithOthersCapacity(capacitiesInfoCompanyData.combinationKnowledgeWithOthersCapacity)
        setUseOfExperiencesToImproveCapacity(capacitiesInfoCompanyData.useOfExperiencesToImproveCapacity)
        setQuickReleaseProductsCapacity(capacitiesInfoCompanyData.quickReleaseProductsCapacity)
        setTechProblemsResolutionCapacity(capacitiesInfoCompanyData.techProblemsResolutionCapacity)
        setFidelityCapacity(capacitiesInfoCompanyData.fidelityCapacity)
        setCreateReputationCapacity(capacitiesInfoCompanyData.createReputationCapacity)

    }

    async function customSubmit(data) {
        let assetsCompany = {
            companyId: props.location.state.company.id,
            ...data
        }

        await axios.post("/capacitiesinfocompany", assetsCompany);

        props.history.push("company")

    }

    return (
        <ThemeProvider theme={theme}>
            <CapacitiesInfoFormView
                loading={loading}
                handleSubmit={handleSubmit}
                customSubmit={customSubmit}
                register={register}
                errors={errors}
                control={control}

                anticipateTechChangesCapacity={anticipateTechChangesCapacity}
                newBusinessDiversificationCapacity={newBusinessDiversificationCapacity}
                digitalizationCapacity={digitalizationCapacity}
                sustainabilityProjectsCapacity={sustainabilityProjectsCapacity}
                highQualityLevelsCapacity={highQualityLevelsCapacity}
                innovationAndCreativityCapacity={innovationAndCreativityCapacity}
                combinationKnowledgeWithOthersCapacity={combinationKnowledgeWithOthersCapacity}
                useOfExperiencesToImproveCapacity={useOfExperiencesToImproveCapacity}
                quickReleaseProductsCapacity={quickReleaseProductsCapacity}

                techProblemsResolutionCapacity={techProblemsResolutionCapacity}
                fidelityCapacity={fidelityCapacity}
                createReputationCapacity={createReputationCapacity}


            />
        </ThemeProvider>
    );
}

export default AssetsInfoCompanyForm