import {makeStyles} from "@material-ui/core/styles";
import red from '@material-ui/core/colors/red'
import {colorPrimary} from "../../../EnvironmentVars";

const assetsCollaborationFormStyles = makeStyles((theme) => ({

    warmText: {
        color: red[500],
        fontSize: "1em",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em"
        },
    },

    textAreaAutosize: {
        width: '75%',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: 'auto'
    },

    legendText: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        color: colorPrimary,
    },

    formControlLabel: {
        fontSize: '1em',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '0.7em'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '0.8m'
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: '1em'
        }
    },

    saveButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(19)
    },

    paper: {
        justifyContent: "center",
        alignItems: "center",
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '85%',
        padding: '40px 20px'
    },

    hrForm: {
        border: "0.05em solid " + colorPrimary,
        backgroundColor: colorPrimary,
        borderRadius: "8px",
        margin: '20px 40px'
    },

}));
export default assetsCollaborationFormStyles;