import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {Add} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import AdditionalInfoForPartnershipStyles
    from "../../../../styles/userArea/companyCardComponentsStyles/AdditionalInfoForPartnershipStyles";
import {Accordion, AccordionDetails, Grid} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function AssetsResumeInfoView(props) {
    const classes = AdditionalInfoForPartnershipStyles();
    return (
        <Grid container>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12} style={{marginTop: '20px'}}>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid container align={"center"} alignItems={"center"} spacing={0}>
                            <Grid item xs={2}>
                                <IconButton aria-label="add" onClick={props.addAdditionalInfo}>
                                    <Add/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography>Información de activos</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>

                        <ul>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Patentes: {props.patents ? props.patents : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Software: {props.software ? props.software : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Bases de datos: {props.dataBases ? props.dataBases : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Marcas: {props.tradeMark ? props.tradeMark : 0}
                                </Typography>
                            </li>
                            <li>
                                <Typography component={'div'} className={classes.heading}>
                                    Certificaciones: {props.certifications ? props.certifications : 0}
                                </Typography>
                            </li>
                        </ul>

                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    )
}

export default AssetsResumeInfoView;