import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {Add} from "@material-ui/icons";
import TechLine from "../../../components/userArea/companyCardComponents/techLinesAccordion/TechLine";

function TechLinesListView(props) {
    return (
        <div>
            <h6>Líneas Tecnológicas</h6>
            <div>
                <IconButton aria-label="add" onClick={props.addTechLine}>
                    <Add/>
                </IconButton>
            </div>
            {
                props.techLines.map((techLine, numberOfTechLine) =>
                    <TechLine
                        key={techLine.id}
                        techLine={techLine}
                        techLines={props.techLines}
                        setTechLines={props.setTechLines}
                        addAsset={props.addAsset}
                        addCapacity={props.addCapacity}
                        deleteTechLine={props.deleteTechLine}
                        numberOfTechLine={numberOfTechLine}
                    />
                )
            }
        </div>)
}

export default TechLinesListView;