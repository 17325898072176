import React, {useEffect, useState} from "react";
import axios from "axios";
import Alert from "sweetalert2";
import {CapacitiesInfoDetailsScreenView} from "../../views/adminArea/CapacitiesInfoDetailsScreenView";
import {colorPrimary} from "../../EnvironmentVars";
import {useForm} from "react-hook-form";

const capacities = [
    {
        description: 'Capacidad para anticipar cambios tecnológicos',
        key: 'anticipate_tech_changes_capacity',
    },
    {
        description: 'Capacidad para diversificar en nuevos negocios',
        key: 'new_business_diversification_capacity',
    },
    {
        description: 'Capacidad para la digitalización de los procesos',
        key: 'digitalization_capacity',
    },
    {
        description: 'Capacidad para impulsar proyectos de sostenibilidad',
        key: 'sustainability_projects_capacity',
    },
    {
        description: 'Capacidad para lograr altos niveles de calidad',
        key: 'high_quality_levels_capacity',
    },
    {
        description: 'Capacidad creativa e innovadora',
        key: 'innovation_and_creativity_capacity',
    },
    {
        description: 'Capacidad para combinar las tecnologías y conocimiento de otros agentes con los propios',
        key: 'combination_knowledge_with_others_capacity',
    },
    {
        description: 'Capacidad para asimilar las experiencias de éxito y mejores prácticas de innovación',
        key: 'use_of_experiences_to_improve_capacity',
    },
    {
        description: 'Capacidad para lanzar rápidamente nuevos productos al mercado',
        key: 'quick_release_products_capacity',
    },
    {
        description: 'Capacidad de resolución de problemas técnicos',
        key: 'tech_problems_resolution_capacity',
    },
    {
        description: 'Capacidad para generar fidelidad y confianza en los clientes',
        key: 'fidelity_capacity',
    },
    {
        description: 'Capacidad para crear reputación corporativa',
        key: 'create_reputation_capacity',
    },

]

export const CapacitiesInfoDetailsScreen = () => {

    const [companies, setCompanies] = useState();
    const [loading, setLoading] = useState(true);

    const {control} = useForm(
    );


    useEffect(async () => {
        await getAllCompanies();
    }, []);

    async function getAllCompanies() {
        const url = "company/allcompanies"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                setCompanies(res.data);
                setLoading(false)
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: 'Ha ocurrido un error al cargar los matches, si el problema persiste contacta con soporte',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    async function getCompaniesByElementSelected(capacityKey) {
        const url = "capacitiesinfocompany/search/" + capacityKey
        return await axios({
            method: 'GET',
            url,
            data: {},
        });

    }

    async function handleComboCapacityOnChange(elementSelected) {
        setLoading(true);
        if (elementSelected === null || elementSelected === undefined) {
            setCompanies([])
            setLoading(false);
        } else {
            let companiesByCapacityResponse = await getCompaniesByElementSelected(elementSelected.key);
            setCompanies(companiesByCapacityResponse.data);
            setLoading(false);
        }

    }

    // async function handleCSVDownload() {
    //     let dataType = "company"
    //     await getDataForCSV(dataType)
    // }

    return (
        <CapacitiesInfoDetailsScreenView
            loading={loading}
            companies={companies}
            capacities={capacities}
            control={control}
            // capacitySelectedForSearch={capacitySelectedForSearch}
            handleComboCapacityOnChange={handleComboCapacityOnChange}
            // handleCSVDownload={handleCSVDownload}
        />
    )
}
