import React, {useEffect, useState} from "react";
import axios from "axios";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";
import {
    MatchesCompletedDetailsScreenView
} from "../../../views/adminArea/matchesCompletedDetailsScreen/MatchesCompletedDetailsScreenView";
import {getDataForCSV} from "../../../services/FileDownloadService";

export const MatchesCompletedDetailsScreen = () => {
    const [matches, setMatches] = useState();
    const [loading, setLoading] = useState(true);
    const [matchesManagedByDate, setMatchesManagedByDate] = useState();
    const [matchesEfficiency, setMatchesEfficiency] = useState();

    useEffect(() => {
        getMatchesCompletedDetails();
        getDataForChart("/admin/charts/matchesmanagedbydate", setMatchesManagedByDate, "matches por fecha")
        getMatchesEfficency();
    }, [])

    async function getMatchesCompletedDetails() {
        const url = "matches/matchescompleted"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                setMatches(res.data);
                setLoading(false)
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: 'Ha ocurrido un error al cargar los matches, si el problema persiste contacta con soporte',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    async function getDataForChart(url, dataFormater, setterOfState, nameOfChart) {
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                setMatchesManagedByDate(res.data);
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: "Ha ocurrido un error al cargar los datos de " + nameOfChart + ", si el problema persiste contacta con soporte",
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    async function getMatchesEfficency() {
        const url = "/admin/charts/matchesefficiency"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                setMatchesEfficiency(res.data);
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: "Ha ocurrido un error al cargar los datos de la eficiencia de matches, si el problema persiste contacta con soporte",
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    async function handleCSVDownload() {
        let dataType = "matchCompleted"
        await getDataForCSV(dataType)
    }

    return (
        <MatchesCompletedDetailsScreenView
            matches={matches}
            loading={loading}
            matchesManagedByDate={matchesManagedByDate}
            matchesEfficiency={matchesEfficiency}
            handleCSVDownload={handleCSVDownload}
        />)
}