import React, {useEffect, useState} from "react";
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios'
import CircularProgress from "@material-ui/core/CircularProgress";
import {ThemeProvider} from '@material-ui/styles';
import theme from '../../../Theme';
import AssetsFormView from "../../../views/userArea/stepperAssetsForm/AssetsFormView";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";

function AssetsForm(props) {
    const [selectedAssetId, setSelectedAssetId] = useState("");
    const [assetsTypes, setAssetsTypes] = useState([]);
    const [loading, setLoading] = useState(true);

    let validationSchema = yup.object().shape({
        selected: yup.string().required(),
    });

    const {register, handleSubmit, errors, control} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    useEffect(() => {
        if (props.assetApplicant) {
            Alert.fire({
                    text: "Se actualizado y modificado el formulario",
                    icon: 'success',
                    timer: 2500,
                    showConfirmButton: false,
                    iconColor: colorPrimary
                }
            )
        }
    }, [props.assetApplicant]);


    function handleChange(assetId) {
        setSelectedAssetId(assetId);
    }

    function handleCheckAssetApplicant(event) {
        props.setAssetApplicant(event.target.checked);
    }

    function handleChangeItemId(assetId) {
        setSelectedAssetId(assetId);
    }

    function customSubmit(data) {
        let company = JSON.parse(props.company);
        if (company === undefined) {
            company = JSON.parse(localStorage.getItem("company"));
        }
        let techLineId = props.techLineId;
        if (techLineId === undefined) {
            techLineId = JSON.parse(localStorage.getItem("techLineId"));
        }
        let assetData = {
            companyId: company.id,
            techLineId: techLineId,
            name: data.selected,
            assetId: selectedAssetId,
            assetApplicant: props.assetApplicant
        };
        props.setAsset({...props.asset, assetData});
        localStorage.setItem("assetForm", JSON.stringify(assetData));
        setSelectedAssetId(assetData.assetId)
        props.setAssetApplicant(assetData.assetApplicant)
        props.handleNext();
    }

    useEffect(() => {
            window.scrollTo(0, 0)

            async function getAssets() {
                const url = "assetstypes"
                await axios({
                    method: 'GET',
                    url,
                    data: {},
                }).then(
                    res => {
                        setAssetsTypes(res.data);
                        setLoading(false);
                    },
                    error => {

                    }
                ).catch(
                    error => {
                        console.error(error)
                    }
                )
            }

            getAssets();
            let chosenAssetId = selectedAssetId ? selectedAssetId : localStorageChosen !== null ? localStorageChosen.assetId : "";
            let chosenAssetApplicant = props.assetApplicant ? props.assetApplicant : localStorageChosen !== null ? localStorageChosen.assetApplicant : "";
            setSelectedAssetId(chosenAssetId);
            props.setAssetApplicant(chosenAssetApplicant)
        }
        , [selectedAssetId]);

    let localStorageChosen = JSON.parse(localStorage.getItem("assetForm"));
    let chosen = localStorageChosen !== null ? localStorageChosen.name : "";

    return (

        <ThemeProvider theme={theme}>
            {
                loading ?
                    <CircularProgress color="primary"/>
                    :
                    <AssetsFormView handleSubmit={handleSubmit} control={control} register={register}
                                    customSubmit={customSubmit}
                                    assetsTypes={assetsTypes}
                                    value={props.asset}
                                    handleChange={handleChange}
                                    handleChangeItemId={handleChangeItemId}
                                    errors={errors}
                                    selectedItem={chosen}
                                    handleCheckAssetApplicant={handleCheckAssetApplicant}
                                    assetApplicant={props.assetApplicant}
                    />
            }
        </ThemeProvider>
    );
}

export default AssetsForm;