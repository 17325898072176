import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {MatchesDnDContext} from "../../../context/MatchesDnDContext";
import {ModalAddMatchesToKanbanView} from "../../../views/adminArea/matchesPanelViews/ModalAddMatchesToKanbanView";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";

export const ModalAddMatchesToKanban = (props) => {

    const [selectedMatchesToIntroduceInKanban, setSelectedMatchesToIntroduceInKanban] = useState();
    const {getMatchesInKanban} = useContext(MatchesDnDContext);
    const [matchesNotInKanban, setMatchesNotInKanban] = useState();

    async function updateSelected(selection) {
        setSelectedMatchesToIntroduceInKanban(selection);
    }

    async function getMatchesNotInKanban() {
        const url = "matches/matchesnotinkanban/"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                setMatchesNotInKanban(res.data);
                props.setLoading(false)
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: 'Ha ocurrido un error al cargar los matches, si el problema persiste contacta con soporte',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    useEffect(async () => {
        await getMatchesNotInKanban();
    }, [])


    const updateSelectedItemsForKanban = async () => {
        if (selectedMatchesToIntroduceInKanban !== undefined) {
            props.setLoading(true);
            await axios.put("/matches/addmatchtokanban", selectedMatchesToIntroduceInKanban)
                .then(res => {
                        getMatchesInKanban();
                        getMatchesNotInKanban();
                        props.setLoading(false);
                        props.closeModal()
                    },
                    error => {
                        if (error.response.status === 403) {
                            this.navigateToForbidden();
                        }
                    }
                ).catch(error => {

                    }
                );
        } else {
            props.closeModal();
        }
    }

    return (
        <ModalAddMatchesToKanbanView
            {...props}
            updateSelectedItemsForKanban={updateSelectedItemsForKanban}
            updateSelected={updateSelected}
            matchesNotInKanban={matchesNotInKanban}
            loading={props.loading}
        />
    )
}
