import {createMuiTheme} from '@material-ui/core/styles';
import {colorPrimary, colorSecondary} from "./EnvironmentVars";
import {esES} from "@material-ui/data-grid";

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
    palette: {
        primary: {
            main: colorPrimary
        },
        secondary: {
            main: colorSecondary
        },
    },
    typography: {
        fontFamily: [
            'Raleway'
        ].join(','),
        fontWeightRegular: "500"
    },
    spacing: 8,
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: '#db3131',
                '&$error': {
                    color: '#db3131'
                },
            }
        },
        MuiPaper: {
            root: {
                border: "100px"
                // margin:"100px"
                // boxShadow:"20px 20px 60px #d9d9d9,-20px -20px 60px #ffffff",
                // backgroundColor: "#bada55"
            }
        },
        MuiButton: {
            root: {
                background: 'linear-gradient(45deg, ' + colorPrimary + ', ' + colorSecondary + ' 130%)',
                border: 0,
                borderRadius: 10,
                textFillColor: 'white',
                height: 40,
                width: 100,
                padding: '0px 30px',
                boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                marginTop: 16,
                marginBottom: 24,
                marginLeft: 0
            }
        },
        MuiSlider: {
            root: {
                color: colorPrimary,
                width: '80%',
                height: 7,
                marginLeft: '10%',
            },
            margin: {
                marginLeft: '10%',
                marginTop: '10%',
                marginBotton: '10%',
            },
            thumb: {
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
            },
            active: {},
            valueLabel: {
                left: 'calc(-50% + 4px)',
            },
            track: {
                height: 8,
                borderRadius: 4,
            },
            rail: {
                height: 8,
                borderRadius: 4,
            },
            markLabel: {
                transform: 'translateX(-55%)',
                fontSize: '0.8em',
                [defaultTheme.breakpoints.down('xs')]: {
                    fontSize: "0.5em",
                },
                [defaultTheme.breakpoints.between('xs', 'sm')]: {
                    fontSize: "0.7em",
                },
                [defaultTheme.breakpoints.between('sm', 'md')]: {
                    fontSize: "0.9em",
                },
                [defaultTheme.breakpoints.between('md', 'lg')]: {
                    fontSize: "1em",
                },
            },
        },

    },
}, esES);
export default theme;

