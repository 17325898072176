import React, {useEffect} from "react";
import TechLineHumanResourceFormStyle
    from "../../../styles/userArea/stepperTechLineFormStyles/TechLineHumanResourceFormStyles";
import {FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography} from "@material-ui/core"
import "aos/dist/aos.css";
import aos from "aos";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import Button from "@material-ui/core/Button";
import {SliderCustom} from "./SliderCustom";
import CollaborationWillingnessPreference from "./CollaborationWillingnessPreference";

function TechLineCollaborationFormView(props) {
    const classes = TechLineHumanResourceFormStyle();

    useEffect(() => {
        aos.init({duration: 1000})
    }, []);

    return (

        <form onSubmit={props.handleSubmit(props.customSubmit)} style={{overflowX: "hidden"}}>
            <div className={classes.root}>
                <   Paper className={classes.paper} elevation={12} data-aos={"fade-left"}>
                    <div>&nbsp;</div>
                    <div className={classes.humanResources}>
                        <h3 className={classes.legendText} component="legend">
                            Colaboración
                        </h3>
                        <Grid container direction="column" item xs={12}>

                        </Grid>
                        <div>&nbsp;</div>
                        <Grid container direction="column" item xs={12}>

                            <Grid item xs={12} align={"center"}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            inputRef={props.register}
                                            name="wantToStartSoonCollaboration"
                                            // control={props.control}
                                            color="primary"
                                            onChange={(e) => props.handleChangeCheckWantToStartSoon(e)}
                                            icon={<RadioButtonUncheckedOutlinedIcon/>}
                                            checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                                            checked={props.wantToStartSoonCollaboration}
                                        />
                                    }
                                    name="wantToStartSoonCollaboration"
                                    label=" ¿Va a emprender proyectos de innovación en esta línea tecnológica?"
                                />
                            </Grid>
                            {props.wantToStartSoonCollaboration &&
                                <>
                                    <Grid item xs={12}>
                                        <Typography component={'div'} className={classes.textField}
                                                    style={{marginTop: "20px"}}>
                                            <h6>¿En qué plazo de tiempo?</h6>
                                            <h6 style={{color: "red"}}>
                                                *
                                            </h6>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} align={"center"}>
                                        <FormControl control={props.control} label={''}>
                                            <RadioGroup
                                                name={"timeToStartCollaboration"}
                                                value={props.timeToStartCollaboration}
                                                onChange={props.handleChangetimeToStart}
                                            >
                                                <FormControlLabel inputRef={props.register} value="threeOrLessMonths"
                                                                  control={<Radio color={"primary"}/>}
                                                                  label="En menos de 3 meses"/>

                                                <FormControlLabel inputRef={props.register}
                                                                  value="betweenFourAndTwelveMonths"
                                                                  control={<Radio color={"primary"}/>}
                                                                  label="Entre 4 y 12 meses"/>

                                                <FormControlLabel inputRef={props.register} value="moreThanAYear"
                                                                  control={<Radio color={"primary"}/>}
                                                                  label="Dentro de un año ó más"/>

                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12} align={"center"} style={{margin: "20px"}}>
                                <Typography component={'div'} id="discrete-slider" gutterBottom>
                                    <h6>
                                        Valora la predisposición de la entidad a colaborar con otros agentes
                                        para el desarrollo o la mejora de este activo, o su explotación económica
                                    </h6>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <SliderCustom control={props.control} name={"collaborationWillingness"}
                                              value={props.collaborationWillingness}
                                              valueSetter={props.setCollaborationWillingness}/>
                            </Grid>
                            <Grid item xs={12}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={12}>
                                &nbsp;
                            </Grid>
                            <Grid item xs={12}>
                                {props.collaborationWillingness >= 4 &&
                                    <CollaborationWillingnessPreference
                                        register={props.register}
                                        control={props.control}
                                        errors={props.errors}
                                        companyType={props.collaborationWillingnessPreference}
                                        handleChangeRadioButton={props.handleChangeCollaborationWillingNessPreference}
                                        defaultValue={props.collaborationWillingnessPreference}
                                    />
                                }
                            </Grid>
                            {props.errors.collaborationWillingnessPreference &&
                                <Grid item xs={12} align={"center"} style={{margin: "20px"}}>
                                    <Typography component={'div'} id="discrete-slider" gutterBottom>
                                        <h6 style={{color: "red"}}>
                                            Debes seleccionar un tipo de entidad para colaborar.
                                        </h6>
                                    </Typography>
                                </Grid>
                            }
                        </Grid>

                        <div>&nbsp;</div>
                    </div>
                </Paper>
                <div>&nbsp;</div>
                <hr className={classes.hrForm}></hr>
                <div>
                    <Button variant="contained" type="submit" className={classes.saveButton}>Siguiente</Button>
                </div>
            </div>
        </form>


    );
}

export default TechLineCollaborationFormView;