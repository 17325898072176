import React, {useEffect, useState} from "react";
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TechLineFormView from "../../../views/userArea/stepperTechLineFormViews/TechLineFormView";
import axios from 'axios'
import {ThemeProvider} from '@material-ui/styles';
import theme from '../../../Theme';
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";

function TechLineForm(props) {
    let techLineForm = props.techLineFormContext ? props.techLineFormContext.generalData : JSON.parse(localStorage.getItem("techLineForm"));

    const [selectedItem, setSelectedItem] = useState(techLineForm ? techLineForm.name : "");
    const [selectedItemId, setselectedItemId] = useState(techLineForm ? techLineForm.techLineId : "");
    const [knowledgeAreas, setKnowledgeAreas] = useState([]);
    const [techLineApplicant, setTechLineApplicant] = useState(techLineForm ? techLineForm.techLineApplicant : "");
    const [loading, setLoading] = useState(true);

    let validationSchema = yup.object().shape({
        selected: yup.string().required(),
    });

    const {register, handleSubmit, errors, control} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    function handleChange(techLineId) {
        setSelectedItem(techLineId);
    }

    function handleChangeItemId(techLineId) {
        setselectedItemId(techLineId);
    }

    function customSubmit(data) {
        let techLineId = selectedItemId;
        let company = props.company;
        if (company === undefined) {
            company = JSON.parse(localStorage.getItem("company"));
        }
        let generalData = {
            companyId: company.id,
            name: data.selected,
            techLineId: techLineId,
            id: techLineForm ? techLineForm.id : undefined,
            techLineApplicant: data.techLineApplicant
        };

        let techLineFormContext = techLineForm ? {...techLineForm, generalData} : {generalData};
        props.setTechLineFormContext(techLineFormContext);
        localStorage.setItem("techLineForm", JSON.stringify(generalData));
        setSelectedItem(generalData.name);
        setselectedItemId(generalData.techLineId)
        props.handleNext();
    }

    function handleCheckTechLineApplicant(event) {
        setTechLineApplicant(event.target.checked)
    }

    useEffect(async () => {
        if (techLineApplicant && JSON.parse(localStorage.getItem("techLineForm")) === undefined) {
            await Alert.fire({
                    text: "Se actualizado y modificado el formulario",
                    icon: 'success',
                    timer: 2500,
                    showConfirmButton: false,
                    iconColor: colorPrimary
                }
            )
        }
    }, [techLineApplicant]);

    useEffect(async () => {

            await getTechLines();
            let selectedTechLineId = selectedItemId ? selectedItemId : techLineForm !== null ? techLineForm.techLineId : "";
            setselectedItemId(selectedTechLineId);

        }
        , []);

    async function getTechLines() {
        const url = "knowledgeareas"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(
            res => {
                setKnowledgeAreas(res.data)
                setLoading(false)
            },
            error => {

            }
        ).catch(
            error => {
                console.error(error)
            }
        )
    }


    return (

        <ThemeProvider theme={theme}>
            {
                <TechLineFormView handleSubmit={handleSubmit} control={control} register={register}
                                  customSubmit={customSubmit}
                                  knowledgeAreas={knowledgeAreas}
                                  value={selectedItem}
                                  handleChange={handleChange}
                                  handleChangeItemId={handleChangeItemId}
                                  errors={errors}
                                  stepperProps={props}
                                  selectedItem={selectedItem}
                                  setKnowledgeAreas={setKnowledgeAreas}
                                  setLoading={setLoading}
                                  loading={loading}
                                  techLineApplicant={techLineApplicant}
                                  handleCheckTechLineApplicant={handleCheckTechLineApplicant}
                />
            }
        </ThemeProvider>
    );
}

export default TechLineForm;