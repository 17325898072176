import {makeStyles} from "@material-ui/core/styles";
import red from '@material-ui/core/colors/red'
import {teal} from '@material-ui/core/colors'
import {colorPrimary} from "../../../EnvironmentVars";

const capacitiesFormStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        width: '100%',
        marginLeft: '15%',
        marginBottom: '10px'
    },

    warmText: {
        color: red[500],
        fontSize: "1em",
        marginTop: '40px',
        marginLeft: '40px',
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em"
        },
    },

    legendText: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        color: colorPrimary,
        [theme.breakpoints.down('xs')]: {
            marginBottom: '5px',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            marginLeft: '1px',
        },
    },

    formControlLabel: {
        fontSize: '1em',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.5em',
            marginLeft: '-10px',
            width: '170px',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '0.8em',
            marginLeft: '5px'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '0.9em',
            marginLeft: '5px'
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: '1em',
            marginLeft: '5px'
        }
    },

    formControlRadio: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: '35px',
            width: '60px',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '0.7em',
            marginLeft: '-60px'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '0.8em',
            marginLeft: '-110px',
            width: '30px',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: '1em',
            marginLeft: '-150px',
            width: '30px',
        }
    },

    saveButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(19)
    },

    paper: {
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '85%',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },

    capacities: {
        padding: '40px 50px'
    },

    hrCapacities: {
        border: "0.05em solid " + teal[400],
        backgroundColor: teal[400],
        borderRadius: "8px",
        margin: '20px 40px'
    },

}));
export default capacitiesFormStyles;