import React from "react";
import CollaborationAgentsView from "../../../views/userArea/stepperViews/CollaborationAgentsView";

function CollanborationAgents(props) {

    function handleChangeCheckBoxes(event) {
        let name = event.target.name.split('\'')[1];
        let checked = event.target.checked;
        switch (name) {
            case "techInstitutes" :
                props.setStateOptions({
                        ...props.chosenOptions,
                        techInstitutes: checked
                    }
                )
                break;

            case "otherCompanies" :
                props.setStateOptions({
                        ...props.chosenOptions,
                        otherCompanies: checked
                    }
                )
                break;

            case "universities" :
                props.setStateOptions({
                        ...props.chosenOptions,
                        universities: checked
                    }
                )
                break;

            case "startUps" :
                props.setStateOptions({
                        ...props.chosenOptions,
                        startUps: checked
                    }
                )
                break;

            case "specializedCentres" :
                props.setStateOptions({
                        ...props.chosenOptions,
                        specializedCentres: checked
                    }
                )
                break;

            case "managementEntities" :
                props.setStateOptions({
                        ...props.chosenOptions,
                        managementEntities: checked
                    }
                )
                break;

            case "europeanCentres" :
                props.setStateOptions({
                        ...props.chosenOptions,
                        europeanCentres: checked
                    }
                )
                break;

            case "businessAssociations" :
                props.setStateOptions({
                        ...props.chosenOptions,
                        businessAssociations: checked
                    }
                )
                break;

            case "others" :
                props.setStateOptions({
                        ...props.chosenOptions,
                        others: checked
                    }
                )
                break;
        }
    }

    return (
        <CollaborationAgentsView
            register={props.register}
            nameOfOptions={props.nameOfOptions}
            chosenOptions={props.chosenOptions}
            handleChange={handleChangeCheckBoxes}
        />

    )
}

export default CollanborationAgents