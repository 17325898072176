import React from "react";
import Button from "@material-ui/core/Button";
import AdditionalInfoForPartnershipStyles
    from "../../../styles/userArea/companyCardComponentsStyles/AdditionalInfoForPartnershipStyles";
import {Controller} from "react-hook-form";
import {Grid, TextareaAutosize, Typography} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import FormLabel from "@material-ui/core/FormLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import {SliderCustom} from "../stepperTechLineFormViews/SliderCustom";

function AdditionalInfoForPartnershipFormView(props) {
    const classes = AdditionalInfoForPartnershipStyles();

    return (
        <Paper className={classes.paper} elevation={12}>
            <Grid container align={"center"} justify={"center"}>
                <form onSubmit={props.handleSubmit(props.customSubmit)}>
                    <Grid item xs={12} style={{margin: "30px"}}>
                        <h3>Información adicional sobre las necesidades de tu entidad</h3>
                        <div>&nbsp;&nbsp;</div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component={'div'}>
                            <h6>
                                Ayúdanos a conocer las características que deberían cumplir tus potenciales
                                colaboradores
                            </h6>
                        </Typography>
                        <div>&nbsp;&nbsp;</div>
                    </Grid>
                    {
                        props.partnershipPreferencesItems.map(
                            partnershipPreferenceItem =>
                                <Grid container key={partnershipPreferenceItem.key}>
                                    <Grid item xs={6} style={{paddingRight: '30px'}}>
                                        <Typography component={'div'} align={"right"}>
                                            {partnershipPreferenceItem.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} align={"left"}>
                                        <SliderCustom control={props.control} name={partnershipPreferenceItem.key}
                                                      value={props.partnershipPreferencesValues[`${partnershipPreferenceItem.key}`] !== undefined && props.partnershipPreferencesValues[`${partnershipPreferenceItem.key}`] !== null ? props.partnershipPreferencesValues[`${partnershipPreferenceItem.key}`] : 1}
                                        />
                                    </Grid>
                                </Grid>
                        )
                    }

                    <Grid item xs={12} style={{marginTop: '30px'}}>
                        <Typography component={'div'}>
                            <h6>
                                ¿Puede detallar cuál o cuáles serían los retos de innovación que desearía lanzar?
                            </h6>
                        </Typography>
                        <div>&nbsp;&nbsp;</div>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            defaultValue={props.partnershipPreferencesValues.additionalInfo !== null ? props.partnershipPreferencesValues.additionalInfo : ''}
                            as={
                                <TextareaAutosize
                                    className={classes.textAreaAutosize}
                                    rowsMin={5}
                                    variant="outlined"
                                    name="additionalInfo"
                                    onKeyUp={(event) => props.customChangeInfo(event)}

                                />
                            }
                            name="additionalInfo"
                            control={props.control}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.progressBar}>
                            <LinearProgress variant="determinate" value={props.additionalInfoLength * 100 / 4000}/>
                            <FormLabel className={classes.AdditionalInfoChar}>
                                {props.additionalInfoLength} (máximo: 4000)
                            </FormLabel>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel className={classes.warmText}>
                            {props.errors.additionalInfo?.message}
                        </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit" className={classes.loginButton}>
                            Guardar
                        </Button>
                    </Grid>
                    {/*</div>*/}
                </form>
            </Grid>
        </Paper>
    )
}

export default AdditionalInfoForPartnershipFormView;