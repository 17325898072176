import React, {useEffect, useState} from "react";
import {CompaniesDetailsScreenView} from "../../../views/adminArea/companiesDetailsScreen/CompaniesDetailsScreenView";
import axios from "axios";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";
import {getDataForCSV} from "../../../services/FileDownloadService";


export const CompaniesDetailsScreen = () => {

    const [companies, setCompanies] = useState();
    const [loading, setLoading] = useState(true);
    const [numberOfCompaniesByType, setNumberOfCompaniesByType] = useState();
    const [activeIndex, setActiveIndex] = useState(0);
    const [mostUsedKnowledgeAreas, setMostUsedKnowledgeAreas] = useState();

    useEffect(async () => {
        await getAllCompanies();
        await getDataForChart("/admin/charts/companiesbytype", formatnumberOfCompaniesData, setNumberOfCompaniesByType, "compañías por tipo")
        await getDataForChart("/admin/charts/mostusedknowledgeareas", formatMostUsedKnowledgeAreasData, setMostUsedKnowledgeAreas, "Areas de conocimiento más utilizadas")
    }, []);

    async function deleteCompany(id) {
        const url = "company/delete/" + id;

        await axios({
            method: 'DELETE',
            url,
            data: {}
        })
            .then(async res => {
                //window.location.reload(false);
                reloadCompanies();
            }, error => {
                if (error.response.status === 506) {

                    Alert.fire({
                        title: "Ups...",
                        text: 'La compañía a borrar no existe.',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    });

                } else if (error.response.status === 507) {

                    Alert.fire({
                        title: "Ups...",
                        text: 'No es posible borrar una compañía que forma parte de un match sin eliminar el match primero.',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    });

                }
            })
            .catch((error) => {});
    }

    async function reloadCompanies() {
        getAllCompanies();
    }

    async function getAllCompanies() {
        const url = "company/allcompanies"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                let originalCompanies = res.data;
                
                let modifiedCompanies = originalCompanies.map(element => {
                    return {
                        ...element,
                        deleteCompany: deleteCompany,
                        reloadCompanies: reloadCompanies
                    }
                });

                setCompanies(modifiedCompanies);
                setLoading(false)
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: 'Ha ocurrido un error al cargar los matches, si el problema persiste contacta con soporte',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }


    function formatnumberOfCompaniesData(numberOfMatches) {
        return [
            {
                name: "Tractoras",
                value: numberOfMatches.numberOfTracting
            },
            {
                name: "Start-up",
                value: numberOfMatches.numberOfStartUp
            },
            {
                name: "Preferencia consorcios",
                value: numberOfMatches.numberOfPrefersConsortium
            },
            {
                name: "Centros I+D",
                value: numberOfMatches.numberOfCenterId
            },
            {
                name: "Institutos tecnológicos",
                value: numberOfMatches.numberOfTechnologyInstitutes
            },
            {
                name: "Centro europeo",
                value: numberOfMatches.numberOfEuropeanCenter
            },
            {
                name: "Entidad de gestión empresarial",
                value: numberOfMatches.numberOfEntityManagementBussinesArea
            },
            {
                name: "Asociación empresarial",
                value: numberOfMatches.numberOfBusinessAssociation
            },
            {
                name: "ONG",
                value: numberOfMatches.numberOfOng
            }
        ]
    }

    async function getDataForChart(url, dataFormater, setterOfState, nameOfChart) {
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                let dataFormated = dataFormater(res.data);
                setterOfState(dataFormated);
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: "Ha ocurrido un error al cargar los datos de " + nameOfChart + ", si el problema persiste contacta con soporte",
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    function formatMostUsedKnowledgeAreasData(mostUsedKnowledgAreas) {
        let mostUsedKnowledgAreasFormated = [];
        for (let i = 0; i < mostUsedKnowledgAreas.length; i++) {
            let mostUsedKA = {
                name: mostUsedKnowledgAreas[i].areaName,
                value: mostUsedKnowledgAreas[i].numberOfTimesAreaAppears
            }
            mostUsedKnowledgAreasFormated.push(mostUsedKA);
        }
        return mostUsedKnowledgAreasFormated;
    }

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    async function handleCSVDownload() {
        let dataType = "company"
        await getDataForCSV(dataType)
    }

    return (
        <CompaniesDetailsScreenView
            loading={loading}
            companies={companies}
            numberOfCompaniesByType={numberOfCompaniesByType}
            activeIndex={activeIndex}
            onPieEnter={onPieEnter}
            mostUsedKnowledgeAreas={mostUsedKnowledgeAreas}
            handleCSVDownload={handleCSVDownload}
        />
    )
}
