import styled from "styled-components";
import List from "@material-ui/core/List";
import {colorPrimary} from "../../../EnvironmentVars";
import {ResponsiveVar} from "../../../ResponsiveVars";

export const ListStyled = styled(List)`
overflow: auto;
font-weight: 400;
 border-radius: 15px;
 color:${colorPrimary};
 width:40vw;
 max-width: 700px;
@media (max-width: ${ResponsiveVar.ultraWide}) {
   max-height:60%;
}
@media (max-width: ${ResponsiveVar.desktop}) {
   max-height:60%;
}
@media (max-width: ${ResponsiveVar.laptopL}) {
    max-height:55%;
}
@media (max-width: ${ResponsiveVar.laptop}) {
    max-height:52%;
}
@media (max-width: ${ResponsiveVar.tablet}) {
    max-height:40%;
}
@media (max-width: ${ResponsiveVar.mobileL}) {
    max-height:37%;
}

@media (max-width: ${ResponsiveVar.mobileM}) {
    max-height:30%;
}

@media (max-width: ${ResponsiveVar.mobileS}) {
    max-height:28%;
}
  
`;

export const DecorationDivider = styled.div`
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 30px;
    box-shadow: 10px 10px 11px #d9d9d9, -10px -10px 11px #ffffff;
`;