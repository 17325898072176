import React, {useState} from "react";
import {HamburguerMenuView} from "../../../views/commonArea/burguerMenuViews/HamburguerMenuViews";

export function HamburgerMenu(props) {
    const [isOpen, setOpen] = useState(false);

    const toggleMenu = () => {
        setOpen(!isOpen);
    };

    return (
        <HamburguerMenuView toggleMenu={toggleMenu} isOpen={isOpen}/>
    );
}