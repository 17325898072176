import styled from "styled-components";
import {motion} from "framer-motion";
import {colorPrimary, colorSecondary} from "../../../EnvironmentVars";

export const HamburgerMenuContainer = styled.div`
  display: flex;
`;

export const MenuContainer = styled(motion.div)`
  min-width: 300px;
  width: 100%;
  max-width: 15%;
  height: 100%;
  ${({isOpen}) => isOpen && `
  box-shadow:  -11px 11px 22px #649a85,
             11px -11px 22px #88d0b5;
    `
}
${({isOpen}) => !isOpen && `
  box-shadow:  -7px 7px 14px #649a85,
             7px -7px 14px #88d0b5;
    `
}
 background: linear-gradient(45deg, ${colorPrimary}, ${colorSecondary} 120%);
  z-index: 90;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(4em);
  user-select: none;
  padding: 1em 2.5em;
  
  border-radius: 10px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5em;
`;

export const menuVariants = {
    open: {
        transform: "translateX(3%)",
    },
    closed: {
        transform: "translateX(103%)",
    },
};

export const menuTransition = {
    type: "spring",
    duration: 1,
    stiffness: 33,
    delay: 0.1,
};
