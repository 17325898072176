import {makeStyles} from "@material-ui/core/styles";
import red from '@material-ui/core/colors/red'
import teal from '@material-ui/core/colors/teal'
import {colorPrimary} from "../../../EnvironmentVars";

const techLineFormStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginLeft: '15%',
        marginBottom: '10px'
    },

    warmText: {
        color: red[500],
        fontSize: "1em",
        marginTop: '40px',
        marginLeft: '40px',
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em"
        },
    },

    textField: {
        position: 'relative',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em",
        },
    },

    textField2: {
        width: '75%',
        position: 'relative',
        left: '50%',
        top: '50%',
        transform: 'translate(85%, -50%)',
    },

    legendText: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(5),
        color: colorPrimary
    },

    formControlLabel: {
        fontSize: '1em',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '0.7em'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '0.8m'
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: '1em'
        }
    },

    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    saveButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(19)
    },
    heading: {
        padding: '5px 5px'
    },
    paper: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(58),
        width: theme.spacing(118),
    },

    hrForm: {
        border: "0.05em solid " + teal[400],
        backgroundColor: teal[400],
        borderRadius: "8px",
        margin: '20px 40px'
    },

    techLinesContainer: {
        minHeight: "50vh"
    },

    loadingComponent: {
        minHeight: "50vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "25%"
    },

    textNoResults: {
        color: colorPrimary
    }
}));
export default techLineFormStyles;