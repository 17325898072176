import styled from "styled-components";
import {motion} from "framer-motion";
import {colorPrimary} from "../../../EnvironmentVars";

export const NavMenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NavList = styled.ul`
  padding: 0 0.8em;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NavLink = styled(motion.li)`
  font-weight: 600;
  height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
  a {
    text-decoration: none;
    color: ${colorPrimary};
    font-size: 20px;
  }
  &:hover {
    a {
    }
  }
`;
export const NavTitle = styled(motion.div)`
  font-weight: 600;
  height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    a {
    }
  }
`;

export const variants = {
    show: {
        opacity: 1,
    },
    hide: {
        opacity: 0,
    },
};