import React, {createContext, useState} from 'react'

export const CompanyContext = createContext();

export const CompanyProvider = (props) => {
    const [company, setCompany] = useState(undefined);

    return (
        <CompanyContext.Provider value={[company, setCompany]}>
            {props.children}
        </CompanyContext.Provider>
    );
}