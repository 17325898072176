import React from "react";
import {
    BoldLink,
    BoxContainer,
    FormContainer,
    Input,
    SubmitButton,
    TextGrey
} from "../../../styles/commonArea/accountAccessStyles/CommonStyles";
import loginStyles from "../../../styles/commonArea/userManagementStyles/LoginStyles";
import {Typography} from "@material-ui/core";

export function LoginFormView(props) {

    const classes = loginStyles();

    return (
        <BoxContainer>
            <FormContainer onSubmit={props.handleSubmit(props.customSubmit)}>
                <Input ref={props.register} name="username" type="text" placeholder="Correo" autoComplete="off"/>
                <Input ref={props.register} name="password" type={"password"} placeholder="Password"/>
                <div>&nbsp;&nbsp;</div>
                <Typography component={'div'} className={classes.warmText}>
                    {props.errors.username?.message}
                    {props.errors.password?.message}
                    {props.loginErrorMessage}
                </Typography>
                <div>&nbsp;&nbsp;</div>
                <SubmitButton type="submit">Accede</SubmitButton>
            </FormContainer>
            <div>&nbsp;&nbsp;</div>
            <BoldLink onClick={props.switchToResetPassword}
                      whileHover={{
                          boxShadow: "6px 6px 12px #bebebe, -6px -6px 12px #ffffff",
                      }}
                      transition={{type: "spring", stiffness: 300}}>Olvidé mi contraseña</BoldLink>
            <TextGrey>
                ¿No tienes cuenta?{" "}
                <BoldLink onClick={props.switchToSignup}
                          whileHover={{
                              boxShadow: "6px 6px 12px #bebebe, -6px -6px 12px #ffffff",
                          }}
                          transition={{type: "spring", stiffness: 300}}
                >
                    Regístrate.
                </BoldLink>
            </TextGrey>
        </BoxContainer>
    );
}