import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import AssetsGeneralInfoFormView from "../../../views/userArea/stepperAssetsForm/AssetsGeneralInfoFormView";

function AssetGeneralInfoForm(props) {
    const [unitsNumber, setUnitsNumber] = useState("");
    const [assetLocation, setAssetLocation] = useState("");

    let validationSchema = yup.object().shape({
        unitsNumber: yup.number(),
    });

    const {register, handleSubmit, errors, control} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    function customSubmit(data) {
        let grlData = {unitsNumber: data.unitsNumber, assetLocation: data.assetLocation}
        let assetState = {...props.asset, grlData};
        props.setAsset(assetState);
        localStorage.setItem("assetGrlForm", JSON.stringify(assetState));
        setUnitsNumber(grlData.unitsNumber);
        setAssetLocation(grlData.assetLocation);
        props.handleNext();
    }

    let assetsGrlInfoChosenStored = JSON.parse(localStorage.getItem("assetGrlForm"));

    let unitsNumberChosen = unitsNumber ? unitsNumber : assetsGrlInfoChosenStored !== null ? assetsGrlInfoChosenStored.grlData.unitsNumber : "";
    let assetLocationChosen = assetLocation ? assetLocation : assetsGrlInfoChosenStored !== null ? assetsGrlInfoChosenStored.grlData.assetLocation : "";

    return (
        <ThemeProvider theme={theme}>
            {
                <AssetsGeneralInfoFormView handleSubmit={handleSubmit} control={control} register={register}
                                           customSubmit={customSubmit}
                                           value={unitsNumberChosen}
                                           errors={errors}
                                           unitsNumber={unitsNumberChosen}
                                           assetLocation={assetLocationChosen}
                />}
        </ThemeProvider>
    );
}

export default AssetGeneralInfoForm;