import FormLabel from "@material-ui/core/FormLabel";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import "../../../styles/commonArea/utilComponentsStyles/TechLineFormStyles.scss"
import Grid from "@material-ui/core/Grid";

function SearchInputView(props) {
    return (
        <Grid container
              spacing={0}
              justify={"center"}
              alignItems={"center"}
        >
            <Grid item xs={12}>
                <div className={"searchInputWrapper"}>

                    <input className="c-checkbox" type="checkbox" id="checkbox"/>
                    <div className="c-formContainer">
                        <div className="c-form" action="">
                            <input className="c-form__input"
                                   placeholder="Buscador"
                                   type="text"
                                   value={props.searchText}
                                   onChange={props.handleChangeSearchText}
                            />
                            <FormLabel
                                className="c-form__buttonLabel"
                                htmlFor="checkbox">
                                <button
                                    className="c-form__button"
                                    type="button"
                                    onClick={props.handleDeleteSearchInput}
                                >
                                    <ClearOutlinedIcon className={"searchButtonIcon"}/>
                                </button>
                            </FormLabel>
                            <FormLabel className="c-form__toggle" htmlFor="checkbox">
                                <SearchIcon color={"primary"}/>
                            </FormLabel>
                        </div>
                    </div>

                </div>
            </Grid>
        </Grid>
    )
}

export default SearchInputView

