import {
    BackDrop,
    backdropVariants,
    BoxContainer,
    expandingTransition,
    HeaderContainer,
    HeaderText,
    InnerContainer,
    SmallText,
    TopContainer
} from "../../../styles/commonArea/accountAccessStyles/AccountBoxStyles";
import React from "react";
import {AnimatePresence} from "framer-motion";
import {ResetPasswordSendMailForm} from "../../../components/commonComponents/accountAccessComponents/ResetPasswordSendMailForm";
import {SignupForm} from "../../../components/commonComponents/accountAccessComponents/SignupForm";
import {LoginForm} from "../../../components/commonComponents/accountAccessComponents/LoginForm";

export const AccountBoxView = (props) => {
    return (
        <BoxContainer
            initial={{scale: 0.7, opacity: 0.5}}
            animate={{scale: 1, opacity: 1}}
            transition={{
                type: "spring",
                duration: 2
            }}
        >
            <TopContainer>
                <BackDrop
                    initial={false}
                    animate={props.isExpanded ? "expanded" : "collapsed"}
                    variants={backdropVariants}
                    transition={expandingTransition}
                />
                <AnimatePresence exitBeforeEnter>
                    {
                        props.active === "signin" && (
                            <HeaderContainer whileHover={{scale: 1.2}} initial={{opacity: 0}} animate={{opacity: 1}}
                                             exit={{opacity: 0}} transition={{duration: 0.3}} key={"singin"}>
                                <HeaderText>¡Bienvenido</HeaderText>
                                <HeaderText>de nuevo!</HeaderText>
                                <SmallText/>
                            </HeaderContainer>
                        )
                    }
                    {
                        props.active === "signup" && (
                            <HeaderContainer whileHover={{scale: 1.2}} initial={{opacity: 0}} animate={{opacity: 1}}
                                             exit={{opacity: 0}} transition={{duration: 0.3}} key={"singup"}>
                                <HeaderText>¡Hola!</HeaderText>
                                <HeaderText>Regístrate</HeaderText>
                                <SmallText/>
                            </HeaderContainer>
                        )
                    }
                    {
                        props.active === "resetPassword" && (
                            <HeaderContainer whileHover={{scale: 1.2}} initial={{opacity: 0}} animate={{opacity: 1}}
                                             exit={{opacity: 0}} transition={{duration: 0.3}} key={"resetPassword"}>
                                <HeaderText>Envíame</HeaderText>
                                <HeaderText>correo de recuperación</HeaderText>
                                <SmallText/>
                            </HeaderContainer>
                        )
                    }
                </AnimatePresence>

            </TopContainer>

            <InnerContainer>
                {props.active === "signin" && <LoginForm/>}
                {props.active === "signup" && <SignupForm/>}
                {props.active === "resetPassword" && <ResetPasswordSendMailForm/>}
            </InnerContainer>
        </BoxContainer>
    )
}