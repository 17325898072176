import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import AssetsResumeInfoView
    from "../../../views/userArea/companyCardComponentsViews/assetsCapacitiesInfo/AssetsResumeInfoView";

function AssetsResumeInfo(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const [patents, setPatents] = useState();
    const [software, setSoftware] = useState();
    const [dataBases, setDataBases] = useState();
    const [tradeMark, setTradeMark] = useState();
    const [certifications, setCertifications] = useState();

    useEffect(async () => {
            await getAssetsCapacitiesInfoForResumeScreen()
        }, []
    );

    function setValuesOfAssets(assetsCapacitiesData) {
        setPatents(assetsCapacitiesData.patents);
        setSoftware(assetsCapacitiesData.software);
        setDataBases(assetsCapacitiesData.dataBases);
        setTradeMark(assetsCapacitiesData.tradeMark);
        setCertifications(assetsCapacitiesData.certifications);
    }


    async function getAssetsCapacitiesInfoForResumeScreen() {
        let data = {};
        const url = "assetsinfocompany/" + props.company.id
        let assetsCapacities = await axios({
            method: 'GET',
            url,
            data: data,
        })
        setValuesOfAssets(assetsCapacities.data)
        setLoading(false);
    }

    function editDataAssetsCapacitiesInfo() {
        history.push({
            pathname: '/assetsinfo',
            state: {company: props.company}
        })
    }


    return (loading ? <CircularProgress color={"primary"}/> :
        <AssetsResumeInfoView
            addAdditionalInfo={editDataAssetsCapacitiesInfo}
            patents={patents}
            software={software}
            dataBases={dataBases}
            tradeMark={tradeMark}
            certifications={certifications}
        />)
}

export default AssetsResumeInfo;