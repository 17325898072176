import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import Context from "../../../context/UserContext";


function PrivateRoute({
                          component: Component,
                          render: render,
                          ...rest
                      }) {
    const {jwt} = useContext(Context);
    return (
        render === undefined ?
            <Route {...rest} component={(props) => (
                jwt ? <Component {...props}/> : <Redirect to={"/login"}/>
            )}>
            </Route>
            : jwt ? <Route {...rest} render={render}/> : <Redirect to={"/login"}/>
    )
}

export default PrivateRoute;
