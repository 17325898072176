import {ModalMatchDetailsView} from "../../../views/adminArea/matchesPanelViews/ModalMatchDetailsView";
import {useState} from "react";

export const ModalMatchDetails = (props) => {

    const [loading, setLoading] = useState(false);

    return (
        <ModalMatchDetailsView
            loading={loading}
            setLoading={setLoading}
            rating={props.rating}
            setRating={props.setRating}
            match={props.match}
            getMatchTypeText={props.getMatchTypeText}
            modalRef={props.modalRef}
        />
    )

}