import React, {useState} from "react";
import {InformationSourcesScreenView} from "../../../views/adminArea/InformationSourcesScreenView";
import axios from "axios";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";

export const InformationSourcesScreen = () => {

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [textToSearch, setTextToSearch] = useState("");

    async function getDataFromBack(textToSearch) {
        setLoading(true)
        const url = "informationsources/" + textToSearch
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                setData(res.data);
                setLoading(false)
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: 'Ha ocurrido un error al buscar en los datos de WIPO y OEPM, si el problema persiste contacta con soporte',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {
        });
    }

    return (
        <InformationSourcesScreenView
            loading={loading}
            setLoading={setLoading}
            data={data}
            getDataFromBack={getDataFromBack}
            textTosearch={textToSearch}
            setTextToSearch={setTextToSearch}
        />
    )
}
