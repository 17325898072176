import React from 'react';
import adminHomeCardStyles from "../../../styles/adminArea/AdminHomeStyles/AdminHomeCardStyles";
import CountUp from 'react-countup'
import Grid from "@material-ui/core/Grid";

export default function AdminHomeCard(props) {
    const classes = adminHomeCardStyles();

    return (

        <div className={classes.cardWrapper}>

            <div className={classes.circle}>
                <Grid item xs={12}>
                    <Grid container spacing={0} align={"center"} alignItems={"center"} justify={"center"}>
                        <CountUp end={props.number} delay={0.5} duration={3.5} className={classes.numbersTypography}/>
                        {props.percent &&
                        <div className={classes.numbersTypography}>%</div>
                        }
                    </Grid>
                </Grid>
                <div style={{background: props.color}} className={classes.topDecoration}>&nbsp;&nbsp;</div>

            </div>
            <div className={classes.title}>
                {props.title}
            </div>
        </div>

    );
}
