import {makeStyles} from "@material-ui/core/styles";
import red from '@material-ui/core/colors/red'
import teal from '@material-ui/core/colors/teal'
import {colorPrimary} from "../../../EnvironmentVars";

const assetsGeneralInfoFormStyles = makeStyles((theme) => ({

    paperWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
        paddingBottom: "20px"
    },

    warmText: {
        color: red[500],
        fontSize: "1em",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em"
        },
    },

    textField: {
        width: '12%',
        minWidth: "150px",
        maxWidth: "200px",
        marginBottom: theme.spacing(2)
    },

    legendText: {
        margin: "auto",
        color: colorPrimary,
        marginBottom: theme.spacing(2)
    },

    saveButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(19)
    },

    textgrlInfo: {
        marginLeft: theme.spacing(5)
    },
    paper: {
        marginTop: theme.spacing(1),
        width: "70%",
        marginBottom: theme.spacing(1),
    },

    hrForm: {
        border: "0.05em solid " + teal[400],
        backgroundColor: teal[400],
        borderRadius: "8px",
        margin: '20px 40px'
    },

}));
export default assetsGeneralInfoFormStyles;