import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import CapacitiesGeneralFormView from "../../../views/userArea/stepperCapacitiesFormViews/CapacitiesGeneralFormView";

function CapacitiesGeneralForm(props) {

    const [amountOfPeopleRelated, setAmountOfPeopleRelated] = useState(undefined);
    const [importanceOfCapacity, setImportanceOfCapacity] = useState(undefined);
    const [hasCollabs, setHasCollabs] = useState(undefined);
    const [collabsWithCapacity, setCollabsWithCapacity] = useState(undefined);
    const [collaborationWillingness, setCollaborationWillingness] = useState(undefined);
    const [collaborationWillingnessPreferenceCapacity, setCollaborationWillingnessPreferenceCapacity] = useState(undefined);
    const [capacityApplicant, setCapacityApplicant] = useState(false);

    let collaborationCheckBoxesDefault = {
        techInstitutes: false,
        otherCompanies: false,
        universities: false,
        startUps: false,
        specializedCentres: false,
        managementEntities: false,
        europeanCentres: false,
        businessAssociations: false,
        others: false
    };

    let validationSchema = yup.object().shape(
        {
            collaborationWillingnessPreferenceCapacity: collaborationWillingness >= 4 ? yup.object().test("atLeastOneTrue", "Not matched", function (value) {
                let checkBoxesValues = Object.values(value)
                for (let i = 0; i < checkBoxesValues.length; i++) {
                    if (checkBoxesValues[i]) {
                        return true;
                    }
                }
                return false
            }) : yup.string().notRequired(),
            hasCollabs: yup.string().required(),
            collabsWithCapacity: hasCollabs === "1" ? yup.object().test("atLeastOneTrue", "Not matched", function (value) {
                let checkBoxesValues = Object.values(value)
                for (let i = 0; i < checkBoxesValues.length; i++) {
                    if (checkBoxesValues[i]) {
                        return true;
                    }
                }
                return false
            }) : yup.boolean().notRequired(),
        }
    );

    const {register, handleSubmit, errors, control} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    function customSubmit(data) {
        let capacityGeneralData =
            {
                amountOfPeopleRelated: data.amountOfPeopleRelated,
                importanceOfCapacity: data.importanceOfCapacity,
                hasCollabs: data.hasCollabs,
                collabsWithCapacity: data.collabsWithCapacity !== undefined ? data.collabsWithCapacity : collaborationCheckBoxesDefault,
                collaborationWillingness: data.collaborationWillingness,
                collaborationWillingnessPreferenceCapacity: data.collaborationWillingnessPreferenceCapacity !== undefined ? data.collaborationWillingnessPreferenceCapacity : collaborationCheckBoxesDefault,
            }
        let capacityForm = {...props.capacityForm, capacityGeneralData: capacityGeneralData};
        props.setCapacityForm(capacityForm);
        localStorage.setItem("capacityGeneralForm", JSON.stringify(capacityGeneralData));
        setAmountOfPeopleRelated(capacityGeneralData.amountOfPeopleRelated);
        setImportanceOfCapacity(capacityGeneralData.importanceOfCapacity);
        setHasCollabs(capacityGeneralData.hasCollabs);
        setCollabsWithCapacity(capacityGeneralData.collabsWithCapacity);
        setCollaborationWillingness(capacityGeneralData.collaborationWillingness);
        setCollaborationWillingnessPreferenceCapacity(capacityGeneralData.collaborationWillingnessPreferenceCapacity);

        props.handleNext();
    }

    let collaborationDataChosenStored = JSON.parse(localStorage.getItem("capacityGeneralForm"));

    let amountOfPeopleRelatedChosen = amountOfPeopleRelated ? amountOfPeopleRelated : collaborationDataChosenStored !== null ? collaborationDataChosenStored.amountOfPeopleRelated : 6;
    let importanceOfCapacityChosen = importanceOfCapacity ? importanceOfCapacity : collaborationDataChosenStored !== null ? collaborationDataChosenStored.importanceOfCapacity : 0;
    let hasCollabsChosen = hasCollabs ? hasCollabs : collaborationDataChosenStored !== null ? collaborationDataChosenStored.hasCollabs : "";
    let collabsWithCapacityChosen = collabsWithCapacity ? collabsWithCapacity : collaborationDataChosenStored !== null ? collaborationDataChosenStored.collabsWithCapacity : collaborationCheckBoxesDefault;
    let collaborationWillingnessChosen = collaborationWillingness ? collaborationWillingness : collaborationDataChosenStored !== null ? collaborationDataChosenStored.collaborationWillingness : 6;
    let collaborationWillingnessPreferenceCapacityChosen = collaborationWillingnessPreferenceCapacity ? collaborationWillingnessPreferenceCapacity : collaborationDataChosenStored !== null ? collaborationDataChosenStored.collaborationWillingnessPreferenceCapacity : collaborationCheckBoxesDefault;

    let capacityDataStored = JSON.parse(localStorage.getItem("capacityForm"));
    let capacityApplicantChosen = capacityApplicant ? capacityApplicant : capacityDataStored !== null ? capacityDataStored.capacityApplicant : false;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(function () {
        setAmountOfPeopleRelated(amountOfPeopleRelatedChosen);
        setImportanceOfCapacity(importanceOfCapacityChosen);
        setHasCollabs(hasCollabsChosen);
        setCollabsWithCapacity(collabsWithCapacityChosen);
        setCollaborationWillingness(collaborationWillingnessChosen);
        setCollaborationWillingnessPreferenceCapacity(collaborationWillingnessPreferenceCapacityChosen);
        setCapacityApplicant(capacityApplicant);
    }, [amountOfPeopleRelatedChosen, collaborationWillingnessChosen, collaborationWillingnessPreferenceCapacityChosen, collabsWithCapacityChosen, hasCollabsChosen, importanceOfCapacityChosen])

    return (
        <ThemeProvider theme={theme}>
            {
                <CapacitiesGeneralFormView handleSubmit={handleSubmit} control={control} register={register}
                                           customSubmit={customSubmit}
                                           errors={errors}
                                           hasCollabs={hasCollabs}
                                           setHasCollabs={setHasCollabs}
                                           setCollabsWithCapacity={setCollabsWithCapacity}
                                           setCollaborationWillingnessPreferenceCapacity={setCollaborationWillingnessPreferenceCapacity}
                                           setCollaborationWillingness={setCollaborationWillingness}
                                           collaborationWillingness={collaborationWillingness}
                                           amountOfPeopleRelated={amountOfPeopleRelated}
                                           importanceOfCapacityChosen={importanceOfCapacityChosen}
                                           amountOfPeopleRelatedChosen={amountOfPeopleRelatedChosen}
                                           hasCollabsChosen={hasCollabsChosen}
                                           collabsWithCapacityChosen={collabsWithCapacityChosen}
                                           collaborationWillingnessChosen={collaborationWillingnessChosen}
                                           collaborationWillingnessPreferenceCapacityChosen={collaborationWillingnessPreferenceCapacityChosen}
                                           capacityApplicantChosen={capacityApplicantChosen}
                />}
        </ThemeProvider>
    )
}

export default CapacitiesGeneralForm;