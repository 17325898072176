import {makeStyles} from "@material-ui/core/styles";
import {colorPrimary, colorSecondary} from "../../EnvironmentVars";

const navBarStyles = makeStyles((theme) => ({

    grid: {
        width: "100%",
        margin: "0px"
    },

    navBar: {
        background: "white",
    },

    title: {
        //TODO la configuración de color gradiente en texto se rompe con el navegador chrome
        // fontSize: "2em",
        //  background: "linear-gradient(45deg, " + colorPrimary + ", " +colorSecondary + " 150%)",
        //  backgroundClip: "text",
        //  textFillColor: "transparent",
        // flexGrow: 0,
        // display: "flex",
        fontSize: "2em",
        color: colorPrimary,
        [theme.breakpoints.down('xs')]: {
            fontSize: "1.2em"
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: "1.4em"
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: "1.6em"
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: "1.8em"
        },
    },

    titleLink: {
        '&:hover':
            {
                color: colorPrimary,
                textDecoration: "none",
            },
    },

    navLink: {
        color: colorPrimary,
        marginLeft: theme.spacing(1),
        textDecoration: "none",
        padding: "5px 5px",

        borderRadius: "5px",

        background: "#ffffff",
        boxShadow: "3px 3px 6px #d9d9d9, -3px -3px 6px #ffffff",
        '&:hover':
            {
                color: colorPrimary,
                textDecoration: "none",

            },
        '&:active': {
            background: "#ffffff",
            boxShadow: "0px 0px 10px #d9d9d9, -0px -0px 10px #ffffff",
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: "0.9em",
        },
    },

    logo: {
        width: "50%",
        height: "50%",
        maxWidth: "100px",
        minWidth: "50px"
    },

    separator: {
        background: "linear-gradient(45deg, " + colorPrimary + ", " + colorSecondary + " 150%)",
    }

}));
export default navBarStyles;