import React, {useEffect, useState} from "react";
import axios from "axios";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";
import {TechLinesDetailsScreenView} from "../../../views/adminArea/techlinesDetailsScreen/TechLinesDetailsScreenView";
import {getDataForCSV} from "../../../services/FileDownloadService";

export const TechLinesDetailsScreen = () => {

    const [techLinesDetails, setTechLinesDetails] = useState();
    const [loading, setLoading] = useState(true);
    const [mostUsedTechLines, setMostUsedTechLines] = useState();

    useEffect(async () => {
        await getTechLinesDetails();
        await getDataForChart("/admin/charts/mostusedtechlines", formatMostUsedTechLinesData, setMostUsedTechLines, "compañías por tipo")
        setLoading(false)
    }, []);

    async function getTechLinesDetails() {
        const url = "techlinescompany/alltechlines"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                setTechLinesDetails(res.data);

            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: 'Ha ocurrido un error al cargar los matches, si el problema persiste contacta con soporte',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    async function getDataForChart(url, dataFormater, setterOfState, nameOfChart) {
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                let dataFormated = dataFormater(res.data);
                setterOfState(dataFormated);
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: "Ha ocurrido un error al cargar los datos de " + nameOfChart + ", si el problema persiste contacta con soporte",
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    function formatMostUsedTechLinesData(mostUsedTechLines) {
        let mostUsedTechlinesFormated = [];
        for (let i = 0; i < mostUsedTechLines.length; i++) {
            let mostUsedKA = {
                name: mostUsedTechLines[i].techLineName,
                value: mostUsedTechLines[i].numberOfTimesTechLineAppears
            }
            mostUsedTechlinesFormated.push(mostUsedKA);
        }
        return mostUsedTechlinesFormated;
    }

    async function handleCSVDownload() {
        let dataType = "techLines"
        await getDataForCSV(dataType)
    }

    return (
        <TechLinesDetailsScreenView
            loading={loading}
            techLinesDetails={techLinesDetails}
            mostUsedTechLines={mostUsedTechLines}
            handleCSVDownload={handleCSVDownload}
        />
    )
}
