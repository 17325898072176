import {CustomToolTipStyled} from "../../../styles/adminArea/statisticsScreenStyle/CustomTooltScatterChartStyle";
import {statisticsOptions} from "../../../components/adminArea/statisticsScreen/statisticsOptions";

export const CustomTooltip = ({active, payload, label, xAxisName, yAxisName}) => {

    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <CustomToolTipStyled>
                    <p className="label">{payload[0].payload.name}</p>
                    <hr></hr>
                    <p className="intro">{statisticsOptions.get(xAxisName) + ": " + payload[0].payload.x}</p>
                    <p className="intro">{statisticsOptions.get(yAxisName) + ": " + payload[0].payload.y}</p>
                </CustomToolTipStyled>
            </div>
        );
    }

    return null;
};