import {makeStyles} from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import {colorPrimary, colorSecondary} from "../../../EnvironmentVars";

const ResetPasswordChangePasswordStyles = makeStyles((theme) => ({

    titleResetPassword: {
        color: colorPrimary
    },

    paper: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: "center",
        alignItems: "center",
        width: '70%',
        maxWidth: "500px",
        height: 'auto',
    },

    warmText: {
        color: red[500],
        fontSize: "0.8em",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em"
        },
    },

    textField: {
        paddingTop: theme.spacing(3),
        flexGrow: 1,
        width: 250,
        [theme.breakpoints.down('xs')]: {
            width: 200,
        },
        paddingBottom: theme.spacing(2),
    },

    formFields: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 'auto',
    },

    signUpButton: {
        background: 'linear-gradient(45deg, ' + colorPrimary + ', ' + colorSecondary + ' 130%)',
        border: 0,
        borderRadius: 10,
        textFillColor: 'white',
        height: 40,
        width: 110,
        padding: '0px 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        marginTop: 16,
        marginBottom: 24,
        marginLeft: 0,
    }
}));
export default ResetPasswordChangePasswordStyles;