import styled from "styled-components";
import {motion} from "framer-motion";
import {colorBlueGreen, colorPrimary, colorSecondary, colorTernary} from "../../../EnvironmentVars";

export const MatchCardDiv = styled(motion.div)`
  border-radius: 15px;
  padding: 10px;
  border: 5px solid rgba(200, 200, 200, 0.3);
  margin: 10px;
  min-height: 100px;
  background: white;
  color: ${colorPrimary};
  //background:linear-gradient(45deg, ${colorPrimary} -40%, ${colorSecondary} 120%);
  //color: white;
  font-weight: 800;
  font-size: 15px;
  text-align:left;
  line-height: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const cardVariants = {
    normal: {},
    dragging: {
        webkitBoxShadow: "10px 20px 49px 0px rgba(0,0,0,0.49)",
        mozBoxShadow: "10px 20px 49px 0px rgba(0,0,0,0.49)",
        boxShadow: "10px 20px 49px 0px rgba(0,0,0,0.49)"
    },
};

export const overTransition = {
    type: "spring",
    duration: 4,
    stiffness: 150,
};

