import Grid from "@material-ui/core/Grid";
import {Draggable} from "react-beautiful-dnd";
import BusinessRoundedIcon from "@material-ui/icons/BusinessRounded";
import {colorPrimary} from "../../../EnvironmentVars";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import React from "react";
import {cardVariants, MatchCardDiv, overTransition} from "../../../styles/adminArea/matchesPanelStyles/MatchCardStyles";
import {ModalMatchDetails} from "../../../components/adminArea/matchesPanelComponents/ModalMatchDetails";
import Rating from "@material-ui/lab/Rating";

export const MatchCardView = (props) => {
    return (
        <>
            <Grid container spacing={0} justify={"center"} align={"center"}>
                <Grid item xs={12}>
                    <div onClick={() => props.modalRef.current.open()}>
                        <Draggable key={props.match.id} draggableId={props.match.id + ''} index={props.index}>
                            {(provided, snapshot) =>
                                (
                                    <MatchCardDiv
                                        variants={cardVariants}
                                        animate={snapshot.isDragging ? "dragging" : "normal"}
                                        transition={overTransition}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                    >
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} align={"center"}>
                                                <h3>Match-{props.match.id}</h3>
                                            </Grid>
                                            <Grid item xs={12} align={"center"}>
                                                <Rating name="read-only" value={props.rating} readOnly/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <BusinessRoundedIcon
                                                    style={{color: colorPrimary}}
                                                />
                                                {" " + props.match.companyOne.name}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <BusinessRoundedIcon
                                                    style={{color: colorPrimary}}
                                                />
                                                {" " + props.match.companyTwo.name}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MergeTypeIcon
                                                    style={{color: colorPrimary}}
                                                />
                                                {" " + props.getMatchTypeText(props.match.matchType)}
                                            </Grid>
                                        </Grid>
                                    </MatchCardDiv>
                                )
                            }
                        </Draggable>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <ModalMatchDetails modalRef={props.modalRef}
                                       match={props.match}
                                       getMatchTypeText={props.getMatchTypeText}
                                       rating={props.rating}
                                       setRating={props.setRating}
                    />
                </Grid>
            </Grid>
        </>
    )
}