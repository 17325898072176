import axios from "axios";
import {colorPrimary} from "../EnvironmentVars";
import Alert from "sweetalert2";

export function dataToFileCSVDownload(res, dataType) {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', dataType + '-details.csv');
    document.body.appendChild(link);
    link.click();
}

export async function getDataForCSV(dataType) {
    const url = "/file/download/csv/" + dataType
    await axios({
        method: 'GET',
        url,
        data: {},
    }).then(res => {
            dataToFileCSVDownload(res, dataType);
        }, error => {
            Alert.fire({
                    title: "Ups...",
                    text: 'Ha ocurrido un error con la extracción de resultados, asegurate que existen datos, si el problema persiste contacta con soporte',
                    icon: 'warning',
                    timer: 2500,
                    showConfirmButton: false,
                    iconColor: colorPrimary
                }
            )
        }
    ).catch((error) => {

    });
}