import React from "react"
import forbiddenStyles from "../../../styles/commonArea/userManagementStyles/ForbiddenStyles";
import Button from '@material-ui/core/Button';

function forbiddenView(props) {
    const classes = forbiddenStyles();
    return (
        <div>
            <div className={classes.forbiddenWrapper}>
                <h1>ACCESO NO PERMITIDO</h1>
            </div>
            <div className={classes.forbiddenWrapper}>
                <Button className={classes.buttons} onClick={props.handleRedirectLoginButton} variant="contained"
                        color="primary">Ya estoy registrado</Button>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <Button className={classes.buttons} onClick={props.handleRedirectSignUpButton} variant="contained"
                        color="primary">Regístrate</Button>
            </div>
        </div>

    )
}

export default forbiddenView