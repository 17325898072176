import {makeStyles} from "@material-ui/core/styles";
import red from '@material-ui/core/colors/red'
import teal from '@material-ui/core/colors/teal'
import {colorPrimary} from "../../../EnvironmentVars";

const techLineHumanResourceFormStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
    },

    warmText: {
        color: red[500],
        fontSize: "1em",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em"
        },
    },

    humanResources: {
        padding: '40px 40px'
    },

    textField: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },

    legendText: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(1),
        color: colorPrimary,
    },

    paper: {
        marginTop: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '60%',

    },

    saveButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(19)
    },

    hrForm: {
        border: "0.05em solid " + teal[400],
        backgroundColor: teal[400],
        borderRadius: "8px",
        margin: '20px 40px'
    },
}));
export default techLineHumanResourceFormStyles;