import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import companyCardStyles from "../../../styles/userArea/companyCardComponentsStyles/CompanyCardStyles";
import TechLinesList from "../../../components/userArea/companyCardComponents/techLinesAccordion/TechLinesList";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid'
import AdditionalInfoForPartnership
    from "../../../components/userArea/companyCardComponents/AdditionalInfoForPartnership";
import aos from "aos";
import "aos/dist/aos.css"
import AssetsResumeInfo from "../../../components/userArea/companyCardComponents/AssetsResumeInfo";
import CapacitiesResumeInfo from "../../../components/userArea/companyCardComponents/CapacitiesResumeInfo";

function CompanyCardView(props) {

    useEffect(() => {
        aos.init({duration: 2000})
    }, []);

    const classes = companyCardStyles();
    let companyDisplay;
    if (!props.hasCompanyDefined && props.hasCompanyDefined !== undefined) {
        companyDisplay =
            <div className={classes.addCompanyButtonSection} data-aos={"zoom-out"}>
                <div className={classes.buttonWrapper}>
                    <Button onClick={props.handleAddCompanyButton} className={classes.buttonAddCompany}
                            variant="contained">Añadir entidad</Button>
                </div>
            </div>
    } else if (props.company) {
        companyDisplay =
            <div>
                &nbsp;&nbsp;&nbsp;
                <div data-aos={"zoom-out"} style={{overflowX: "hidden"}}>
                    <div className={classes.root}>
                        <Grid container direction="row" wrap='nowrap'>
                            <Grid item xs={12} zeroMinWidth>
                                <CardContent className={classes.cardContent}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12}>
                                            <Typography component={'div'} variant="h5" noWrap>
                                                <IconButton className={classes.editButton} variant="contained"
                                                            onClick={props.handleEditButton}>
                                                    <EditIcon/>
                                                </IconButton>
                                                {props.company.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className={classes.CardCompanyContent}>
                                                <Typography component={'div'} variant="body2" color="textSecondary"
                                                            noWrap>
                                                    {props.company.cif}
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div>&nbsp;&nbsp;</div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <hr></hr>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TechLinesList company={props.company}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div>&nbsp;&nbsp;</div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <hr></hr>
                                        </Grid>
                                        <Grid container spacing={0} >
                                            <Grid item xs={6} style={{
                                                borderRightColor: "white",
                                                borderRightStyle: 'solid',
                                                paddingRight:'15px',
                                                paddingBottom:'20px'
                                            }}>
                                                <AssetsResumeInfo company={props.company}/>

                                            </Grid>
                                            <Grid item xs={6} style={{paddingLeft:'15px'}}>
                                                <CapacitiesResumeInfo company={props.company}/>
                                                {/*<CapacitiesInfoCompany company={props.company}/>*/}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>&nbsp;</Grid>
                                        <Grid item xs={12}>
                                            <AdditionalInfoForPartnership company={props.company}/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>

    }
    return (
        companyDisplay
    );
}

export default CompanyCardView;