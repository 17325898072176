import React, {useState} from 'react';
import AssetsForm from "./AssetsForm";
import {useHistory} from 'react-router-dom';
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import StepperFormView from "../../../views/userArea/stepperViews/StepperFormView";
import AssetGeneralInfoForm from "./AssetGeneralInfoForm";
import AssetColaborationForm from "./AssetCollaborationForm";
import axios from "axios";
import TrackEvent from "../../../tracking/EventTracking";

export default function StepperAsset(props) {
    function getSteps() {
        return ['Activos', 'Unidades y ubicación', 'Colaboración'];
    }

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const history = useHistory();
    const [assetForm, setAssetForm] = useState(undefined);
    const [assetApplicant, setAssetApplicant] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleNext = () => {
        if (!assetApplicant) {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        } else {
            const numberOfSteps = getSteps().length
            setActiveStep((prevActiveStep) => {
                    if (prevActiveStep === numberOfSteps - 1) {
                        return prevActiveStep + 1
                    } else {
                        return prevActiveStep + 2
                    }
                }
            )
        }
    };

    const handleBack = (activeStep) => {
        if (activeStep === 0) {
            localStorage.removeItem("assetForm");
            localStorage.removeItem("assetGrlForm");
            localStorage.removeItem("assetCollaborationForm");
            history.push("company")
        } else {
            if (!assetApplicant) {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep - 2);
            }
        }
    };

    const handleReset = async () => {
        setLoading(true)
        let assetCompany = {
            techLineCompany: {id: assetForm.assetData.techLineId},
            companyId: assetForm.assetData.companyId,
            assetId: assetForm.assetData.assetId,
            name: assetForm.assetData.name,
            assetLocation: !assetForm.assetData.assetApplicant ? assetForm.grlData.assetLocation : "",
            unitsNumber: !assetForm.assetData.assetApplicant ? assetForm.grlData.unitsNumber : 0,
            collaborationWillingness: assetForm.collaborationData.collaborationWillingness,
            collaborationWillingnessPreferenceAsset: assetForm.collaborationData.collaborationWillingnessPreferenceAsset,
            collabsWithAsset: assetForm.collaborationData.collabsWithAsset,
            hasCollabs: assetForm.collaborationData.hasCollabs === "1", true: false,
            peopleHasKnowledge: assetForm.collaborationData.peopleHasKnowledge,
            techFrontierPosition: assetForm.collaborationData.techFrontierPosition,
            techLeader: assetForm.collaborationData.techLeader,
            techLeaderName: assetForm.collaborationData.techLeaderName
        }
        await axios.post("/assetscompany", assetCompany)
            .then(res => {
                    TrackEvent("store_asset", "Guarda activo", "store_asset");
                },
                error => {
                    if (error.response.status === 403) {
                        this.navigateToForbidden();
                    }
                }
            ).catch(error => {
                }
            );
        localStorage.removeItem("assetCollaborationForm");
        localStorage.removeItem("assetGrlForm");
        localStorage.removeItem("assetForm");
        setLoading(false)
        history.push('company')
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <AssetsForm
                    asset={assetForm}
                    setAsset={setAssetForm}
                    activeStep={activeStep}
                    handleNext={handleNext}
                    techLineId={props.techLineId ? props.techLineId : localStorage.getItem("techLineId")}
                    company={props.company ? props.company : localStorage.getItem("company")}
                    assetApplicant={assetApplicant}
                    setAssetApplicant={setAssetApplicant}
                />;
            case 1:
                return <AssetGeneralInfoForm
                    asset={assetForm}
                    setAsset={setAssetForm}
                    handleNext={handleNext}
                />
            case 2:
                return <AssetColaborationForm
                    asset={assetForm}
                    setAsset={setAssetForm}
                    handleNext={handleNext}
                />
            default:
                return 'Unknown stepIndex';
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <StepperFormView
                steps={steps}
                activeStep={activeStep}
                handleReset={handleReset}
                getStepContent={getStepContent}
                handleBack={handleBack}
                handleNext={handleNext}
                loading={loading}
            />
        </ThemeProvider>
    );
}