import {makeStyles} from "@material-ui/core/styles";
import React from "react";

const adminHomeStyles = makeStyles((theme) => ({

    grid: {
        width: "100%",
        height: "100%",
        margin: "0px",
    },

}));
export default adminHomeStyles;