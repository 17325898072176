export const statisticsCompaniesDataGridcolumns = [
    {
        field: 'name',
        headerName: 'Nombre entidad',
        description: 'Nombre entidad',
        flex: 1
    },
    {
        field: 'cif',
        headerName: 'CIF',
        description: 'CIF',
        flex: 1
    },
    {
        field: 'postalCode',
        headerName: 'CP',
        description: 'Código postal',
        flex: 0.3
    },
    {
        field: 'applicationUser',
        headerName: 'Nombre contacto',
        description: 'Nombre persona contacto',
        flex: 1,
        valueGetter: (params) => {
            return params.value.name + ", " + params.value.lastName
        }
    },
    {
        field: 'phone',
        headerName: 'Teléfono',
        description: 'Numero de teléfono',
        flex: 0.3,
        valueGetter: (params) => {
            return params.row.applicationUser.phone
        }
    },
    {
        field: 'email',
        headerName: 'Correo',
        description: 'Correo',
        flex: 0.5,
        valueGetter: (params) => {
            return params.row.applicationUser.email;
        }
    },
    {
        field: 'numberOfTechLines',
        headerName: 'Líneas',
        description: 'Líneas tecnológicas insertadas por la entidad',
        flex: 0.2,
    },
    {
        field: 'numberOfAssets',
        headerName: 'Activos',
        description: 'Activos insertados por la entidad',
        flex: 0.2,
    },
    {
        field: 'numberOfCapacities',
        headerName: 'Capacidades',
        description: 'Capacidades insertadass por la entidad',
        flex: 0.2,

    },

];