import React, {useEffect, useState} from "react";
import AdditionalInfoForPartnershipView
    from "../../../views/userArea/companyCardComponentsViews/AdditionalInfoForPartnershipView";
import {useHistory} from "react-router-dom";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";

function AdditionalInfoForPartnership(props) {
    const history = useHistory();
    const [partnershipPreferences, setPartnershipPreferences] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
            setLoading(true);
            let partenrshipPreferencesRes = await getPartnershipPreferences();
            setPartnershipPreferences(partenrshipPreferencesRes.data);
            setLoading(false);
        }
        , []);

    async function getPartnershipPreferences() {
        let data = {};
        const url = "partnershippreferencescompany/" + props.company.id
        return axios({
            method: 'GET',
            url,
            data: data,
        })
    }

    function addAdditionalInfo() {
        history.push({
            pathname: '/additionalinfo',
            state: {company: props.company, partnershipPreferences: partnershipPreferences}
        })
    }

    async function deleteAdditionalInfo() {

        Alert.fire({
            text: "Esta acción no se puede deshacer!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colorPrimary,
            cancelButtonColor: '#ee6123',
            iconColor: '#ee6123',
            confirmButtonText: '¡Sí, Borra la información adicional!',
            cancelButtonText: '¡Espera!'
        }).then(async (result) => {
                if (result.isConfirmed) {
                    setLoading(true);
                    localStorage.removeItem("partnershipPreferences");
                    const url = "partnershippreferencescompany/" + props.company.id
                    let data = {}

                    await axios({
                        method: 'DELETE',
                        url,
                        data: data,
                    }).then(
                        res => {
                            setPartnershipPreferences(undefined)
                            setLoading(false)
                        },
                        error => {
                            if (error.response.status === 400) {
                                setLoading(false);
                            }
                        }
                    ).catch(
                        error => {
                            console.error(error)
                        }
                    )

                    Alert.fire({
                            text: 'Se ha eliminado la información adicional.',
                            icon: 'success',
                            timer: 1500,
                            showConfirmButton: false,
                            iconColor: colorPrimary
                        }
                    )
                }
            }
        )

    }

    async function editAdditionalInfoRedirect(event, partnershipPreferences) {
        localStorage.setItem("partnershipPreferences", JSON.stringify(partnershipPreferences));
        history.push({
            pathname: '/additionalinfo',
            state: {company: props.company, partnershipPreferences: partnershipPreferences}
        })
    }

    return (loading ? <CircularProgress color={"primary"}/> :
        <AdditionalInfoForPartnershipView partnershipPreferences={partnershipPreferences}
                                          addAdditionalInfo={addAdditionalInfo}
                                          deleteAdditionalInfo={deleteAdditionalInfo}
                                          editAdditionalInfoRedirect={editAdditionalInfoRedirect}
        />)
}

export default AdditionalInfoForPartnership;