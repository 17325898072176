import React, {useEffect, useState} from "react";
import {RatingComponentView} from "../../../views/adminArea/matchesPanelViews/RatingComponentView";
import axios from "axios";

export const RatingComponent = (props) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getRating();
    }, [])

    async function getRating() {
        let data = {};
        const url = "matches/getratingmatch/" + props.match.id;
        await axios({
            method: 'GET',
            url,
            data: data,
        }).then(
            res => {
                props.setRating(res.data.matchRating);
                setLoading(false);
            },
            error => {
            }
        ).catch(
            error => {
            }
        )
    }

    async function setNewRating(id, matchRating) {
        if (id !== null && id !== undefined && matchRating !== null && matchRating !== undefined) {
            setLoading(true);
            const data = {matchId: id, matchRating: matchRating}
            await axios.put("matches/updateratingmatch", data)
                .then(
                    res => {
                        props.setRating(matchRating);
                        setLoading(false);
                    },
                    error => {

                    }
                ).catch(
                    error => {
                        console.error(error)
                    }
                )
        }
    }

    function preventLaunchingModal(event) {
        event.stopPropagation();
        return false
    }

    return (
        <RatingComponentView
            preventLaunchingModal={preventLaunchingModal}
            setNewRating={setNewRating}
            rating={props.rating}
            match={props.match}
            loading={loading}
        />
    )
}