import React, {useContext, useState} from "react";
import SignUpFormView from "../../../views/commonArea/accountAccessViews/SignUpFormView";
import LoginService from "../../../services/loginService";
import UserContext from "../../../context/UserContext";
import {useHistory} from "react-router-dom";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import axios from "axios";
import {Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {AccountContext} from "../../../context/AccountContext";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";

export function SignupForm(props) {

    const [isLogin, setIsLogin] = useState(false);
    const {login, isLogged} = LoginService();
    const {setJWT, setUserRoles} = useContext(UserContext);
    let history = useHistory();
    let [errorMessageMail, setErrorMessageMail] = useState("");
    let [errorMessageUser, setErrorMessageUser] = useState("");
    const {switchToSignin} = useContext(AccountContext);

    const SingUpSchema = yup.object().shape({
        name: yup.string()
            .required("campo requerido")
            .max(200, "El nombre no puede exceder de 200 caracteres"),
        lastName: yup.string()
            .required("campo requerido")
            .max(200, "El apellido no puede exceder de 200 caracteres"),
        email: yup.string()
            .required("campo requerido")
            .email("email no válido")
            .max(100, "El mail no puede exceder de 100 caracteres"),
        password: yup.string()
            .required("Campo requerido")
            .min(4, "el password debe contener al menos 4 caracteres")
            .max(500, "El password no puede exceder de 500 caracteres"),
        phone: yup.string()
            .required("campo requerido")
            .matches(/^[9|6]{1}([\d]{2}[-]*){3}[\d]{2}$/, "teléfono no válido")
            .max(9, "El teléfono no puede exceder de 9 caracteres")

    })

    const {register, errors, handleSubmit} = useForm(
        {
            resolver: yupResolver(SingUpSchema),
        }
    );

    async function customSubmit(data) {
        setIsLogin(true);
        data = {username: data.email, ...data}
        await axios.post("/users/sign-up", data)
            .then(async (res) => {
                    await login(data.username, data.password);
                    const userRolesRetrieved = localStorage.getItem("authorities");
                    const jwtToken = localStorage.getItem("token");
                    setUserRoles(userRolesRetrieved);
                    setJWT(jwtToken);
                    setIsLogin(false);
                    if (userRolesRetrieved !== null && userRolesRetrieved.includes("ADMIN")) {
                        history.push("admin")
                    } else if (jwtToken !== null && jwtToken !== undefined) {
                        history.push("company")
                    } else {
                        setIsLogin(false)
                        localStorage.clear()
                        history.push("signup")
                    }
                },
                error => {
                    if (error.response.status === 506) {
                        setIsLogin(false)
                        setErrorMessageUser("El usuario ya existe");
                    } else if (error.response.status === 507) {
                        setIsLogin(false);
                        setErrorMessageMail("El email ya existe");
                    } else {
                        Alert.fire({
                                title: "Ups...",
                                text: error.response.data.message,//'Se ha deshabilitado el registro, acceso solo por invitación.',
                                icon: 'warning',
                                showConfirmButton: true,
                                iconColor: colorPrimary,
                                confirmButtonColor: colorPrimary
                            }
                        ).then(() => {
                                setIsLogin(false);
                            }
                        )

                    }
                }
            ).catch(error => {
                    history.push("signup")
                }
            );
    }

    return (
        isLogin ?
            <Grid Container align={"center"} justify={"center"} spacing={0}>
                <Grid item
                      xs={12}>
                    <CircularProgress/>
                </Grid>
            </Grid>
            :
            <SignUpFormView
                register={register}
                errors={errors}
                handleSubmit={handleSubmit}
                customSubmit={customSubmit}
                errorMessageUser={errorMessageUser}
                errorMessageMail={errorMessageMail}
                switchToSignin={switchToSignin}
            />
    );
}