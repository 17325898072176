import React from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {MoreVert} from "@material-ui/icons";
import {ButtonStyled} from "../../../styles/adminArea/matchesPanelStyles/MenuMatchDetailsStyles";

export const MenuMatchDetailsView = (props) => {
    return (
        <div>
            <ButtonStyled
                ref={props.anchorRef}
                aria-controls={props.open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={props.handleToggle}
            >
                <MoreVert/>
            </ButtonStyled>
            <Popper open={props.open} anchorEl={props.anchorRef.current} role={undefined} transition disablePortal>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={props.handleClose}>
                                <MenuList autoFocusItem={props.open} id="menu-list-grow"
                                          onKeyDown={props.handleListKeyDown}>
                                    <MenuItem onClick={props.handleQuitOfKanban}>Quitar de kanban</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}