import styled from "styled-components";
import {motion} from "framer-motion";
import {colorPrimary} from "../../../EnvironmentVars";

export const Title = styled(motion.div)`
  border-radius: 5px;
  padding: 20px;
  font-weight: 600;
  font-size: 25px;
    @media (max-width: 1672px) {
    font-size: 20px;
  }
      @media (max-width: 1447px) {
    font-size: 15px;
  }
      @media (max-width: 1280px) {
    font-size: 25px;
  }
  color: ${colorPrimary};
`;


export const Decoration = styled.div`
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;
    box-shadow: 10px 10px 11px #d9d9d9, -10px -10px 11px #ffffff;
`;