import {makeStyles} from "@material-ui/core/styles";
import red from '@material-ui/core/colors/red'
import {colorPrimary} from "../../../EnvironmentVars";

const assetsFormStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1
    },

    warmText: {
        color: red[500],
        fontSize: "1em",
        marginTop: '40px',
        marginLeft: '5px',
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em"
        },
    },

    legendText: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        color: colorPrimary,
        marginBottom: theme.spacing(5),
    },

    saveButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(19)
    },

    heading: {
        padding: '5px 5px'
    },

    paper: {
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '85%',
        [theme.breakpoints.down('xs')]: {
            marginTop: '-20px',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            marginTop: '-20px',
            width: '95%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '96%',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            marginTop: '-20px',
            width: '97%',
        }
    },

    assets: {
        padding: '50px 50px'
    },

    hrAsset: {
        border: "0.05em solid " + colorPrimary,
        backgroundColor: colorPrimary,
        borderRadius: "8px",
        margin: '20px 40px'
    },

    detailName: {
        maringLeft: '150px',
        fontSize: "2em",
    }

}));
export default assetsFormStyles;