import React, {useEffect, useState} from "react";
import axios from "axios";
import TechLinesListView from "../../../../views/userArea/companyCardComponentsViews/TechLinesListView";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router-dom";
import TrackEvent from "../../../../tracking/EventTracking";
import Alert from "sweetalert2"
import {colorPrimary} from "../../../../EnvironmentVars";
import theme from "../../../../Theme";
import {ThemeProvider} from "@material-ui/styles";

function TechLinesList(props) {

    const [techLines, setTechLines] = useState([]);
    const [loading, setLoading] = useState(true);
    let history = useHistory();

    useEffect(() => {
            setLoading(true);

            async function getTechLines() {
                let data = {};
                const url = "techlinescompany/" + props.company.id
                await axios({
                    method: 'GET',
                    url,
                    data: data,
                }).then(
                    res => {
                        setTechLines(res.data)
                        // let techLines = res.data;
                        // if (techLines.length === 1) {
                        //     if (techLines[0].assetsCompany.length === 0 && techLines[0].capacitiesCompany.length === 0) {
                        //         Alert.fire({
                        //             text: "Recuerda añadir un activo o capacidad a tu línea",
                        //             icon: 'info',
                        //             showCancelButton: false,
                        //             confirmButtonColor: colorPrimary,
                        //             cancelButtonColor: '#ee6123',
                        //             iconColor: colorPrimary,
                        //             confirmButtonText: '¡Entendido!'
                        //         })
                        //     }
                        // }
                        setLoading(false)
                    },
                    error => {
                        if (error.response.status === 400) {
                            setLoading(false);
                        }
                    }
                ).catch(
                    error => {
                        console.error(error)
                    }
                )
            }

            getTechLines();
        }
        , [props.company.id]);

    const addTechLine = (e) => {
        localStorage.removeItem("techLineForm");
        localStorage.removeItem("experienceForm");
        localStorage.removeItem("workersForm");
        history.push({
            pathname: '/steppertechlineform',
            state: {company: props.company}
        })
        TrackEvent("start_tech_line_form", "Accede formulario línea tecnológica", "start_tech_line_form")
    }

    async function deleteTechLine(event, techLineCompanyId) {

        Alert.fire({
            text: "Esta acción no se puede deshacer!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: colorPrimary,
            cancelButtonColor: '#ee6123',
            iconColor: '#ee6123',
            confirmButtonText: '¡Sí, borra la línea!',
            cancelButtonText: '¡Espera!'
        }).then(async (result) => {
                if (result.isConfirmed) {
                    setLoading(true);
                    let data = {};
                    const url = "techlinescompany/" + techLineCompanyId
                    await axios({
                        method: 'DELETE',
                        url,
                        data: data,
                    }).then(
                        res => {
                            let techLinesWithoutTheErased = techLines.filter(techLineCompany => techLineCompany.id !== techLineCompanyId);
                            setTechLines(techLinesWithoutTheErased);
                            setLoading(false)
                        },
                        error => {
                            if (error.response.status === 400) {
                                setLoading(false);
                            }
                        }
                    ).catch(
                        error => {
                            console.error(error)
                        }
                    )

                    Alert.fire({
                            text: 'Se ha eliminado la línea',
                            icon: 'success',
                            timer: 1500,
                            showConfirmButton: false,
                            iconColor: colorPrimary
                        }
                    )
                }
            }
        )

    }

    return (
        loading ? <CircularProgress color={"primary"}/> :
            <ThemeProvider theme={theme}>
                <TechLinesListView
                    setTechLines={setTechLines}
                    techLines={techLines}
                    addTechLine={addTechLine}
                    deleteTechLine={deleteTechLine}
                    setLoading={setLoading}
                />
            </ThemeProvider>
    );
}

export default TechLinesList;