import {makeStyles} from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import {teal} from '@material-ui/core/colors'
import {colorPrimary} from "../../../EnvironmentVars";

const techLineExperienceFormStyles = makeStyles((theme) => ({

    root: {
        width: '100%'
    },

    warmText: {
        color: red[500],
        fontSize: "1em",
        padding: '10px 10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em"
        },
    },

    textField: {
        display: 'flex',
        margin: "auto",
        width: '225px',
        paddingBottom: theme.spacing(3),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em",
        },
    },

    legendText: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(5),
        color: colorPrimary,
    },

    legendLevelText: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        marginLeft: '0%',

    },

    formControlLabel: {
        fontSize: '1.1rem',
        whiteSpace: 'nowrap',
        [theme.breakpoints.between('xs', '299')]: {
            fontSize: '0.85rem',
            whiteSpace: 'pre-line'
        },
        [theme.breakpoints.between('300', 'md')]: {
            fontSize: '0.85rem',
            whiteSpace: 'pre-line',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '0.85rem',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: '0.95rem',
            whiteSpace: 'nowrap',
        },
    },

    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    saveButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(19)
    },

    error: {
        textColor: red
    },


    paper: {
        display: 'flex',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: "center",
        alignItems: "center",
        width: '80%',
        height: 'auto',
        padding: '5px 5px',
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '80%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '80%',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: '80%',
        },
    },

    hrForm: {
        border: "0.05em solid " + teal[400],
        backgroundColor: teal[400],
        borderRadius: "8px",
        margin: '10px 40px'
    },

    techLine: {
        padding: '40px 50px'
    },


}));
export default techLineExperienceFormStyles;