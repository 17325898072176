import React, {useContext, useRef, useState} from "react";
import {MatchCardView} from "../../../views/adminArea/matchesPanelViews/MatchCardView";
import {MatchesDnDContext} from "../../../context/MatchesDnDContext";

export const MatchCard = (props) => {

    const [rating, setRating] = useState(props.match.matchRating);
    const [loading, setLoading] = useState()
    const {setMatches} = useContext(MatchesDnDContext);
    const modalRef = useRef();

    function getMatchTypeText(matchType) {
        if (matchType === "ASSET") {
            return "Activo"
        } else if (matchType === "CAPACITY") {
            return "Capacidad"
        } else if (matchType === "TECHLINE") {
            return "Línea tecnológica"
        }
    }

    return (
        <>
            <MatchCardView
                modalRef={modalRef}
                match={props.match}
                index={props.index}
                getMatchTypeText={getMatchTypeText}
                rating={rating}
                setRating={setRating}
            />
        </>
    )
}