import {makeStyles} from "@material-ui/core/styles";
import {colorPrimary, colorSecondary} from "../../EnvironmentVars";

const footerStyles = makeStyles((theme) => ({

        footerWrapper: {
            position: "relative",
            left: 0,
            bottom: '-449px',
            width: '100%',
            textAlign: 'center',
        },

        customShapeDividerBottom: {
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            overflow: "hidden",
            lineHeight: 0,
            background: "linear-gradient(45deg, " + colorPrimary + ", " + colorSecondary + " 120%)",//colorPrimary,
        },

        svg: {
            position: "relative",
            display: "block",
            width: "calc(131% + 1.3px)",
            height: "100px",
            [theme.breakpoints.between('xs', '299')]: {
                height: "20px"
            },
            [theme.breakpoints.between('300', 'md')]: {
                height: "40px"
            },
            [theme.breakpoints.between('sm', 'md')]: {
                height: "50px"
            },
            [theme.breakpoints.between('md', 'lg')]: {
                height: "100px"
            },
        },

        shapeFill: {
            fill: "#FFFFFF",
        },

        logoIvaceGeneralitat: {
            width: "95%",
            margin: "35px",
        },
        rrss: {
            width: "95%",
            margin: "35px",
        }

    }))
;
export default footerStyles;