import React, {useEffect} from "react";
import capacitiesGeneralFormStyles from "../../../styles/userArea/capacitiesFormStyles/CapacitiesGeneralFormStyles";
import {
    Box,
    Button,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Slider,
    Typography
} from "@material-ui/core";
import {Controller} from "react-hook-form";
import CollaborationAgents from "../../../components/userArea/StepperComponents/CollaborationAgents";
import aos from "aos";
import "aos/dist/aos.css"

function CapacitiesGeneralFormView(props) {

    const classes = capacitiesGeneralFormStyles();

    useEffect(() => {
        aos.init({duration: 1000})
    }, []);

    const marksImportanceOfCapacity = [
        {
            value: -3,
            label: 'Debilidad',
        },
        {
            value: 3,
            label: 'Fortaleza',
        }
    ];

    const marksAmountOfPeopleRelated = [
        {
            value: 1,
            label: 'Muy pocas personas',
        },
        {
            value: 7,
            label: 'Toda la organización',
        }
    ];

    const marksCollaborationWillingness = [
        {
            value: 1,
            label: 'Muy baja',
        },
        {
            value: 7,
            label: 'Muy alta',
        }
    ];

    let isCapacityApplicant = props.capacityApplicantChosen;
    return (
        <form onSubmit={props.handleSubmit(props.customSubmit)} style={{overflowX: "hidden"}}>
            <Paper className={classes.paper} elevation={12} data-aos={"fade-up"}>
                <div className={classes.capacities}>
                    <h3 className={classes.legendText} component="legend">
                        Información general y de colaboración
                    </h3>
                    <Grid container direction="row">
                        <Grid item xs={2}></Grid>
                        <Grid container direction="column" item xs>
                            <Grid item xs>
                                <div className={classes.root}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography component={'div'} id="discrete-slider" gutterBottom>
                                                <h6 className={classes.h6Style}>Indica si esta capacidad se encuentra
                                                    vinculada
                                                    a una única persona o
                                                    departamento de
                                                    su empresa o si, por el contrario, se extiende a lo largo de toda la
                                                    organización</h6>
                                            </Typography> </Grid>
                                        <Grid item>
                                            <h6 style={{color: "red"}}>
                                                *
                                            </h6>
                                        </Grid>
                                    </Grid>
                                    <Controller
                                        name="amountOfPeopleRelated"
                                        control={props.control}
                                        defaultValue={props.amountOfPeopleRelatedChosen}
                                        render={(props) => (
                                            <Slider
                                                {...props}
                                                onChange={(_, value) => {
                                                    props.onChange(value);
                                                }}
                                                valueLabelDisplay="auto"
                                                max={7}
                                                step={1}
                                                min={1}
                                                marks={marksAmountOfPeopleRelated}
                                                disabled={isCapacityApplicant}
                                            />
                                        )}
                                    />
                                </div>
                            </Grid>
                            <div>&nbsp;</div>
                            <Grid item xs>
                                <div className={classes.root}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography component={'div'} id="discrete-slider" gutterBottom>
                                                <h6 className={classes.h6Style}>¿Cuál crees que es el posicionamiento de
                                                    la
                                                    entidad en esta
                                                    capacidad con respecto a la media
                                                    del sector? Considera el 0 como la media</h6>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <h6 style={{color: "red"}}>
                                                *
                                            </h6>
                                        </Grid>
                                    </Grid>
                                    <Controller
                                        name="importanceOfCapacity"
                                        control={props.control}
                                        defaultValue={isCapacityApplicant ? -3 : props.importanceOfCapacityChosen}
                                        render={(props) => (
                                            <Slider
                                                {...props}
                                                onChange={(_, value) => {
                                                    props.onChange(value);
                                                }}
                                                valueLabelDisplay="auto"
                                                max={3}
                                                min={-3}
                                                step={1}
                                                marks={marksImportanceOfCapacity}
                                                disabled={isCapacityApplicant}
                                            />
                                        )}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div>&nbsp;</div>

                    <Grid container direction="row">
                        <Grid item xs={2}></Grid>
                        <Grid container direction="column" item xs>
                            <div className={classes.root}>
                                <Grid item xs>
                                    <Grid container>
                                        <Grid item>
                                            <h6 className={classes.h6Style}>
                                                ¿Mantiene la entidad actualmente algún acuerdo de colaboración para la
                                                gestión o
                                                explotación de esta capacidad?
                                            </h6>
                                        </Grid>
                                        <Grid item>
                                            <h6 style={{color: "red"}}>
                                                *
                                            </h6>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs>
                                    <section>
                                        <Controller
                                            defaultValue={isCapacityApplicant ? "0" : props.hasCollabsChosen}
                                            as={
                                                <RadioGroup aria-label="hasCollabs"
                                                            value={props.value}
                                                >
                                                    <Grid container direction="row" item xs={10}>
                                                        <Grid key="0" item xs></Grid>
                                                        <Grid key="1" container direction="column" wrap="nowrap" item
                                                              xs>
                                                            <FormControlLabel
                                                                control=
                                                                    {
                                                                        <Radio inputRef={props.register}
                                                                               name="yes"
                                                                               value="1"
                                                                               onChange={(event) => props.setHasCollabs(event.target.value)}
                                                                               color="primary"
                                                                               disabled={isCapacityApplicant}
                                                                        />
                                                                    }
                                                                label={
                                                                    <Box className={classes.formControlLabel}>
                                                                        Sí
                                                                    </Box>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                control=
                                                                    {
                                                                        <Radio inputRef={props.register}
                                                                               name="no"
                                                                               value="0"
                                                                               onChange={(event) => props.setHasCollabs(event.target.value)}
                                                                               color="primary"
                                                                               disabled={isCapacityApplicant}
                                                                        />
                                                                    }
                                                                label=
                                                                    {
                                                                        <Box className={classes.formControlLabel}>
                                                                            No
                                                                        </Box>
                                                                    }

                                                            />
                                                        </Grid>
                                                        <Grid key="2" item xs></Grid>
                                                    </Grid>
                                                </RadioGroup>
                                            }
                                            name="hasCollabs"
                                            control={props.control}
                                        />
                                        {
                                            props.errors.hasCollabs &&
                                            <FormLabel className={classes.warmText}>
                                                Debes seleccionar si actualmente mantiene acuerdo de colaboración
                                            </FormLabel>
                                        }
                                        {props.hasCollabs === "1" &&
                                        <div>
                                            <Grid item xs>
                                                <Grid container>
                                                    <Grid item>
                                                        <h6 className={classes.h6Style}>
                                                            Indica con cuál de los siguientes agentes
                                                        </h6>
                                                    </Grid>
                                                    <Grid item>
                                                        <h6 style={{color: "red"}}>
                                                            *
                                                        </h6>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <CollaborationAgents
                                                register={props.register}
                                                nameOfOptions={"collabsWithCapacity"}
                                                chosenOptions={props.collabsWithCapacityChosen}
                                                setStateOptions={props.setCollabsWithCapacity}
                                                name={"collabsWith"}
                                            />

                                        </div>
                                        }
                                        {
                                            props.errors.collabsWithCapacity &&
                                            <FormLabel className={classes.warmText}>
                                                Debes seleccionar con que tipo de entidad mantiene colaboración
                                                actualmente.
                                            </FormLabel>
                                        }
                                    </section>
                                    <div>&nbsp;</div>
                                    <Grid item xs>
                                        <div>
                                            <Grid container>
                                                <Grid item>
                                                    <h6 className={classes.h6Style}>
                                                        Valora la predisposición de la entidad a colaborar con otros
                                                        agentes
                                                        para la mejora de
                                                        la capacidad o de su explotación
                                                    </h6>
                                                </Grid>
                                                <Grid item>
                                                    <h6 style={{color: "red"}}>
                                                        *
                                                    </h6>
                                                </Grid>
                                            </Grid>
                                            <Typography component={'div'} id="discrete-slider" gutterBottom>

                                            </Typography>
                                            <Controller
                                                defaultValue={props.collaborationWillingnessChosen}
                                                render={(renderProps) => (
                                                    <Slider
                                                        {...renderProps}
                                                        onChange={(_, value) => {
                                                            renderProps.onChange(value);
                                                            props.setCollaborationWillingness(value);
                                                        }}
                                                        valueLabelDisplay="auto"
                                                        max={7}
                                                        step={1}
                                                        min={1}
                                                        marks={marksCollaborationWillingness}
                                                    />
                                                )}
                                                name="collaborationWillingness"
                                                control={props.control}
                                            />
                                        </div>
                                        <div>&nbsp;</div>
                                        {props.collaborationWillingness >= 4 &&
                                        <div>
                                            <Grid item xs>
                                                <Grid container>
                                                    <Grid item>
                                                        <h6 className={classes.h6Style}>Indica con qué tipo de entidad
                                                            prefiere
                                                            colaborar</h6>
                                                    </Grid>
                                                    <Grid item>
                                                        <h6 style={{color: "red"}}>
                                                            *
                                                        </h6>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                            <CollaborationAgents
                                                register={props.register}
                                                nameOfOptions={"collaborationWillingnessPreferenceCapacity"}
                                                chosenOptions={props.collaborationWillingnessPreferenceCapacityChosen}
                                                setStateOptions={props.setCollaborationWillingnessPreferenceCapacity}
                                                name={"collaborationWillingnessPreference"}
                                            />

                                            {
                                                props.errors.collaborationWillingnessPreferenceCapacity &&
                                                <FormLabel className={classes.warmText}>
                                                    Debes seleccionar con que tipo de entidad prefiere
                                                    colaborar</FormLabel>
                                            }
                                        </div>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
            <br></br>
            <hr className={classes.hrCapacities}></hr>

            <div>
                <Button variant="contained" type="submit" className={classes.saveButton}>Siguiente</Button>
            </div>
        </form>
    );
}

export default CapacitiesGeneralFormView;