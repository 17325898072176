import Rating from "@material-ui/lab/Rating";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export const RatingComponentView = (props) => {

    return props.match !== undefined && props.rating !== undefined && !props.loading ?
        <div style={{height: "50px"}}>
            <Rating name="size-small"
                    size="small"
                    value={props.rating}
                    onChange={(event, newValue) => {
                        props.setNewRating(props.match.id, newValue);
                    }}
            />
        </div>
        :
        <div style={{height: "50px"}}>
            <CircularProgress/>
        </div>


}