import React, {useEffect, useState} from "react";
import SearchInputView from "../../../views/commonArea/utilComponentViews/SearchInputView";
import axios from "axios";

function SearchInput(props) {
    const [searchText, setSearchText] = useState("");
    const [openSearchBox, setOpenSearchBox] = useState(false);
    const [timeOutSearch, setTimeOutSearch] = useState(0)

    useEffect(() => {
            clearTimeout(timeOutSearch);
        }
        , []
    )

    function handleDeleteSearchInput() {
        setSearchText("");
        getAllTechLines();
    }

    function getAllTechLines() {
        const url = "knowledgeareas"
        axios({
            method: 'GET',
            url,
            data: {},
        }).then(
            res => {
                props.collectionSetter(res.data)
                props.setLoading(false)
            },
            error => {

            }
        ).catch(
            error => {
                console.error(error)
            }
        )
    }

    function getFilteredTechLines(textEntered) {
        if (timeOutSearch) {
            clearTimeout(timeOutSearch)
        }

        setTimeOutSearch(setTimeout(() => {
                    props.setLoading(true);
                    if (textEntered.length === 0) {
                        getAllTechLines();
                    } else {
                        let data = {};
                        const url = "knowledgeareas/search/" + textEntered
                        axios({
                            method: 'GET',
                            url,
                            data: data,
                        }).then(
                            res => {
                                props.collectionSetter(res.data);
                                props.setLoading(false)
                            },
                            error => {
                            }
                        ).catch(
                            error => {
                            }
                        )
                    }
                }, 800
            )
        )

    }

    function handleChangeSearchText(event) {
        let textEntered = event.target.value;

        setSearchText(textEntered);
        props.setLoading(true);
        if (textEntered.length >= 4 || textEntered.length === 0) {
            getFilteredTechLines(textEntered)
        }
        props.setLoading(false)
    }

    return (
        <SearchInputView
            handleDeleteSearchInput={handleDeleteSearchInput}
            handleChangeSearchText={handleChangeSearchText}
            searchText={searchText}
        />
    )
}

export default SearchInput;