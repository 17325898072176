import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {Add, Edit} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import AdditionalInfoForPartnershipStyles
    from "../../../styles/userArea/companyCardComponentsStyles/AdditionalInfoForPartnershipStyles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

function AdditionalInfoForPartnershipView(props) {
    const classes = AdditionalInfoForPartnershipStyles();
    return (
        <div>
            <h6>Información adicional sobre las necesidades de su entidad</h6>
            {
                !props.partnershipPreferences ?
                    <div>
                        <IconButton aria-label="add" onClick={props.addAdditionalInfo}>
                            <Add/>
                        </IconButton>
                    </div>
                    :
                    <div>
                        <IconButton className={classes.editButton} aria-label="delete"
                                    onClick={(event) => props.editAdditionalInfoRedirect(event, props.partnershipPreferences)}>
                            <Edit/>
                        </IconButton>
                        <IconButton className={classes.deleteButton} aria-label="delete"
                                    onClick={props.deleteAdditionalInfo}>
                            <HighlightOffIcon/>
                        </IconButton>

                        <Typography component={'div'} className={classes.heading}>
                            Información adicional
                        </Typography>
                        <div>&nbsp;&nbsp;</div>
                        <Typography component={'div'} className={classes.heading}>
                            {props.partnershipPreferences.additionalInfo}
                        </Typography>
                        <div>&nbsp;&nbsp;</div>
                    </div>
            }
        </div>)
}

export default AdditionalInfoForPartnershipView;