import {makeStyles} from "@material-ui/core/styles";
import {colorPrimary} from "../../../EnvironmentVars";

const homeCardStyles = makeStyles((theme) => ({

    root: {
        textAlign: "center",
        margin: "10px",
        borderRadius: "50px",
        background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
        boxShadow: "20px 20px 21px #d9d9d9, -20px -20px 21px #ffffff",
    },

    cardImage: {
        maxHeight: "350px",
        offset: 'hidden',
        [theme.breakpoints.down('538')]: {
            width: '85%',
            height: "85%",
        },
    },

    homeCardText: {
        color: colorPrimary,
        height: "100px",
        [theme.breakpoints.between('md', 'lg')]: {
            height: "120px",
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            height: "80px",
        },

    },

}));
export default homeCardStyles;