import {makeStyles} from "@material-ui/core/styles";
import teal from "@material-ui/core/colors/teal"
import {colorPrimary, colorSecondary} from "../../../EnvironmentVars";

const stepperFormStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '5px'
    },

    backButton: {
        background: 'linear-gradient(45deg, ' + colorPrimary + ', ' + colorSecondary + ' 130%)',
        border: 0,
        borderRadius: 10,
        textFillColor: 'white',
        height: 40,
        width: 100,
        padding: '0px 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        marginTop: 16,
        marginBottom: 24,
        marginLeft: 0,

        position: 'relative',
        left: '40px',
        top: '-50%',
        transform: 'translate(-1%, -220%)',
    },

    nextButton: {
        marginLeft: theme.spacing(5),
        backgroundColor: colorPrimary,
        '&:hover': {
            background: colorPrimary
        }
    },

    endButton: {
        width: "fit-content",
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: colorPrimary,
        '&:hover': {
            background: colorPrimary
        }
    },

    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),

    },

    assets: {
        padding: '10px 200px'
    },

    paper: {
        display: 'flex',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '70%',
        // height: 'auto',
        padding: '50px 0px',
        maxWidth: '500px',
    },

    formCompletedText: {
        color: colorPrimary,

    },

    hrForm: {
        border: "0.05em solid " + teal[400],
        backgroundColor: teal[400],
        borderRadius: "8px",
        margin: '10px 40px'
    },

    stepContainer: {
        minHeight: "100vh",
        backgroundColor: "#bada55"
    },

    emptySpace:{
        minHeight: "80px",
    }
}));
export default stepperFormStyles;