import React, {useEffect} from "react";
import {FormControl, FormControlLabel, Grid, Radio, RadioGroup} from "@material-ui/core";
import aos from "aos";

export default function CompanyTypeRadioButtonsView(props) {
    useEffect(() => {
        aos.init({duration: 2000})
    }, []);

    return (
        <Grid item xs={12} align={"left"}>
            <FormControl control={props.control} label={''}>
                <RadioGroup
                    name={props.radioGroupName}
                    value={props.defaultValue}
                    onChange={props.handleChangeRadioButton}
                >
                    <Grid item xs={12}>
                        <FormControlLabel inputRef={props.register} value="tractingCompany"
                                          control={<Radio color={"primary"}/>}
                                          label="Empresa tractora dinamizadora del entorno o aceleradora de jóvenes empresas innovadoras"/>
                    </Grid>
                    <FormControlLabel inputRef={props.register} value="startUpCompany"
                                      control={<Radio color={"primary"}/>}
                                      label="Start-up y/o spin-off de base tecnológica o innovadora"/>

                    <FormControlLabel inputRef={props.register} value="prefersConsortiums"
                                      control={<Radio color={"primary"}/>}
                                      label="Empresa que desea colaborar en consorcios de I+D+i"/>

                    <FormControlLabel inputRef={props.register} value="centerId"
                                      control={<Radio color={"primary"}/>}
                                      label="Centro de I+D público o privado"/>

                    <FormControlLabel inputRef={props.register} value="technologyInstitutes"
                                      control={<Radio color={"primary"}/>}
                                      label="Institutos tecnológicos"/>

                    <FormControlLabel inputRef={props.register} value="europeanCenter"
                                      control={<Radio color={"primary"}/>}
                                      label="Centro Europeo de Empresas Innovadoras o Cámara de Comercio "/>

                    <FormControlLabel inputRef={props.register} value="entityManagementBusinessArea"
                                      control={<Radio color={"primary"}/>}
                                      label="Entidad de gestión de área empresarial"/>

                    <FormControlLabel inputRef={props.register} value="businessAssociation"
                                      control={<Radio color={"primary"}/>}
                                      label="Asociación empresarial"/>

                    <FormControlLabel inputRef={props.register} value="ong" control={<Radio color={"primary"}/>}
                                      label="Organizaciones no gubernamentales y otras organizaciones del tercer sector"/>

                </RadioGroup>
            </FormControl>
        </Grid>

    )
}
