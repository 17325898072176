import React, {useContext} from 'react';
import {MenuMatchDetailsView} from "../../../views/adminArea/matchesPanelViews/MenuMatchDetailsView";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";
import axios from "axios";
import {MatchesDnDContext} from "../../../context/MatchesDnDContext";

const alertAchievementOptions = {
    text: "Como ha finalizado el match",
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: colorPrimary,
    cancelButtonColor: '#ee6123',
    iconColor: '#ee6123',
    confirmButtonText: '¡Conseguido!',
    cancelButtonText: 'Una lástima',
}

const alertQuitConfirmationOptions = {
    text: "¿Seguro que la quieres quitar?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: colorPrimary,
    cancelButtonColor: '#ee6123',
    iconColor: '#ee6123',
    confirmButtonText: '¡Sí, quitala del kanban!',
    cancelButtonText: '¡Espera!'
}

const alertManagedOptions = {
    title: "¿Ha terminado la gestión?",
    text: "No se podrá recuperar el match para el kanban en el futuro",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: colorPrimary,
    cancelButtonColor: '#ee6123',
    iconColor: '#ee6123',
    confirmButtonText: '¡Sí, ha terminado!',
    cancelButtonText: '¡Poder recuperar match!'
}

export const MenuMatchDetails = (props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const {getMatchesInKanban, columnTypes} = useContext(MatchesDnDContext);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    async function quitCardFromKanban(matchAchieved) {
        props.setLoading(true);
        props.match.achieved = matchAchieved;
        await axios.put("/matches/quitmatchkanban/", props.match)
            .then(
                res => {
                    getMatchesInKanban();
                    props.modalRef.current.close();
                    Alert.fire({
                            text: 'Se ha quitado correctamente',
                            icon: 'success',
                            timer: 1500,
                            showConfirmButton: false,
                            iconColor: colorPrimary
                        }
                    )
                    props.setLoading(false);
                },
                error => {
                    Alert.fire({
                            text: 'se ha producido un error',
                            icon: "warning",
                            timer: 1500,
                            showConfirmButton: false,
                            iconColor: colorPrimary
                        }
                    )
                    props.setLoading(false);
                }
            ).catch(
                error => {
                    console.error(error)
                }
            )
    }

    const handleQuitOfKanban = (data) => {
        Alert.fire(alertQuitConfirmationOptions).then(async (result) => {
                if (result.isConfirmed && props.match.matchStatus !== columnTypes.INITIAL) {
                    Alert.fire(alertManagedOptions).then(async result => {
                        if (result.isConfirmed) {
                            props.match.managed = true;
                            Alert.fire(alertAchievementOptions).then(async result => {
                                await quitCardFromKanban(result.isConfirmed);
                            })
                        } else if (result.isDismissed) {
                            props.match.managed = false;
                            await quitCardFromKanban();
                        }
                    });
                } else if (result.isConfirmed && props.match.matchStatus === columnTypes.INITIAL) {
                    await quitCardFromKanban();
                }
            }
        )
    }

    return (
        <MenuMatchDetailsView
            handleClose={handleClose}
            anchorRef={anchorRef}
            prevOpen={prevOpen}
            handleToggle={handleToggle}
            handleListKeyDown={handleListKeyDown}
            open={open}
            handleQuitOfKanban={handleQuitOfKanban}
        />
    )
}