import styled from "styled-components";
import {motion} from "framer-motion";

export const ATagRrssStyled = styled(motion.a)`
  margin: 10px;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    color: #0c5460;
    text-decoration: none;
    cursor: pointer;
  }

`