import React, {useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import Context from "../../../context/UserContext";

function AdminRoute({
                        component: Component,
                        render: render,
                        ...rest
                    }) {
    const {userRoles} = useContext(Context);
    return (
        render === undefined ?
            <Route {...rest} component={(props) => (
                userRoles !== null && userRoles.includes("ADMIN") ? <Component {...props}/> : <Redirect to={"/login"}/>
            )}>
            </Route>
            : userRoles !== null && userRoles.includes("ADMIN") ? <Route {...rest} render={render}/> :
                <Redirect to={"/login"}/>
    )
}

export default AdminRoute;
