import {makeStyles} from "@material-ui/core/styles";
import {colorPrimary} from "../../../EnvironmentVars";

const adminHomeCardStyles = makeStyles((theme) => ({

    title: {
        textAlign: "center",
        margin: "10px",
        fontSize: "30px",
        color: colorPrimary
    },

    topDecoration: {
        borderRadius: "5%",
        width: "100%"
    },
    numbersTypography: {
        fontSize: "70px",
        color: colorPrimary
    },

    circle: {
        textAlign: "center",
        margin: "10px",
        verticalAlign: "middle",
        borderRadius: "5%",
        width: "350px",
        background: "linear-gradient(52deg, rgba(233,233,233,1) 0%, rgba(255,255,255,1) 100%);",
        boxShadow: "10px 10px 11px #d9d9d9, -10px -10px 11px #ffffff",
    }

}));
export default adminHomeCardStyles;