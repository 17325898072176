import React from "react";
import NavBarView from "../../views/commonArea/NavBarView";

function NavBar() {

    return (
        <NavBarView
        />
    )
}

export default NavBar
