import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import TechLineCollaborationFormView
    from "../../../views/userArea/stepperTechLineFormViews/TechLineCollaborationFormView";
import {allCompanyTypes} from "../StepperComponents/companyTypes";

function TechLineCollaborationForm(props) {

    let workersChosenStored = JSON.parse(localStorage.getItem("workersForm"));
    let techLineForm = props.techLineFormContext.workersData ? props.techLineFormContext.workersData : workersChosenStored;

    const [wantToStartSoonCollaboration, setWantToStartSoonCollaboration] = useState(techLineForm ? techLineForm.wantToStartSoonCollaboration : true)
    const [timeToStartCollaboration, setTimeToStartCollaboration] = useState(techLineForm ? techLineForm.timeToStartCollaboration : 'threeOrLessMonths')

    const [collaborationWillingness, setCollaborationWillingness] = useState(techLineForm ? techLineForm.collaborationWillingness : 4);
    const [collaborationWillingnessPreference, setCollaborationWillingnessPreference] = useState(techLineForm ? getByValue(techLineForm.collaborationWillingnessPreference, true) : "tractingCompany");

    let validationSchema = yup.object().shape({
        collaborationWillingness: yup.number().required(),
    });

    const {register, handleSubmit, errors, control} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function getByValue(objToIterate, searchValue) {
        for (const key in objToIterate) {
            if (objToIterate.hasOwnProperty(key)) {
                if (objToIterate[key] === searchValue) {
                    return key;
                }
            }
        }
    }

    function handleChangeCheckWantToStartSoon(event) {
        setWantToStartSoonCollaboration(event.target.checked);
    }

    function handleChangetimeToStart(event) {
        setTimeToStartCollaboration(event.target.value);
    }

    const handleChangeCollaborationWillingNessPreference = (event) => {
        setCollaborationWillingnessPreference(event.target.value);
    };

    function getObjectFromMap() {
        let objectFromMap = {};
        for (let [key, value] of allCompanyTypes) {
            objectFromMap = {...objectFromMap, [key]: value};
        }
        return objectFromMap;
    }

    function setAllCompanyTypestoFalse(map) {
        for (let [key, value] of map.entries()) {
            map.set(key, false);
        }
    }

    function customSubmit(data) {
        setAllCompanyTypestoFalse(allCompanyTypes);
        allCompanyTypes.set(data.collaborationWillingnessPreference, true);
        let objectFromMap = getObjectFromMap(allCompanyTypes);

        let workersData = {
            wantToStartSoonCollaboration: data.wantToStartSoonCollaboration,
            timeToStartCollaboration: data.timeToStartCollaboration,
            collaborationWillingness: data.collaborationWillingness,
            collaborationWillingnessPreference: objectFromMap,
        }
        let techLineFormContext = {...props.techLineFormContext, workersData};
        props.setTechLineFormContext(techLineFormContext);
        localStorage.setItem("workersForm", JSON.stringify(workersData));
        setWantToStartSoonCollaboration(workersData.wantToStartSoonCollaboration);
        setTimeToStartCollaboration(workersData.timeToStartCollaboration);
        setCollaborationWillingness(workersData.collaborationWillingness);
        setCollaborationWillingnessPreference(data.collaborationWillingnessPreference);
        props.handleNext();
    }


    return (
        <ThemeProvider theme={theme}>
            {
                <TechLineCollaborationFormView handleSubmit={handleSubmit} control={control} register={register}
                                               customSubmit={customSubmit}
                                               errors={errors}
                                               stepperProps={props}
                                               wantToStartSoonCollaboration={wantToStartSoonCollaboration}
                                               setWantToStartSoonCollaboration={setWantToStartSoonCollaboration}
                                               handleChangeCheckWantToStartSoon={handleChangeCheckWantToStartSoon}
                                               timeToStartCollaboration={timeToStartCollaboration}
                                               handleChangetimeToStart={handleChangetimeToStart}
                                               setCollaborationWillingness={setCollaborationWillingness}
                                               setCollaborationWillingnessPreference={setCollaborationWillingnessPreference}
                                               handleChangeCollaborationWillingNessPreference={handleChangeCollaborationWillingNessPreference}
                                               collaborationWillingnessPreference={collaborationWillingnessPreference}
                                               collaborationWillingness={collaborationWillingness}

                />}
        </ThemeProvider>
    );
}

export default TechLineCollaborationForm;