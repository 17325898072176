import React from "react";
import {Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    SubTitleMatchDetails,
    TitleMatchDetails
} from "../../../styles/adminArea/matchesDetailsStyles/MatchesDetailsScreenStyles";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {colorBlueGreen, colorPrimary} from "../../../EnvironmentVars";
import {DataGridStyled} from "../../../styles/adminArea/companiesDetailsScreen/CompanyDetailsScreenStyles";
import AdminHomeCard from "../../../components/adminArea/AdminHome/AdminHomeCard";
import IconButton from "@material-ui/core/IconButton";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const matchesDataGridcolumns = [
    {
        field: 'companyOneName',
        headerName: 'Entidad 1',
        description: 'Nombre de la compañía uno',
        flex: 1,
        valueGetter: (params) =>
            `${params.getValue('companyOne').name}`
    },
    {
        field: 'companyOnePosition',
        headerName: 'Posición 1',
        description: 'Posición de la compañía uno',
        flex: 0.5,
    },
    {
        field: 'companyTwoName',
        headerName: 'Entidad 2',
        description: 'Nombre de la compañía dos',
        flex: 1,
        valueGetter: (params) =>
            `  ${params.getValue('companyTwo').name}`
    },
    {
        field: 'companyTwoPosition',
        headerName: 'Posición 2',
        description: 'Posición de la compañía dos',
        flex: 0.5,
    },
    {
        field: 'matchType',
        headerName: 'Tipo de match',
        width: 150,
        valueGetter: (params) => {
            if (params.value === "ASSET") {
                return "Activo"
            } else if (params.value === "CAPACITY") {
                return "Capacidad"
            } else if (params.value === "TECHLINE") {
                return "Línea tecnológica"
            }
        },

    },
    {
        field: 'matchingElementName',
        headerName: 'Elemento coincidente',
        flex: 1
    },
    {
        field: 'managed',
        headerName: 'Gestionado',
        valueGetter: (params) => {
            if (params.value === true) {
                return "Sí"
            } else {
                return "No"
            }
        }
    },
    {
        field: 'achieved',
        headerName: 'Satisfactorio',
        valueGetter: (params) => {
            if (params.value === true) {
                return "Sí"
            } else {
                return "No"
            }
        }
    },
    {
        field: 'matchDate',
        headerName: 'Fecha',
        width: 110,
        valueGetter: (params) => {
            let dateString = new Date(params.value).toLocaleDateString();
            let dateArray = dateString.split("T");
            return dateArray[0];

        }
    },
];

export const MatchesCompletedDetailsScreenView = (props) => {

    return (
        <Grid container spacing={0} align={"center"} justify={"center"}>
            {
                props.matchesManagedByDate && props.matchesEfficiency && !props.loading ?
                    <>
                        <Grid item xs={12}>
                            <TitleMatchDetails>Detalles de matches completados</TitleMatchDetails>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SubTitleMatchDetails>Matches completados</SubTitleMatchDetails>
                            <ResponsiveContainer width="80%" height="75%">
                                <AreaChart
                                    width={500}
                                    height={400}
                                    data={props.matchesManagedByDate}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="dateMatches"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <Area type="monotone" dataKey="matchesTotal" label={"Totales"}
                                          stroke={colorBlueGreen}
                                          fill={colorBlueGreen}/>
                                    <Area type="monotone" dataKey="matchesAchieved" label="conseguidos"
                                          stroke="#8884d8"
                                          fill="#8884d8"/>
                                    <Area type="monotone" dataKey="matchesFailed" label="fallidos"
                                          stroke="#82ca9d"
                                          fill="#82ca9d"/>

                                </AreaChart>
                            </ResponsiveContainer>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SubTitleMatchDetails>Eficiencia matches</SubTitleMatchDetails>
                            <Grid item xs={12}>
                                &nbsp;
                            </Grid>
                            <AdminHomeCard
                                percent={true}
                                number={props.matchesEfficiency}
                                color={'linear-gradient(52deg, ' + colorPrimary + ' 0%, ' + colorBlueGreen + '  100%)'}
                            />
                        </Grid>
                    </>
                    : <CircularProgress/>
            }
            {props.matches && !props.loading ?
                <>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <IconButton variant="contained" onClick={props.handleCSVDownload}>
                            <DownloadForOfflineIcon sx={{color: colorPrimary}}/>&nbsp;CSV
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{height: "100vh", width: '100%'}}>
                            <DataGridStyled
                                rows={props.matches}
                                columns={matchesDataGridcolumns}
                                pageSize={22}
                                loading={props.loading}
                                color={'linear-gradient(52deg, rgba(118,181,157,1) 0%, rgba(204,252,203,1) 100%)'}
                            />
                        </div>
                    </Grid>
                </>
                :
                <Grid item xs={12}>
                    <CircularProgress/>
                </Grid>
            }
        </Grid>
    )
}