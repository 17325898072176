import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import AssetsColaborationFormView from "../../../views/userArea/stepperAssetsForm/AssetsCollaborationFormView";

function AssetGeneralInfoForm(props) {

    const [peopleHasKnowledge, setPeopleHasKnowledge] = useState(undefined);
    const [techFrontierPosition, setTechFrontierPosition] = useState(undefined);
    const [unitsNumber, setUnitsNumber] = useState(0);
    const [hasCollabs, setHasCollabs] = useState(undefined);
    const [collabsWithAsset, setCollabsWithAsset] = useState(undefined);
    const [collaborationWillingness, setCollaborationWillingness] = useState(undefined);
    const [collaborationWillingnessPreferenceAsset, setCollaborationWillingnessPreferenceAsset] = useState(undefined);
    const [techLeader, setTechLeader] = useState(false);
    const [techLeaderName, setTechLeaderName] = useState("");
    const [assetApplicant, setAssetApplicant] = useState(false);

    let collaborationCheckBoxesDefault = {
        techInstitutes: false,
        otherCompanies: false,
        universities: false,
        startUps: false,
        specializedCentres: false,
        managementEntities: false,
        europeanCentres: false,
        businessAssociations: false,
        others: false
    };

    let validationSchema = yup.object().shape(
        {
            unitsNumber: yup.number(),

            collaborationWillingnessPreferenceAsset: collaborationWillingness >= 4 ? yup.object().test("atLeastOneTrue", "Not matched", function (value) {
                let checkBoxesValues = Object.values(value)
                for (let i = 0; i < checkBoxesValues.length; i++) {
                    if (checkBoxesValues[i]) {
                        return true;
                    }
                }
                return false
            }) : yup.string().notRequired(),
            hasCollabs: yup.string().required(),

            collabsWithAsset: hasCollabs === "1" ? yup.object().test("atLeastOneTrue", "Not matched", function (value) {
                let checkBoxesValues = Object.values(value)
                for (let i = 0; i < checkBoxesValues.length; i++) {
                    if (checkBoxesValues[i]) {
                        return true;
                    }
                }
                return false
            }) : yup.boolean().notRequired(),

            techLeader: yup.string().notRequired(),
            techLeaderName: yup.string()
                //.required('Debe indicar el nombre de empresa líder del sector')
                .min(2, 'La empresa no puede contener menos de 2 caracteres')
                .max(500, 'La empresa no puede contgener más de 500 caracteres')
        }
    );

    const {register, handleSubmit, errors, control, getValues, setValue} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    function customChangeTechLeader(event) {
        let checked = event.target.checked
        checked ? setTechLeaderName(company.name) : setTechLeaderName("")
        setTechLeader(event.target.checked)
    }

    function customSubmit(data) {
        let company = JSON.parse(localStorage.getItem("company"))
        let collaborationData =
            {
                peopleHasKnowledge: data.peopleHasKnowledge,
                techFrontierPosition: data.techFrontierPosition,
                techLeader: data.techLeader,
                techLeaderName: data.techLeader === "true" && data.techLeaderName === undefined ? company.name :
                    data.techLeader === "false" && data.techLeaderName === undefined ? ' ' : data.techLeaderName,
                hasCollabs: data.hasCollabs,
                collabsWithAsset: data.collabsWithAsset !== undefined ? data.collabsWithAsset : collaborationCheckBoxesDefault,
                collaborationWillingness: data.collaborationWillingness,
                collaborationWillingnessPreferenceAsset: data.collaborationWillingnessPreferenceAsset !== undefined ? data.collaborationWillingnessPreferenceAsset : collaborationCheckBoxesDefault,
            }
        let assetForm = {...props.asset, collaborationData: collaborationData};
        props.setAsset(assetForm);
        localStorage.setItem("assetCollaborationForm", JSON.stringify(collaborationData));
        setPeopleHasKnowledge(collaborationData.peopleHasKnowledge);
        setTechFrontierPosition(collaborationData.techFrontierPosition);
        setTechLeader(collaborationData.techLeader);
        setTechLeaderName(collaborationData.techLeaderName);
        setHasCollabs(collaborationData.hasCollabs);
        setCollabsWithAsset(collaborationData.collabsWithAsset);
        setCollaborationWillingness(collaborationData.collaborationWillingness);
        setCollaborationWillingnessPreferenceAsset(collaborationData.collaborationWillingnessPreferenceAsset);
        props.handleNext();
    }


    let collaborationDataChosenStored = JSON.parse(localStorage.getItem("assetCollaborationForm"));
    let company = JSON.parse(localStorage.getItem("company"))
    let peopleHasKnowledgeChosen = peopleHasKnowledge ? peopleHasKnowledge : collaborationDataChosenStored !== null ? collaborationDataChosenStored.peopleHasKnowledge : 6;
    let techFrontierPositionChosen = techFrontierPosition ? techFrontierPosition : collaborationDataChosenStored !== null ? collaborationDataChosenStored.techFrontierPosition : 0;
    let techLeaderChosen = techLeader ? techLeader : collaborationDataChosenStored !== null ? collaborationDataChosenStored.techLeader : "";
    let techLeaderNameChosen = techLeader ? company.name : collaborationDataChosenStored !== null ? collaborationDataChosenStored.techLeaderName : "";
    let hasCollabsChosen = hasCollabs ? hasCollabs : collaborationDataChosenStored !== null ? collaborationDataChosenStored.hasCollabs : "";
    let collabsWithAssetChosen = collabsWithAsset ? collabsWithAsset : collaborationDataChosenStored !== null ? collaborationDataChosenStored.collabsWithAsset : collaborationCheckBoxesDefault;
    let collaborationWillingnessChosen = collaborationWillingness ? collaborationWillingness : collaborationDataChosenStored !== null ? collaborationDataChosenStored.collaborationWillingness : 6;
    let collaborationWillingnessPreferenceAssetChosen = collaborationWillingnessPreferenceAsset ? collaborationWillingnessPreferenceAsset : collaborationDataChosenStored !== null ? collaborationDataChosenStored.collaborationWillingnessPreferenceAsset : collaborationCheckBoxesDefault;

    let assetDataStored = JSON.parse(localStorage.getItem("assetForm"));
    let assetApplicantChosen = assetApplicant ? assetApplicant : assetDataStored !== null ? assetDataStored.assetApplicant : false;

    useEffect(function () {
        window.scrollTo(0, 0);
        setPeopleHasKnowledge(peopleHasKnowledgeChosen);
        setTechFrontierPosition(techFrontierPositionChosen);
        setTechLeader(techLeaderChosen);
        setTechLeaderName(techLeaderNameChosen);
        setHasCollabs(hasCollabsChosen);
        setCollabsWithAsset(collabsWithAssetChosen);
        setCollaborationWillingness(collaborationWillingnessChosen);
        setCollaborationWillingnessPreferenceAsset(collaborationWillingnessPreferenceAssetChosen);
        setAssetApplicant(assetApplicantChosen);
    }, [])

    return (

        <ThemeProvider theme={theme}>
            {
                <AssetsColaborationFormView handleSubmit={handleSubmit} control={control} register={register}
                                            getValues={getValues}
                                            setValue={setValue}
                                            customSubmit={customSubmit}
                                            customChangeTechLeader={customChangeTechLeader}
                                            value={unitsNumber}
                                            errors={errors}
                                            hasCollabs={hasCollabs}
                                            setHasCollabs={setHasCollabs}
                                            techLeader={techLeader}
                                            setTechLeader={setTechLeader}
                                            techLeaderName={techLeaderName}
                                            setTechLeaderName={setTechLeaderName}
                                            setCollabsWith={setCollabsWithAsset}
                                            setCollaborationWillingnessPreference={setCollaborationWillingnessPreferenceAsset}
                                            setCollaborationWillingness={setCollaborationWillingness}
                                            collaborationWillingness={collaborationWillingness}
                                            peopleHasKnowledge={peopleHasKnowledge}
                                            techFrontierPositionChosen={techFrontierPositionChosen}
                                            techLeaderChosen={techLeaderChosen}
                                            techLeaderNameChosen={techLeaderNameChosen}
                                            peopleHasKnowledgeChosen={peopleHasKnowledgeChosen}
                                            hasCollabsChosen={hasCollabsChosen}
                                            collabsWithChosen={collabsWithAssetChosen}
                                            collaborationWillingnessChosen={collaborationWillingnessChosen}
                                            collaborationWillingnessPreferenceChosen={collaborationWillingnessPreferenceAssetChosen}
                                            company={company.name}
                                            defaultValues={techLeaderName}
                                            assetApplicantChosen={assetApplicantChosen}
                />}
        </ThemeProvider>
    );
}

export default AssetGeneralInfoForm;