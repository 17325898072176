import React, {useEffect, useState} from "react";
import TechLineExperienceFormView from "../../../views/userArea/stepperTechLineFormViews/TechLineExperienceFormView";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";

function TechLineExperienceForm(props) {
    let experienceChosenStored = JSON.parse(localStorage.getItem("experienceForm"));
    let receivedForm = props.techLineFormContext.experienceData
    let techLineForm = receivedForm ? receivedForm : experienceChosenStored;


    const [yearsExperience, setYearsExperience] = useState(techLineForm ? techLineForm.yearsExperience : "");
    const [techFrontierPosition, setTechFrontierPosition] = useState(techLineForm ? techLineForm.techFrontierPosition : 0);

    let validationSchema = yup.object().shape({
        yearsExperience: yup.number(),
    });

    const {register, handleSubmit, errors, control} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function customSubmit(data) {

        let experienceData = {
            yearsExperience: data.yearsExperience,
            techFrontierPosition: data.techFrontierPosition
        }
        let techLineFormContext = {...props.techLineFormContext, experienceData};
        props.setTechLineFormContext(techLineFormContext);
        localStorage.setItem("experienceForm", JSON.stringify(experienceData));
        setYearsExperience(experienceData.yearsExperience);
        setTechFrontierPosition(data.techFrontierPosition);
        props.handleNext();
    }


    return (
        <ThemeProvider theme={theme}>
            {
                <TechLineExperienceFormView handleSubmit={handleSubmit} control={control} register={register}
                                            customSubmit={customSubmit}
                                            errors={errors}
                                            stepperProps={props}
                                            yearsExperience={yearsExperience}

                                            techFrontierPosition={techFrontierPosition}
                />}
        </ThemeProvider>
    );
}

export default TechLineExperienceForm;