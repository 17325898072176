import React, {useEffect} from "react";
import CompanyFormStyle from "../../../styles/userArea/companyCardComponentsStyles/CompanyFormStyle";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import "aos/dist/aos.css"
import CompanyTypeRadioButtons from "./CompanyTypeRadioButtons";
import aos from "aos";
import {Autocomplete} from "@material-ui/lab";
import {CircularProgress, Grid} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Controller} from "react-hook-form";

function CompanyFormView(props) {
    const classes = CompanyFormStyle();

    useEffect(() => {
        aos.init({duration: 2000})
    }, []);

    return (
        <div style={{overflowX: "hidden"}} data-aos={"fade_up"}>
            <Paper className={classes.paper} elevation={12}>
                <form onSubmit={props.handleSubmit(props.customSubmit)}>
                    <Grid container align={"center"} justify={"center"} alignItems={"center"} spacing={2}>
                        {/*<div className={classes.formFields}>*/}
                        <Grid item xs={12} style={{marginTop: '20px', marginBottom: '20px'}}>
                            <h4 className={classes.h4Style}>Información de la entidad </h4>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                type="text"
                                name="name"
                                label="Nombre de la entidad"
                                onChange={(e) => props.handleChangeTextInputs(e)}
                                value={props.name}
                                inputRef={props.register}
                            />
                        </Grid>
                        {props.errors.name?.message &&
                            <Grid item xs={12} md={6}>
                                <Typography className={classes.warmText}>
                                    {props.errors.name?.message}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            <TextField
                                type="text"
                                name="cif"
                                label="CIF"
                                onChange={(e) => props.handleChangeTextInputs(e)}
                                value={props.cif}
                                inputRef={props.register}
                            />
                        </Grid>
                        {props.errors.cif?.message &&
                            <Grid item xs={12} md={6}>
                                <Typography className={classes.warmText}>
                                    {props.errors.cif?.message}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            <TextField
                                type="text"
                                name="postalCode"
                                label="Código postal"
                                onChange={(e) => props.handleChangeTextInputs(e)}
                                value={props.postalCode}
                                inputRef={props.register}
                            />
                            {props.errors.postalCode?.message &&
                                <Grid item xs={12} md={6}>
                                    <Typography className={classes.warmText}>
                                        {props.errors.postalCode?.message}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                type="text"
                                name="cnae"
                                label="CNAE"
                                onChange={(e) => props.handleChangeTextInputs(e)}
                                value={props.cnae}
                                inputRef={props.register}
                            />
                        </Grid>
                        {props.errors.cnae &&
                            <Grid item xs={12} md={6}>
                                <Typography className={classes.warmText}>
                                    CNAE debe ser numérico
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12} md={6}>
                            <TextField
                                type="text"
                                name="web"
                                label="Web"
                                onChange={(e) => props.handleChangeTextInputs(e)}
                                value={props.web}
                                inputRef={props.register}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                type="text"
                                name="numberOfWorkers"
                                label="Número de trabajadores"
                                onChange={(e) => props.handleChangeTextInputs(e)}
                                value={props.numberOfWorkers}
                                inputRef={props.register}
                            />
                        </Grid>
                        <Grid item xs={12} style={{padding: '15px'}}>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        inputRef={props.register}
                                        name={'isLocatedInTechPark'}
                                        color="primary"
                                        checked={props.isLocatedInTechPark}
                                        onChange={(e) => props.handleChangeIsLocatedInTechPark(e)}/>
                                }
                                name={'isLocatedInTechPark'}
                                label={'Pertenece a un parque o polígono empresarial'}
                                style={{marginLeft: '35px'}}
                            />
                        </Grid>
                        {props.isLocatedInTechPark ?
                            props.techParks ?
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        render={({onChange, ...args}) => (
                                            <Autocomplete
                                                options={props.techParks}
                                                getOptionLabel={option => option.name}
                                                groupBy={option => option.town}
                                                renderInput={(params) => <TextField {...params}
                                                                                    label="Parque empresarial"
                                                                                    margin="normal"/>}
                                                onChange={(e, data) => onChange(data)}
                                                style={{width: '200px'}}
                                                {...args}
                                            />
                                        )}

                                        onChange={([, data]) => data}
                                        defaultValue={props.techParkSelected}
                                        name={"techPark"}
                                        control={props.control}
                                    />

                                </Grid> : <><CircularProgress/></> : <Grid item xs={6}>&nbsp; </Grid>
                        }
                        <Grid item xs={12} style={{padding: '15px'}}></Grid>
                        <Grid item xs={12}>
                            <h5 className={classes.h4Style}>Tipo de entidad</h5>
                        </Grid>
                        <Grid item xs={12} style={{paddingLeft: '35px', paddingRight: '35px'}}>
                            <Typography>
                                Señala cuál o cuáles de las siguientes opciones crees que describen mejor a tu
                                organización:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            paddingLeft: '50px',
                            paddingTop: '30px',
                            paddingRight: '35px',
                            paddingBottom: '35px'
                        }}>
                            <CompanyTypeRadioButtons
                                register={props.register}
                                control={props.control}
                                defaultValue={props.companyType}
                                handleChangeRadioButton={props.handleChangeRadioButton}
                                radioGroupName={"companyType"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" type="submit" className={classes.saveButton}>
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </div>
    )
}

export default CompanyFormView;