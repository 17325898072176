import React, {forwardRef, useImperativeHandle, useState} from "react";
import {AnimatePresence, motion} from "framer-motion";
import styled from 'styled-components'
import Grid from "@material-ui/core/Grid";

export const ModalBackDrop = styled(motion.div)`
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: linear-gradient(180deg,transparent 0%, rgba(32, 110, 111,0.6) 35%, rgb(204, 252, 203,0.9) 70%, transparent 85%);
  z-index: 1;
 `
export const ModalContentWrapper = styled(motion.div)`
border-radius: 15px;
 position: fixed;
  width: 75%;
  height: 75%;
  max-width: 800px; 
  max-Height: 650px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px;
  background: rgba( 255, 255, 255, 0.90 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 20.0px );
  -webkit-backdrop-filter: blur( 20.0px );
  z-index: 2;
 `

export const ModalContent = styled(motion.div)`  
background: transparent;
 position: fixed;
   width: 75%;
  height: 90%;
  max-width: 800px; 
  max-Height: 700px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 50px;
 `

export const Modal = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            open: () => setOpen(true),
            close: () => setOpen(false)
        };
    });

    return (
        <Grid container spacing={0} justify={"center"} alignItems={"center"}>
            <AnimatePresence>
                {open && (
                    <>
                        <ModalBackDrop
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1,
                                transition: {
                                    duration: 0.3
                                }
                            }}
                            exit={{
                                opacity: 0,
                                transition: {
                                    delay: 0.3
                                }
                            }}
                            onClick={() => setOpen(false)}
                        />
                        <ModalContentWrapper
                            animate={
                                {
                                    scale: 1,
                                    transition: {
                                        duration: 0.3
                                    }
                                }
                            }
                            exit={{
                                scale: 0,
                                transition: {
                                    delay: 0.3
                                }
                            }}

                        >
                            <Grid container spacing={0} justify={"center"} align={"center"}>
                                <Grid item xs={12}>
                                    <ModalContent
                                        initial={{
                                            x: 100,
                                            opacity: 0
                                        }}
                                        animate={{
                                            x: 0,
                                            opacity: 1,
                                            transition: {
                                                delay: 0.3,
                                                duration: 0.3
                                            }
                                        }}
                                        exit={{
                                            x: 100,
                                            opacity: 0,
                                            transition: {
                                                duration: 0.3
                                            }
                                        }}
                                    >
                                        {props.children}
                                    </ModalContent>
                                </Grid>
                            </Grid>
                        </ModalContentWrapper>
                    </>
                )}
            </AnimatePresence>
        </Grid>
    );
});
