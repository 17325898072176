import styled from "styled-components";
import {motion} from "framer-motion";

export const ColumnDivStyles = styled(motion.div)`
  max-width: 500px;
  border: 5px solid lightgrey;
  border-image-slice: 1;
  padding: 10px;
  margin: 20px;
  min-height: 600px;
  border-radius: 20px;
  background:  linear-gradient(52deg, rgba(233,233,233,1) 0%, rgba(255,255,255,1) 100%);
  position: relative;
`;

export const DivButtonAddCards = styled(motion.div)`
position:absolute;
bottom: 10px;
left:44%;
`;
