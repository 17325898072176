import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import React from "react";
import assetsCollaborationFormStyles from "../../../styles/userArea/assetsFormStyles/AssetsCollaborationFormStyles";
import CompanyTypeRadioButtons from "../companyCardComponentsViews/CompanyTypeRadioButtons";

export default function CollaborationWillingnessPreference(props) {
    const classes = assetsCollaborationFormStyles();

    return <div>
        <Grid item xs>
            <Grid container justify={"center"} alignContent={"center"} align={"center"}>
                <Grid item xs={12}>
                    <h6 style={{display: "inline"}}>Indica con qué tipo de entidad prefieres colaborar</h6>
                    <div style={{color: "red", display: "inline"}}>
                        *
                    </div>
                </Grid>
                <Grid item xs={12}>
                    &nbsp;
                </Grid>
            </Grid>

        </Grid>
        <Grid item xs={12}>
            <CompanyTypeRadioButtons
                register={props.register}
                control={props.control}
                companyType={props.companyType}
                handleChangeRadioButton={props.handleChangeRadioButton}
                radioGroupName={"collaborationWillingnessPreference"}
                defaultValue={props.defaultValue}
            />
        </Grid>
        {
            props.errors.collaborationWillingnessPreferenceAsset &&
            <FormLabel className={classes.warmText}>
                Debes seleccionar con que tipo de entidad prefieres
                colaborar</FormLabel>
        }
    </div>;
}