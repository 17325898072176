import {Modal} from "../../../components/commonComponents/utilComponents/Modal";
import Grid from "@material-ui/core/Grid";
import {Decoration} from "../../../styles/adminArea/matchesPanelStyles/CommonMatchesPanelStyles";
import {Button} from "@material-ui/core";
import React from "react";
import {colorPrimary} from "../../../EnvironmentVars";
import {DataGrid} from "@material-ui/data-grid";

const matchesDataGridcolumns = [{
    field: 'companyOneName',
    headerName: 'Entidad 1',
    description: 'Nombre de la compañía uno',
    width: 180,
    valueGetter: (params) => `${params.getValue('companyOne').name}`
}, {
    field: 'companyTwoName',
    headerName: 'Entidad 2',
    description: 'Nombre de la compañía dos',
    width: 180,
    valueGetter: (params) => `  ${params.getValue('companyTwo').name}`

}, {
    field: 'matchLevel',
    headerName: 'Nivel de match',
    description: 'Nivel de match por escala de coincidencia',
    width: 180,
}, {field: 'matchingElementName', headerName: 'Elemento coincidente', width: 200},];

export const ModalAddMatchesToKanbanView = (props) => {
    return (<Modal ref={props.modalRef}>
        <Grid container spacing={0} alignItems={"center"} justify={"center"}>
            <Grid item xs={12}>
                <h1 style={{color: colorPrimary}}>Nuevos Matches</h1>
            </Grid>
            <Grid item xs={12}>
                <Decoration style={{background: props.columns[props.columnId].backgroundColor}}/>
            </Grid>
            <Grid item xs={12}>
                {props.matchesNotInKanban && <div style={{height: "34vh", width: '100%'}}>
                    <DataGrid rows={props.matchesNotInKanban}
                              columns={matchesDataGridcolumns}
                              pageSize={5}
                              loading={props.loading}
                              checkboxSelection
                              onSelectionModelChange={(newSelection) => {
                                  props.updateSelected(newSelection.selectionModel);
                              }}
                    />
                </div>}
            </Grid>
            <Grid item xs={12}>
                <Button style={{width: "40vw", maxWidth: "190px"}}
                        onClick={() => props.updateSelectedItemsForKanban()}>
                    Añadir selección
                </Button>
            </Grid>
        </Grid>
    </Modal>)
}