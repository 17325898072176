import React, {useContext, useState} from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {ResetPasswordSendMailView} from "../../../views/commonArea/accountAccessViews/ResetPasswordSendMailView";
import {colorPrimary} from "../../../EnvironmentVars";
import axios from "axios";
import Alert from "sweetalert2";
import {AccountContext} from "../../../context/AccountContext";

export function ResetPasswordSendMailForm(props) {

    const [loading, setLoading] = useState(false);
    const {switchToSignin} = useContext(AccountContext);

    const sendResetPasswordMailValidation = yup.object().shape({
        email: yup.string()
            .required("campo requerido")
            .email("email no válido")
            .max(100, "El mail no puede exceder de 100 caracteres"),
    })

    const {register, errors, handleSubmit} = useForm({
        resolver: yupResolver(sendResetPasswordMailValidation)
    });

    async function customSubmit(data) {
        setLoading(true);
        await axios.post("/resetpassword", data.email)
            .then((res) => {
                    Alert.fire({
                            text: "Se ha enviado el correo con exito a: " + data.email,
                            icon: 'success',
                            timer: 2500,
                            showConfirmButton: false,
                            iconColor: colorPrimary
                        }
                    ).then(() => {
                        setLoading(false);
                        switchToSignin();
                    })

                },
                error => {
                    Alert.fire({
                            title: "Lo sentimos, algo ha salido mal",
                            text: "No se ha enviado el correo a: " + data.email + "." + "\n Por favor comprueba que el correo es correcto.",
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: "De acuerdo!",
                            confirmButtonColor: colorPrimary
                        }
                    )
                    setLoading(false);
                }
            ).catch(error => {

                }
            );
    }

    return (
        <ResetPasswordSendMailView
            handleSubmit={handleSubmit}
            customSubmit={customSubmit}
            register={register}
            errors={errors}
            loading={loading}
            switchToSignin={switchToSignin}
        />
    )
}