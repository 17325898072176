import {colorBlueGreen, colorPrimary, colorSecondary} from "../../../EnvironmentVars";
import {Modal} from "../../../components/commonComponents/utilComponents/Modal";
import React from "react";
import {Grid} from "@material-ui/core";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import ExtensionOutlinedIcon from "@material-ui/icons/ExtensionOutlined";
import {Decoration} from "../../../styles/adminArea/matchesPanelStyles/CommonMatchesPanelStyles";
import {MenuMatchDetails} from "../../../components/adminArea/matchesPanelComponents/MenuMatchDetails";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DecorationDivider, ListStyled} from "../../../styles/adminArea/matchesPanelStyles/ModalMatchdetailsStyles";
import {CompanyContactDetails} from "../../../components/adminArea/matchesPanelComponents/CompanyContactDetails";
import {RatingComponent} from "../../../components/adminArea/matchesPanelComponents/RatingComponent";

export const ModalMatchDetailsView = (props) => {
    return (
        <Modal ref={props.modalRef}>
            <Grid container spacing={0}>
                <Grid item xs={11} align={"center"} style={{marginTop: "18px"}}>
                    <h1 style={{color: colorPrimary}}>Detalles match-{props.match.id}</h1>
                </Grid>
                <Grid item xs={1}>
                    <MenuMatchDetails setLoading={props.setLoading} match={props.match} modalRef={props.modalRef}/>
                </Grid>

                {props.loading ?
                    <Grid container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justify="center"
                          style={{minHeight: '40vh'}}>
                        <Grid item xs={12}>
                            <CircularProgress/>
                        </Grid>
                    </Grid>
                    : <>
                        <Grid item xs={12} align={"center"}>
                            {props.match &&
                            <RatingComponent rating={props.rating} setRating={props.setRating} match={props.match}/>}
                        </Grid>
                        <Grid item xs={12}>
                            <Decoration
                                style={{background: "linear-gradient(45deg," + colorSecondary + ", " + colorBlueGreen + " 120%)"}}/>
                        </Grid>
                        <Grid container
                              spacing={0}
                              justify={"center"}
                              style={{textAlign: "left", lineHeight: "40px", fontSize: "20px"}}
                        >
                            <ListStyled>
                                <Grid item xs={12}>
                                    <CompanyContactDetails company={props.match.companyOne}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <CompanyContactDetails company={props.match.companyTwo}/>
                                </Grid>
                                <Grid item xs={12}>
                                    Información del match
                                </Grid>
                                <Grid item xs={12}>
                                    <DecorationDivider style={{backgroundColor: colorPrimary}}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <MergeTypeIcon style={{color: colorPrimary}}/>
                                    <div style={{display: "inline-block", color: colorPrimary, fontWeight: 600}}>Tipo:
                                    </div>
                                    {" " + props.getMatchTypeText(props.match.matchType)}
                                </Grid>
                                <Grid item xs={12}>
                                    <ExtensionOutlinedIcon style={{color: colorPrimary}}/>
                                    <div style={{
                                        display: "inline-block",
                                        color: colorPrimary,
                                        fontWeight: 600
                                    }}>Elemento:
                                    </div>
                                    {" " + props.match.matchingElementName}
                                </Grid>
                            </ListStyled>
                        </Grid>
                    </>
                }
            </Grid>
        </Modal>
    )
}