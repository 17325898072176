export const colorPrimary = "rgb(32, 110, 111)"; //#206e6f
// export const colorPrimary = "rgb(145, 146, 204)";
export const colorSecondary = "rgb(204, 252, 203)";
export const colorTernary = "rgb(118, 181, 157)";
export const colorBlueGreen = "rgb(171, 224, 229)";
export const colorBlue = "#8AC4FF";
export const colorBluePurple = "rgb(145, 146, 204)";
export const colorPurple = "#985F99";
export const colorOrange = "#EE6123";
export const colorPalidOrange = "rgb(241, 124, 73)";
export const colorPrimaryTranslucid = "rgba(32, 110, 111,0.3)";