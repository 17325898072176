import React from "react";
import {Grid} from "@material-ui/core";
import BusinessRoundedIcon from "@material-ui/icons/BusinessRounded";
import {colorPrimary} from "../../../EnvironmentVars";
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import {DecorationDivider} from "../../../styles/adminArea/matchesPanelStyles/ModalMatchdetailsStyles";

export const ContactCompanyDetailsView = (props) => {
    let user = props.company.applicationUser;
    let company = props.company;
    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <BusinessRoundedIcon style={{color: colorPrimary}}/>{" " + company.name}
            </Grid>
            <Grid item xs={12}>
                <DecorationDivider style={{backgroundColor: colorPrimary}}/>
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={11}>
                <ContactMailOutlinedIcon style={{color: colorPrimary}}/>
                {" " + user.name + ", " + user.lastName}
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={11}>
                <MailOutlineTwoToneIcon style={{color: colorPrimary}}/>
                {" " + user.email}
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={11}>
                <PhoneOutlinedIcon style={{color: colorPrimary}}/>
                {" " + user.phone}
            </Grid>
            <div>&nbsp;</div>
        </Grid>
    )
}