import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import CapacitiesResumeInfoView
    from "../../../views/userArea/companyCardComponentsViews/assetsCapacitiesInfo/CapacitiesResumeInfoView";

function CapacitiesResumeInfo(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    const [anticipateTechChangesCapacity, setAnticipateTechChangesCapacity] = useState(1)
    const [newBusinessDiversificationCapacity, setNewBusinessDiversificationCapacity] = useState(1)
    const [digitalizationCapacity, setDigitalizationCapacity] = useState(1)
    const [sustainabilityProjectsCapacity, setSustainabilityProjectsCapacity] = useState(1)
    const [highQualityLevelsCapacity, setHighQualityLevelsCapacity] = useState(1)
    const [innovationAndCreativityCapacity, setInnovationAndCreativityCapacity] = useState(1)
    const [combinationKnowledgeWithOthersCapacity, setCombinationKnowledgeWithOthersCapacity] = useState(1)
    const [useOfExperiencesToImproveCapacity, setUseOfExperiencesToImproveCapacity] = useState(1)
    const [quickReleaseProductsCapacity, setQuickReleaseProductsCapacity] = useState(1)
    const [techProblemsResolutionCapacity, setTechProblemsResolutionCapacity] = useState(1)
    const [fidelityCapacity, setFidelityCapacity] = useState(1)
    const [createReputationCapacity, setCreateReputationCapacity] = useState(1)

    function setValuesOfCapacitiesInfoCompany(capacitiesInfoCompanyData) {
        setAnticipateTechChangesCapacity(capacitiesInfoCompanyData.anticipateTechChangesCapacity)
        setNewBusinessDiversificationCapacity(capacitiesInfoCompanyData.newBusinessDiversificationCapacity)
        setDigitalizationCapacity(capacitiesInfoCompanyData.digitalizationCapacity)
        setSustainabilityProjectsCapacity(capacitiesInfoCompanyData.sustainabilityProjectsCapacity)
        setHighQualityLevelsCapacity(capacitiesInfoCompanyData.highQualityLevelsCapacity)
        setInnovationAndCreativityCapacity(capacitiesInfoCompanyData.innovationAndCreativityCapacity)
        setCombinationKnowledgeWithOthersCapacity(capacitiesInfoCompanyData.combinationKnowledgeWithOthersCapacity)
        setUseOfExperiencesToImproveCapacity(capacitiesInfoCompanyData.useOfExperiencesToImproveCapacity)
        setQuickReleaseProductsCapacity(capacitiesInfoCompanyData.quickReleaseProductsCapacity)
        setTechProblemsResolutionCapacity(capacitiesInfoCompanyData.techProblemsResolutionCapacity)
        setFidelityCapacity(capacitiesInfoCompanyData.fidelityCapacity)
        setCreateReputationCapacity(capacitiesInfoCompanyData.createReputationCapacity)

    }


    useEffect(async () => {
            let responseCapacities = await getCapacitiesInfoCompany();
            setValuesOfCapacitiesInfoCompany(responseCapacities.data);
            setLoading(false);
        }, []
    );


    async function getCapacitiesInfoCompany() {
        const url = "capacitiesinfocompany/" + props.company.id
        return await axios({
            method: 'GET',
            url,
            data: {},
        })

    }

    function editDataAssetsCapacitiesInfo() {
        history.push({
            pathname: '/capacitiesinfo',
            state: {company: props.company}
        })
    }

    return (loading ? <CircularProgress color={"primary"}/> :
        <CapacitiesResumeInfoView
            addAdditionalInfo={editDataAssetsCapacitiesInfo}

            anticipateTechChangesCapacity={anticipateTechChangesCapacity}
            newBusinessDiversificationCapacity={newBusinessDiversificationCapacity}
            digitalizationCapacity={digitalizationCapacity}
            sustainabilityProjectsCapacity={sustainabilityProjectsCapacity}
            highQualityLevelsCapacity={highQualityLevelsCapacity}
            innovationAndCreativityCapacity={innovationAndCreativityCapacity}
            combinationKnowledgeWithOthersCapacity={combinationKnowledgeWithOthersCapacity}
            useOfExperiencesToImproveCapacity={useOfExperiencesToImproveCapacity}
            quickReleaseProductsCapacity={quickReleaseProductsCapacity}
            techProblemsResolutionCapacity={techProblemsResolutionCapacity}
            fidelityCapacity={fidelityCapacity}
            createReputationCapacity={createReputationCapacity}


        />)
}

export default CapacitiesResumeInfo;