import React from "react";
import Grid from "@material-ui/core/Grid";
import {TitleMatchDetails} from "../../../styles/adminArea/matchesDetailsStyles/MatchesDetailsScreenStyles";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Pie,
    PieChart,
    ResponsiveContainer,
    Sector,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    colorBlue,
    colorBlueGreen,
    colorBluePurple,
    colorPrimary,
    colorPrimaryTranslucid,
    colorTernary
} from "../../../EnvironmentVars";
import {DataGridStyled} from "../../../styles/adminArea/companiesDetailsScreen/CompanyDetailsScreenStyles";
import IconButton from "@material-ui/core/IconButton";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import Delete from "@mui/icons-material/Delete";

function RowMenuCell(props) {
    const handleDeleteClick = (event) => {
        event.stopPropagation();
        props.row.deleteCompany(props.row.id);
    };

    return (
        <IconButton
            color="inherit"
            size="small"
            aria-label="borrar"
            onClick={handleDeleteClick}>
                <Delete fontSize="small" />
        </IconButton>
    );
}

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value} = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor}
                  fill={colorPrimary}>{`Entidades ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill={colorPrimary}>
                {`(Porcentaje ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const companiesDataGridcolumns = [
    {
        field: 'name',
        headerName: 'Nombre entidad',
        description: 'Nombre entidad',
        flex: 0.5
    },
    {
        field: 'cif',
        headerName: 'CIF',
        description: 'CIF',
        flex: 0.4
    },
    {
        field: 'applicationUser',
        headerName: 'Nombre contacto',
        description: 'Nombre persona contacto',
        flex: 0.3,
        valueGetter: (params) => {
            return params.value.name + ", " + params.value.lastName
        }
    },
    {
        field: 'phone',
        headerName: 'Teléfono',
        description: 'Numero de teléfono',
        flex: 0.15,
        valueGetter: (params) => {
            return params.row.applicationUser.phone
        }
    },
    {
        field: 'email',
        headerName: 'Correo',
        description: 'Correo',
        flex: 0.3,
        valueGetter: (params) => {
            return params.row.applicationUser.email;
        }
    },
    {
        field: 'numberOfTechLines',
        headerName: 'Líneas',
        description: 'Líneas tecnológicas insertadas por la entidad',
        flex: 0.13,
    },
    {
        field: 'cnae',
        headerName: 'CNAE',
        description: 'Sector industrial',
        flex: 0.15,
    },
    {
        field: 'techPark',
        headerName: 'Parque o polígono',
        description: 'Parque o polígono',
        flex: 0.2,
        valueGetter: (params) => {
            return params.row.techPark !== null && params.row.techPark !== undefined ? params.row.techPark.name : 'N/A';
        }
    },
    {
        field: 'town',
        headerName: 'Población',
        description: 'Población',
        flex: 0.2,
        valueGetter: (params) => {
            return params.row.techPark !== null && params.row.techPark !== undefined ? params.row.techPark.town : 'N/A';
        }
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        description: 'Acciones',
        flex: 0.2,
        renderCell: RowMenuCell
    }

];

const barColors = [colorPrimary, colorTernary, colorBlueGreen, colorBlue, colorBluePurple]

export const CompaniesDetailsScreenView = (props) => {
    return (
        <Grid container spacing={0} justify={"center"} align={"center"}>
            {
                props.numberOfCompaniesByType && props.mostUsedKnowledgeAreas && !props.loading ?
                    <>
                        <Grid item xs={12}>
                            <TitleMatchDetails>Detalles de compañías</TitleMatchDetails>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TitleMatchDetails>Compañias por tipo</TitleMatchDetails>
                            <ResponsiveContainer width="90%" height="80%">
                                <PieChart>
                                    <defs>
                                        <linearGradient
                                            id="colorsPlatform"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="100%"
                                            spreadMethod="reflect"
                                        >
                                            <stop offset="0" stopColor={colorPrimary}/>
                                            <stop offset="1" stopColor={colorTernary}/>
                                        </linearGradient>
                                    </defs>
                                    <Pie
                                        activeIndex={props.activeIndex}
                                        activeShape={renderActiveShape}
                                        data={props.numberOfCompaniesByType}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="url(#colorsPlatform)"
                                        dataKey="value"
                                        onMouseEnter={props.onPieEnter}

                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TitleMatchDetails>Areas de conocimiento más usadas</TitleMatchDetails>
                            <ResponsiveContainer width="80%" height="75%">
                                <BarChart
                                    data={props.mostUsedKnowledgeAreas}
                                >
                                    <XAxis dataKey="name"/>
                                    <YAxis dataKey="value"/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip cursor={{fill: colorPrimaryTranslucid}}/>
                                    <Bar
                                        dataKey="value"
                                        strokeWidth={1}
                                    >
                                        {
                                            props.mostUsedKnowledgeAreas.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={barColors[index % 20]}/>
                                            ))
                                        }
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Grid>
                    </>
                    : <CircularProgress/>
            }
            {props.companies && !props.loading &&
                <>
                    <Grid container spacing={0} justify={"center"} align={"center"}>
                        <Grid item xs={10}></Grid>
                        <Grid item xs={2}>
                            <IconButton variant="contained" onClick={props.handleCSVDownload}>
                                <DownloadForOfflineIcon sx={{color: colorPrimary}}/>&nbsp;CSV
                            </IconButton>
                        </Grid>
                        <div style={{height: "100vh", width: '100%'}}>
                            <DataGridStyled
                                rows={props.companies}
                                columns={companiesDataGridcolumns}
                                pageSize={22}
                                loading={props.loading}
                                color={'linear-gradient(52deg, rgba(138,196,255,1) 0%, rgba(204,252,203,1) 100%)'}
                            />
                        </div>
                    </Grid>
                </>
            }
        </Grid>)
}