import {makeStyles} from "@material-ui/core/styles";
import red from '@material-ui/core/colors/red'
import {teal} from '@material-ui/core/colors'
import {colorPrimary} from "../../../EnvironmentVars";

const capacitiesGeneralFormStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginLeft: '-5%',
        marginBottom: '10px',
    },

    h6Style: {
        fontSize: "1em",
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '0.9em'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '1m'
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: '1.1em'
        }

    },

    warmText: {
        color: red[500],
        fontSize: "1em",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em"
        },
    },

    legendText: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        color: colorPrimary,
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('xs')]: {
            marginLeft: '2px',
            fontSize: '0.8em'
        },
    },

    formControlLabel: {
        fontSize: '1em',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '0.7em'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '0.8m'
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: '1em'
        }
    },

    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    saveButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(19)
    },

    heading: {
        padding: '5px 5px'
    },

    paper: {
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '85%',
        [theme.breakpoints.down('xs')]: {
            width: '85%',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '85%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '85%',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: '85%',
        }
    },

    capacities: {
        padding: '40px 50px'
    },

    hrCapacities: {
        border: "0.05em solid " + teal[400],
        backgroundColor: teal[400],
        borderRadius: "8px",
        margin: '20px 40px'
    },

}));
export default capacitiesGeneralFormStyles;