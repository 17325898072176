import {MenuToggle} from "../../../components/commonComponents/burguerMenu/MenuToggle";
import {
    ContentContainer,
    HamburgerMenuContainer,
    MenuContainer,
    menuTransition,
    menuVariants
} from "../../../styles/commonArea/burguerMenustyles/HamburguerMenuStyles";
import {NavMenu} from "../../../components/commonComponents/burguerMenu/NavMenu";
import React from "react";

export const HamburguerMenuView = (props) => {
    return (
        <HamburgerMenuContainer>
            <MenuToggle toggle={props.toggleMenu} isOpen={props.isOpen}/>
            <MenuContainer
                initial={false}
                animate={props.isOpen ? "open" : "closed"}
                variants={menuVariants}
                transition={menuTransition}
                onClick={props.toggleMenu}
                isOpen={props.isOpen}
            >
                <ContentContainer>
                    <NavMenu toggle={props.toggleMenu} isOpen={props.isOpen}/>
                </ContentContainer>
            </MenuContainer>
        </HamburgerMenuContainer>
    )
}