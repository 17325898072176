import React, {useEffect} from "react";
import assetsGeneralInfoFormStyles from "../../../styles/userArea/assetsFormStyles/AssetsGeneralInfoFormStyles";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {Grid, Paper} from "@material-ui/core";
import aos from "aos";
import "aos/dist/aos.css"

function AssetsGeneralInfoFormView(props) {
    const classes = assetsGeneralInfoFormStyles();

    useEffect(() => {
        aos.init({duration: 1000})
    }, []);

    return (
        <form onSubmit={props.handleSubmit(props.customSubmit)}>
            <div className={classes.paperWrapper} data-aos={"fade-up"}>
                <Paper className={classes.paper} elevation={12}>
                    <br></br>
                    <Grid container
                          spacing={0}
                          align="center"
                          justify="center"
                          direction="column"
                          wrap={"nowrap"}
                    >
                        <Grid item xs={12}>
                            <h3 className={classes.legendText} component="legend">
                                Información general
                            </h3>
                        </Grid>
                        <Grid item xs={12} align="left" className={classes.textgrlInfo}>
                            <div>&nbsp;</div>
                            <h6>
                                Indica el número de unidades disponibles del activo en la entidad
                            </h6>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                inputRef={props.register}
                                required
                                label={"Unidades"}
                                name="unitsNumber"
                                defaultValue={props.unitsNumber}
                            />
                            {
                                props.errors.unitsNumber &&
                                <FormLabel className={classes.warmText}>El campo debe ser numérico</FormLabel>
                            }
                        </Grid>
                        <Grid item xs={12} align="left" className={classes.textgrlInfo}>
                            <div>&nbsp;</div>
                            <h6>Indica, la ubicación física o electrónica del documento original, del contrato o
                                título
                                en el
                                que se describe o reconoce la propiedad, o del lugar donde se guarda una muestra
                                representativa del activo en
                                cuestión</h6>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                inputRef={props.register}
                                name="assetLocation"
                                label={"Ubicación"}
                                defaultValue={props.assetLocation}
                            />
                        </Grid>
                        <hr></hr>
                    </Grid>
                </Paper>
            </div>
            <div>&nbsp;</div>
            <hr className={classes.hrForm}></hr>
            <div>
                <Button variant="contained" type="submit" className={classes.saveButton}>Siguiente</Button>
            </div>
        </form>
    );
}

export default AssetsGeneralInfoFormView;