import React, {useContext, useRef, useState} from "react";
import {MatchesPanelView} from "../../../views/adminArea/matchesPanelViews/MatchesPanelView";
import {MatchesDnDContext} from "../../../context/MatchesDnDContext";

export const MatchesPanel = () => {

    const {matches, columns} = useContext(MatchesDnDContext);
    const {updateMatchColumnState, updateMatchIndexState, updateMatchDataInBack} = useContext(MatchesDnDContext);
    const [loading, setLoading] = useState(true);
    const modalRef = useRef();

    const onDragEndHandler = result => {
        const {destination, source, reason} = result;
        if (reason === "DROP") {
            if (!destination) {
                return;
            }

            if (destination.droppableId === source.droppableId && destination.index === source.index) {
                return;
            }

            const column = columns[destination.droppableId];
            const oldColumn = columns[source.droppableId];
            const matchId = result.draggableId
            if (destination.droppableId !== source.droppableId) {
                updateMatchColumnState(matchId, oldColumn, column, source.index, destination.index);
            } else if (destination.droppableId === source.droppableId && destination.index !== source.index) {
                updateMatchIndexState(matchId, source.index, destination.index, column.columnType);
            }
            updateMatchDataInBack();
        }
    }

    const onAddNewCardsToKanban = () => {
        modalRef.current.open();
    }
    const closeModal = () => {
        modalRef.current.close();
        modalRef.current.destroy()
    }

    return (
        <div>
            <MatchesPanelView
                matches={matches}
                columns={columns}
                onAddNewCardsToKanban={onAddNewCardsToKanban}
                closeModal={closeModal}
                onDragEndHandler={onDragEndHandler}
                loading={loading}
                setLoading={setLoading}
                modalRef={modalRef}
            />
        </div>
    )
}