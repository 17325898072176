import React from "react";
import Grid from "@material-ui/core/Grid";
import {
    InnerTitleMatchDetails,
    SubTitleMatchDetails,
    TitleMatchDetails
} from "../../../styles/adminArea/matchesDetailsStyles/MatchesDetailsScreenStyles";
import {CartesianGrid, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis} from "recharts";
import CircularProgress from "@material-ui/core/CircularProgress";
import {colorBlue, colorPrimary, colorSecondary} from "../../../EnvironmentVars";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {DataGridStyled} from "../../../styles/adminArea/companiesDetailsScreen/CompanyDetailsScreenStyles";
import {statisticsCompaniesDataGridcolumns} from "./StatisticsCompanyDetailsGridColumns";
import {CustomTooltip} from "./CustomToolTipScatterChartView";

export const StatisticsScreenView = (props) => {
    return (
        <Grid container spacing={0} justify={"center"} align={"center"} style={{height: "100vh"}}>
            <Grid item xs={12}>
                <TitleMatchDetails>Estadísticas inteligentes</TitleMatchDetails>
            </Grid>
            <Grid item xs={12}>
                {props.companies && !props.loading &&
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <SubTitleMatchDetails>Paso 1: Selecciona las compañías a consultar</SubTitleMatchDetails>
                        </Grid>
                        <div style={{height: "41vh", width: '100%'}}>
                            <DataGridStyled
                                checkboxSelection
                                rows={props.companies}
                                columns={statisticsCompaniesDataGridcolumns}
                                pageSize={6}
                                loading={props.loading}
                                color={'linear-gradient(52deg, ' + colorBlue + ' 0%, ' + colorSecondary + ' 100%)'}
                                onSelectionModelChange={(e) => {
                                    props.companiesSelected(e.selectionModel)
                                }}
                            />
                        </div>
                    </Grid>
                }
            </Grid>
            {
                !props.loading ?
                    <>
                        <>
                            <Grid item xs={12} style={{height: '20px'}}/>
                            <Grid item xs={12}>
                                <SubTitleMatchDetails>Paso 2: Selecciona los atributos</SubTitleMatchDetails>
                            </Grid>
                            <Grid item xs={11}>
                                <div>
                                    <InnerTitleMatchDetails>Eje x:</InnerTitleMatchDetails>
                                    <Select
                                        labelId="xAxis"
                                        id="xAxis"
                                        value={props.xAxisValue}
                                        onChange={props.getXAxisValuesOnChange}
                                    >
                                        <MenuItem value="collaborationwillingness">Predisposición a colaborar</MenuItem>
                                        {/*<MenuItem value={"peoplehasknowledge"}>Personas con conocimiento</MenuItem>*/}
                                        <MenuItem value={"position"}>Posicionamiento</MenuItem>
                                    </Select>
                                </div>
                                <ResponsiveContainer width="90%" height="90%">
                                    {props.chartHolderDiv ?
                                        <div style={{
                                            margin: '20px',
                                            width: '70vw',
                                            height: '40vh',
                                            background: 'rgba(230,230,230,0.2)'
                                        }}>
                                        </div> :
                                        <ScatterChart
                                            width={400}
                                            height={400}
                                            margin={{
                                                top: 20,
                                                right: 20,
                                                bottom: 20,
                                                left: 20,
                                            }}
                                        >
                                            <CartesianGrid strokeDashArray="3 3"/>
                                            <XAxis type="number" dataKey="x" name={props.xAxisValue} unit=""
                                                   axisLine={false}/>
                                            <YAxis type="number" dataKey="y" name={props.yAxisValue} unit=""
                                                   axisLine={false}/>
                                            <Tooltip
                                                content={<CustomTooltip xAxisName={props.xAxisValue}
                                                                        yAxisName={props.yAxisValue}/>}
                                            />
                                            <Scatter
                                                data={props.chartData}
                                                fill={colorPrimary}
                                                shape="circle"
                                            />
                                        </ScatterChart>
                                    }
                                </ResponsiveContainer>
                            </Grid>
                            <Grid item xs={9}></Grid>
                            <Grid item xs={3}>
                                <div>
                                    <InnerTitleMatchDetails>Eje y:</InnerTitleMatchDetails>
                                    <Select
                                        labelId="yAxis"
                                        id="yAxis"
                                        value={props.yAxisValue}
                                        onChange={props.getYAxisValuesOnChange}
                                    >
                                        <MenuItem value="collaborationwillingness">Predisposición a colaborar</MenuItem>
                                        {/*<MenuItem value={"peoplehasknowledge"}>Personas con conocimiento</MenuItem>*/}
                                        <MenuItem value="position"><em>Posicionamiento</em></MenuItem>
                                    </Select>
                                </div>
                            </Grid>
                        </>
                    </>
                    : <CircularProgress/>
            }
        </Grid>
    )
}