import React, {useState} from 'react';

const Context = React.createContext({});

export function UserContextProvider({children}) {

    const [jwt, setJWT] = useState(localStorage.getItem("token"));
    const [userRoles, setUserRoles] = useState(localStorage.getItem("authorities"));

    return (
        <Context.Provider value={{jwt, setJWT, userRoles, setUserRoles}}>
            {children}
        </Context.Provider>
    )
}

export default Context
