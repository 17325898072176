import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import LoadingComponent from "../../commonComponents/userManagementComponents/LoadingComponent";
import CompanyCardView from "../../../views/userArea/companyCardComponentsViews/CompanyCardView";
import {CompanyContext} from "../../../context/CompanyContext";

export default function CompanyCard(props) {
    const [company, setCompany] = useContext(CompanyContext);
    const [hasCompanyDefined, setHasCompanyDefined] = useState(undefined);
    const [loading, setLooading] = useState(false);

    const getCompanyData = () => {
        const url = "company/"
        axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                setCompany(res.data)
                localStorage.setItem("company", JSON.stringify(res.data))
                setHasCompanyDefined(true);
            }, error => {
                if (error.response.status === 403) {
                    props.history.push("forbidden")
                }
                if (error.response.status === 404) {
                    setHasCompanyDefined(false);
                }
            }
        ).catch((error) => {

        });
    }

    const handleEditButton = () => {
        props.history.push({
            pathname: '/companyform',
            state: {company: company}
        })

    }

    const handleAddCompanyButton = () => {
        props.history.push({
                pathname: "/companyform",
                state: {wantToAddNewCompany: true}
            }
        );
    }

    useEffect(() => {
        setLooading(true);
        getCompanyData();
        setLooading(false);
    }, []);

    return (
        (company === undefined && hasCompanyDefined === undefined) || loading ?
            (<LoadingComponent/>)
            :
            <CompanyCardView company={company}
                             hasCompanyDefined={hasCompanyDefined}
                             handleAddCompanyButton={handleAddCompanyButton}
                             handleEditButton={handleEditButton}
            />

    )
}