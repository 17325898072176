import React, {useEffect, useState} from "react";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import AdditionalInfoForPartnershipFormView
    from "../../../views/userArea/companyCardComponentsViews/AdditionalInfoForPartnershipFormView";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router-dom";

function AdditionalInfoForPartnershipForm(props) {
    const history = useHistory();
    const [partnershipPreferencesItems, setPartnershipPreferencesItems] = useState();
    const [loading, setLoading] = useState(true);
    const [additionalInfoLength, setAdditionalInfoLength] = useState(0);
    const [partnershipPreferencesValues, setPartnershipPreferencesValues] = useState();

    let validationSchema = yup.object().shape(
        {
            additionalInfo: yup.string().max(4000, "El texto no puede contener más de 4000 caracteres.")
        }
    );

    const {register, handleSubmit, errors, control} = useForm(
        {
            resolver: yupResolver(validationSchema),
        }
    );

    function isPartnershipValuesAreNotStored(partnershipPreferencesValues) {
        return partnershipPreferencesValues === undefined || partnershipPreferencesValues === null;
    }

    async function getPreferencesValuesFromServer() {
        let companyId = props.location.state.companyId ? props.location.state.companyId :
            JSON.parse(localStorage.getItem("company")).id;
        let data = {};
        const url = "partnershippreferencescompany/" + companyId
        return axios({
            method: 'GET',
            url,
            data: data,
        });
    }

    useEffect(async () => {
        setLoading(true);
        let preferencesItemsResponse = await getPartnershipPreferences();
        setPartnershipPreferencesItems(preferencesItemsResponse.data);
        let passedPreferencesfromSuperiorComponent = props.location.state.partnershipPreferences;
        let retrievedFromBrowSerStoragePreferences = JSON.parse(localStorage.getItem("partnershipPreferences"));
        let partnershipPreferencesValues = passedPreferencesfromSuperiorComponent !== undefined ? passedPreferencesfromSuperiorComponent : retrievedFromBrowSerStoragePreferences;
        if (isPartnershipValuesAreNotStored(partnershipPreferencesValues)) {
            partnershipPreferencesValues = await getPreferencesValuesFromServer();
            setPartnershipPreferencesValues(partnershipPreferencesValues.data);
        } else {
            setPartnershipPreferencesValues(partnershipPreferencesValues)
        }
        setLoading(false);
    }, []);

    async function getPartnershipPreferences() {
        let data = {};
        const url = "partnershippreferences"
        return axios({
            method: 'GET',
            url,
            data: data,
        });
    }

    function customChangeInfo(event) {
        let caracterLength = event.target.value.length
        setAdditionalInfoLength(caracterLength)
    }

    async function customSubmit(data) {
        props.location.state.companyId === undefined ?
            data.companyId = JSON.parse(localStorage.getItem("company")).id
            : data.companyId = undefined;
        if (data.companyId !== undefined && !partnershipPreferencesValues) {
            await axios.post("/partnershippreferencescompany", data)
                .then(
                    res => {
                        history.push('company')
                    },
                    error => {

                    }
                ).catch(
                    error => {
                        console.error(error)
                    }
                )

        } else if (partnershipPreferencesValues) {
            data.id = partnershipPreferencesValues.id;
            await axios.put("/partnershippreferencescompany", data)
                .then(
                    res => {
                        history.push('company')
                    },
                    error => {

                    }
                ).catch(
                    error => {
                        console.error(error)
                    }
                )
        }
    }

    return (
        <ThemeProvider theme={theme}>
            {loading ? <CircularProgress color={"primary"}/> :
                <AdditionalInfoForPartnershipFormView
                    partnershipPreferencesItems={partnershipPreferencesItems}
                    partnershipPreferencesValues={partnershipPreferencesValues}
                    loading={loading}
                    company={props.company}
                    handleSubmit={handleSubmit}
                    customSubmit={customSubmit}
                    register={register}
                    control={control}
                    errors={errors}
                    additionalInfoLength={additionalInfoLength}
                    customChangeInfo={customChangeInfo}
                    // partnershipPreferencesValues={partnershipPreferencesValues}
                />}
        </ThemeProvider>
    );
}

export default AdditionalInfoForPartnershipForm