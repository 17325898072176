import React from "react";
import ResetPasswordChangePasswordStyles
    from "../../../styles/commonArea/userManagementStyles/ResetPasswordChangePasswordStyles";
import {AnimatePresence} from "framer-motion";
import {Grid, Typography} from "@material-ui/core";
import {FormContainer, Input, SubmitButton} from "../../../styles/commonArea/accountAccessStyles/CommonStyles";
import {
    BackDrop,
    BoxContainer,
    HeaderContainer,
    HeaderText,
    InnerContainer,
    SmallText,
    TopContainer
} from "../../../styles/commonArea/accountAccessStyles/AccountBoxStyles";

function ResetPasswordSendEmailView(props) {
    const classes = ResetPasswordChangePasswordStyles();
    return (
        <Grid container spacing={0} align={"center"} justify={"center"}>
            <Grid item xs={12}>
                <BoxContainer
                    initial={{scale: 0.7, opacity: 0.5}}
                    animate={{scale: 1, opacity: 1}}
                    transition={{
                        type: "spring",
                        duration: 2
                    }}
                >
                    <TopContainer>
                        <BackDrop
                        />
                        <AnimatePresence exitBeforeEnter>
                            <HeaderContainer whileHover={{scale: 1.2}} initial={{opacity: 0}} animate={{opacity: 1}}
                                             exit={{opacity: 0}} transition={{duration: 0.3}} key={"singin"}>
                                <HeaderText>Restablece tu</HeaderText>
                                <HeaderText>contraseña</HeaderText>
                                <SmallText></SmallText>
                            </HeaderContainer>
                        </AnimatePresence>
                    </TopContainer>
                    <InnerContainer>
                        <FormContainer onSubmit={props.handleSubmit(props.customSubmit)}>
                            <Input ref={props.register} name="password" type="password" placeholder="Contraseña"
                                   autoComplete="off"/>
                            <Input ref={props.register} name="confirmPass" type={"password"}
                                   placeholder="Confirma contraseña"/>
                            <div>&nbsp;&nbsp;</div>
                            <Typography component={'div'} className={classes.warmText}>
                                {props.errors.password?.message}
                                {props.errors.confirmPass?.message}
                                {props.loginErrorMessage}
                            </Typography>
                            <div>&nbsp;&nbsp;</div>
                            <SubmitButton type="submit">Restablece</SubmitButton>
                        </FormContainer>
                    </InnerContainer>
                </BoxContainer>
            </Grid>
        </Grid>
    )
}

export default ResetPasswordSendEmailView;