import React, {useEffect} from "react";
import assetsFormStyles from "../../../styles/userArea/assetsFormStyles/AssetsFormStyles";
import {Controller} from "react-hook-form";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import aos from "aos";
import "aos/dist/aos.css"

function AssetsFormView(props) {
    const classes = assetsFormStyles();

    useEffect(() => {
        aos.init({duration: 1000})
    }, []);

    return (
        <form component="fieldset" onSubmit={props.handleSubmit(props.customSubmit)}>
            <Paper className={classes.paper} elevation={12} style={{overflowX: "hidden"}} data-aos={"zoom-out"}>
                <div className={classes.assets}>
                    <h3 className={classes.legendText} component="legend">Activos</h3>
                    <FormControlLabel
                        control={
                            <Checkbox
                                inputRef={props.register}
                                name="assetApplicant"
                                // control={props.control}
                                color="primary"
                                onChange={(e) => props.handleCheckAssetApplicant(e)}
                                icon={<RadioButtonUncheckedOutlinedIcon/>}
                                checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                                checked={props.assetApplicant}
                            />
                        }
                        name="assetApplicant"
                        label="No dispongo de este activo pero me gustaría incorporarlo"
                    />
                    <hr className={classes.hrAsset}></hr>
                    <div>&nbsp;&nbsp;</div>
                    <Controller
                        name="selected"
                        defaultValue={props.selectedItem}
                        data-aos={"fade-right"}
                        as={
                            <RadioGroup aria-label="selected"
                                        aria-required={true} required
                                        value={props.value}
                                        onChange={props.handleChange}
                            >
                                {
                                    props.assetsTypes.map(
                                        assetType =>
                                            <Accordion key={assetType.id}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography component={'div'}
                                                                className={classes.heading}>{assetType.name}
                                                    </Typography>
                                                </AccordionSummary>
                                                <Grid container direction="row">
                                                    <AccordionDetails>
                                                        <Grid item xs={1}></Grid>
                                                        <Grid container direction="column" item xs={12}>
                                                            {
                                                                assetType.assets.map(
                                                                    asset =>
                                                                        <FormControlLabel
                                                                            key={asset.id}
                                                                            defaultValue={props.selectedItem}
                                                                            control=
                                                                                {
                                                                                    <Radio
                                                                                        inputRef={props.register}
                                                                                        name={asset.id.toString()}
                                                                                        value={asset.name}
                                                                                        onChange={(event) => props.handleChangeItemId(asset.id)}
                                                                                        color="primary"

                                                                                    />
                                                                                }
                                                                            label={asset.name}
                                                                        />
                                                                )
                                                            }
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Grid>
                                            </Accordion>
                                    )
                                }
                            </RadioGroup>
                        }
                        control={props.control}>
                    </Controller>
                    {
                        props.errors.selected &&
                        <FormLabel className={classes.warmText}
                                   color='secondary'>
                            Debes seleccionar un activo
                        </FormLabel>
                    }
                </div>
            </Paper>
            <hr className={classes.hrAsset}></hr>
            <div>
                <Button variant="contained" type="submit" className={classes.saveButton}>Siguiente</Button>
            </div>
        </form>
    );
}

export default AssetsFormView;