import React from "react";
import Button from "@material-ui/core/Button";
import AdditionalInfoForPartnershipStyles
    from "../../../../styles/userArea/companyCardComponentsStyles/AdditionalInfoForPartnershipStyles";
import {CircularProgress, Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CapacitiesInfoCompanyValoration from "./CapacitiesInfoCompanyValoration";


function CapacitiesInfoFormView(props) {
    const classes = AdditionalInfoForPartnershipStyles();

    return (
        <Paper className={classes.paper} elevation={12}>
            <div>&nbsp;</div>
            {props.loading ?
                <Grid container spacing={0} justify={"center"} align={"center"} alignItems={"center"}
                      style={{minHeight: "50vh"}}>
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                </Grid> :
                <form onSubmit={props.handleSubmit(props.customSubmit)}>
                    <div>&nbsp;&nbsp;</div>
                    <Grid container spacing={10} justify={"center"} align={"center"}>
                        <Grid item xs={12}>
                            <h3>Capacidades</h3>
                        </Grid>
                        <CapacitiesInfoCompanyValoration props={props} classes={classes}/>
                        <Grid item xs={12}>
                            <div>&nbsp;&nbsp;</div>
                            <Button variant="contained" type="submit" className={classes.loginButton}>
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            }
        </Paper>
    )
}

export default CapacitiesInfoFormView;