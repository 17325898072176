import React, {Component} from "react";
import ForbiddenView from "../../../views/commonArea/userManagementViews/ForbiddenView";

class Forbidden extends Component {

    handleRedirectLoginButton = () => {
        this.props.history.push("login")
    }

    handleRedirectSignUpButton = () => {
        this.props.history.push("signup")
    }

    render() {
        return (<ForbiddenView
                handleRedirectLoginButton={this.handleRedirectLoginButton}
                handleRedirectSignUpButton={this.handleRedirectSignUpButton}
            />
        )
    }
}

export default Forbidden;