import React from "react";
import CompanyTypeRadioButtonsView from "./CompanyTypeRadioButtonsView";

export default function CompanyTypeRadioButtons(props) {

    return (
        <CompanyTypeRadioButtonsView
            register={props.register}
            control={props.control}
            defaultValue={props.defaultValue}
            handleChangeRadioButton={props.handleChangeRadioButton}
            radioGroupName={props.radioGroupName}
        />

    )
}
