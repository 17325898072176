import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {TitleMatchDetails} from "../../styles/adminArea/matchesDetailsStyles/MatchesDetailsScreenStyles";
import SearchInputOnBack from "../../components/commonComponents/utilComponents/SearchInputOnBack";
import "aos/dist/aos.css";
import aos from "aos";
import {DataGridStyled} from "../../styles/adminArea/companiesDetailsScreen/CompanyDetailsScreenStyles";
import {CircularProgress} from "@material-ui/core";

const informationSourceHeaders = [
    {
        field: 'id',
        headerName: 'ID',
        description: 'id',
        flex: 0.3
    },
    {
        field: 'companyName',
        headerName: 'Nombre entidad',
        description: 'Nombre entidad',
        flex: 1,
    },
    {
        field: 'cif',
        headerName: 'CIF',
        description: 'CIF',
        flex: 0.5
    },
    {
        field: 'title',
        headerName: 'Título',
        description: 'Título de patente o Marca',
        flex: 2,
    },
    {
        field: 'publicationNumber',
        headerName: 'Número de publicación',
        description: 'Número de publicación',
        flex: 0.5,
    },
    {
        field: 'category',
        headerName: 'Categoría',
        description: 'Marca/patente/etc....',
        flex: 0.4,
    }
];

export const InformationSourcesScreenView = (props) => {
    useEffect(() => {
        aos.init({duration: 1000})
    }, [])

    return (<>
            <Grid container spacing={0} justify={"center"} align={"center"}>
                <Grid item xs={12}>
                    <TitleMatchDetails>Patentes y marcas</TitleMatchDetails>
                </Grid>
                <Grid item xs={12} data-aos="zoom-in">
                    <SearchInputOnBack
                        getDataFromBack={props.getDataFromBack}
                        textToSearch={props.textToSearch}
                        setTextToSearch={props.setTextToSearch}
                    />
                </Grid>
                {props.data === undefined ?
                    <div style={{
                        margin: '20px',
                        width: '70vw',
                        height: '100vh',
                    }}>
                    </div>
                    : props.loading ? <CircularProgress></CircularProgress> :
                        <>
                            <Grid container spacing={0} justify={"center"} align={"center"}>
                                <div style={{height: "100vh", width: '100%'}}>
                                    <DataGridStyled
                                        rows={props.data}
                                        columns={informationSourceHeaders}
                                        pageSize={30}
                                        loading={props.loading}
                                        color={'linear-gradient(52deg, rgba(138,196,255,1) 0%, rgba(204,252,203,1) 100%)'}
                                    />
                                </div>
                            </Grid>
                        </>
                }
            </Grid>
        </>
    )
}