import {makeStyles} from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

const additionalInfoForPartnershipStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        flexGrow: 1,
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },

    warmText: {
        color: red[500],
        fontSize: "0.8em",
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.8em"
        },
    },

    paper: {
        display: 'flex',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: "center",
        alignItems: "center",
        width: '70%',
        height: 'auto',
        padding: '5px 50px'
    },

    textAreaAutosize: {
        width: '85%',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: 'auto'
    },

    AdditionalInfoChar: {
        display: 'flex',
        justifyContent: 'center',
        padding: '5px 2px'
    },

    progressBar: {
        width: '85%',
        padding: '1px 1px'
    }

}));
export default additionalInfoForPartnershipStyles;