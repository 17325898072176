import React, {useContext} from "react";
import {ColumnView} from "../../../views/adminArea/matchesPanelViews/ColumnView";
import {MatchesDnDContext} from "../../../context/MatchesDnDContext";

export const Column = (props) => {

    const {matches} = useContext(MatchesDnDContext);

    return (
        <ColumnView columnId={props.columnId}
                    matches={matches}
                    onAddNewCardsToKanban={props.onAddNewCardsToKanban}
                    {...props}>
        </ColumnView>
    )
}