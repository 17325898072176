import React, {useEffect} from "react";
import Button from '@material-ui/core/Button';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import stepperFormStyles from "../../../styles/userArea/stepperStyles/StepperFormStyles";
import {Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import "aos/dist/aos.css"
import aos from "aos";

function StepperFormView(props) {

    const classes = stepperFormStyles();

    useEffect(() => {
        aos.init({duration: 2000})
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={classes.root} style={{overflowX: "hidden"}}>
            <Stepper activeStep={props.activeStep} alternativeLabel>
                {props.steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {props.activeStep === props.steps.length ? (
                    <div>
                        <Paper className={classes.paper} elevation={12} data-aos={"zoom-out"}>
                            <Grid container
                                  spacing={0}
                                  align="center"
                                  justify="center"
                                  direction="column"
                                  wrap={"nowrap"}
                            >
                                <Grid item xs={12}>
                                    <h3 className={classes.formCompletedText}>Formulario completado.
                                    </h3>
                                </Grid>
                                <Grid item xs={12}>
                                    {props.loading ? <CircularProgress color={"primary"}/> :
                                        <Button className={classes.endButton} variant="contained" color="primary"
                                                onClick={props.handleReset}>
                                            Finalizar y guardar
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                        <br></br>
                        <div>
                            <hr className={classes.hrForm}></hr>
                        </div>
                        <div className={classes.emptySpace}>&nbsp;</div>
                        <Button className={classes.backButton}
                                onClick={(event) => props.handleBack(props.activeStep)}
                        >
                            Atrás
                        </Button>
                    </div>
                ) : (
                    <div className={classes.instructions}>
                        <Typography component={'div'}
                                    className={classes.instructions}>{props.getStepContent(props.activeStep)}</Typography>
                        <Button className={classes.backButton}
                                onClick={(event) => props.handleBack(props.activeStep)}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
            </div>

        </div>
    );
}

export default StepperFormView;