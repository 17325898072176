import React, {useContext, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Column} from "../../../components/adminArea/matchesPanelComponents/Column";
import {DragDropContext} from "react-beautiful-dnd";
import {MatchesDnDContext} from "../../../context/MatchesDnDContext";
import {ModalAddMatchesToKanban} from "../../../components/adminArea/matchesPanelComponents/ModalAddMatchesToKanban";

export const MatchesPanelView = (props) => {

    return (
        <DragDropContext
            onDragEnd={props.onDragEndHandler}
        >
            <Grid container spacing={0} justify={"center"} align={"center"}>
                {props.columns.columnOrder.map((columnId) => {
                    return (
                        <Grid item xs={12} md={6} lg={3} key={columnId}>
                            <Column
                                columnId={columnId}
                                modalRef={props.modalRef}
                                onAddNewCardsToKanban={props.onAddNewCardsToKanban}
                                {...props}
                            >
                            </Column>
                            <Grid item xs={12}>
                                {
                                    <ModalAddMatchesToKanban
                                        modalRef={props.modalRef}
                                        columnId={columnId}
                                        columns={props.columns}
                                        loading={props.loading}
                                        setLoading={props.setLoading}
                                        closeModal={props.closeModal}
                                        openModal={props.onAddNewCardsToKanban}
                                    />
                                }
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </DragDropContext>
    )
}