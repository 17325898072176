import React, {useEffect, useState} from "react";
import AdminHomeCard from "../../../components/adminArea/AdminHome/AdminHomeCard";
import adminHomeStyles from "../../../styles/adminArea/AdminHomeStyles/AdminHomeStyles";
import {Grid} from "@material-ui/core";
import aos from "aos";
import "aos/dist/aos.css"
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {colorBlueGreen, colorPrimary} from "../../../EnvironmentVars";

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration:none;
  }
`;

function AdminHomeView() {

    const classes = adminHomeStyles();
    const [numberCompanies, setNumberCompanies] = useState(52);
    const [matchPossible, setMatchPossible] = useState(23);
    const [activeMatches, setActiveMatches] = useState(12);
    const [completedMatches, setCompletedMatches] = useState(11);
    const [techLinesExistent, setTechlinesExistent] = useState(127);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        aos.init({duration: 2000})
        const url = "admin/home"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                setNumberCompanies(res.data.numberCompanies);
                setMatchPossible(res.data.matchPossible);
                setActiveMatches(res.data.activeMatches);
                setCompletedMatches(res.data.completedMatches);
                setTechlinesExistent(res.data.techLinesExistent);
                setLoading(false);
            }, error => {
                if (error.response.status === 403) {
                }
                if (error.response.status === 404) {
                }
            }
        ).catch((error) => {

        });
    }, []);

    return (loading ?
            <Grid container spacing={0} justify={"center"} align={"center"}>
                <Grid item xs={12}>
                    <CircularProgress/>
                </Grid>
            </Grid> :
            <Grid container
                  className={classes.grid}
                  spacing={0}
                  align="center"
                  justify="center"
                  style={{overflowX: "hidden"}}
            >
                <Grid item lg={4} md={6} xs={12}
                      data-aos={"fade-right"}
                >
                    <StyledLink to={"/companiesdetailsscreen"}>
                        <AdminHomeCard
                            title={"Compañías registradas"}
                            number={numberCompanies}
                            color={'linear-gradient(52deg, rgba(138,196,255,1) 0%, rgba(204,252,203,1) 100%)'}
                        />
                    </StyledLink>
                </Grid>
                <Grid item lg={4} md={6} xs={12} data-aos="zoom-out">
                    <StyledLink to={"/matchesdetailsscreen"}>
                        <AdminHomeCard
                            title={"Match posibles"}
                            number={matchPossible}
                            color={'linear-gradient(52deg, rgba(138,196,255,1) 0%, rgba(152,95,153,1) 100%)'}
                        />
                    </StyledLink>
                </Grid>
                <Grid item lg={4} md={6} xs={12} data-aos="zoom-out">
                    <StyledLink to={"/matchescompleteddetailsscreen"}>
                        <AdminHomeCard
                            title={"Match completados"}
                            number={completedMatches}
                            color={'linear-gradient(52deg, rgba(241,124,73,1) 0%, rgba(204,252,203,1) 100%)'}
                        />
                    </StyledLink>
                </Grid>
                <Grid item lg={6} md={6} xs={12} data-aos="zoom-out">
                    <StyledLink to={"/techlinesdetailsscreen"}>
                        <AdminHomeCard
                            title={"Líneas introducidas"}
                            number={techLinesExistent}
                            color={'linear-gradient(52deg, ' + colorPrimary + ' 0%, ' + colorBlueGreen + '  100%)'}
                        />
                    </StyledLink>
                </Grid>
                <Grid item lg={6} md={6} xs={12} data-aos={"fade-left"}>
                    <StyledLink to={"/matchespanel"}>
                        <AdminHomeCard
                            title={"Match activos"}
                            number={activeMatches}
                            color={'linear-gradient(52deg, rgba(118,181,157,1) 0%, rgba(204,252,203,1) 100%)'}
                        />
                    </StyledLink>
                </Grid>
            </Grid>
    )
}

export default AdminHomeView;