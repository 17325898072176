import React, {useEffect} from "react";
import HomeCard from "./HomeCard";
import homeStyles from "../../../styles/commonArea/homeStyles/HomeStyles";
import bluePurpleLogo from "../../../resources/abstract-homepage-logos/blue-purple.png";
import greenLightGreenLogo from "../../../resources/abstract-homepage-logos/green-light-green.png";
import yellowGreenLogo from "../../../resources/abstract-homepage-logos/yellow-green.png";
import yellowOrangeLogo from "../../../resources/abstract-homepage-logos/yellow-orange.png"
import videoPeriod from "../../../resources/home-period.mp4";
import {Grid} from "@material-ui/core";
import aos from "aos"
import "aos/dist/aos.css"

function Home() {
    const classes = homeStyles();

    useEffect(() => {
        aos.init({duration: 2000})
    }, []);

    return (
        <Grid container
              className={classes.grid}
              spacing={5}
              style={{overflowX: "hidden", marginBottom:'50px'}}>

            <Grid item xs={12}>
                <Grid container
                    align={"center"}
                >
                    <Grid item xs={12}>
                        <video autoPlay muted>
                            <source src={videoPeriod} type={'video/mp4'}/>
                        </video>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={3} md={6} xs={12}
                  data-aos={"fade-right"}
            >
                <HomeCard image={bluePurpleLogo}
                          title={"Privacidad"}
                          text={"Muestra tus capacidades tecnológicas solo a colaboradores potenciales."}

                />
            </Grid>
            <Grid item lg={3} md={6} xs={12} data-aos="zoom-out">
                <HomeCard image={greenLightGreenLogo}
                          title={"Conocimiento"}
                          text={"Fortalece la transferencia de conocimiento cientifico, tecnológico y económico."}
                />
            </Grid>
            <Grid item lg={3} md={6} xs={12} data-aos="zoom-out">
                <HomeCard image={yellowGreenLogo}
                          title={"Colaboración"}
                          text={"Ayuda a generar acuerdos de colaboración entre los agentes."}
                />
            </Grid>
            <Grid item lg={3} md={6} xs={12} data-aos={"fade-left"}>
                <HomeCard image={yellowOrangeLogo}
                          title={"Capacidades"}
                          text={"Facilita el poder aprovechar las capacidades de las empresas \"tractoras\", que son las líderes en innovación."}
                />
            </Grid>

        </Grid>

    )
}

export default Home;