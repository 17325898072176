import {Grid, Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import React from "react";

export default function AssetsTotalInfo(props, classes) {
    props = props.props;
    return <Grid container spacing={2} align={"left"} alignItems={"center"}>
        <Grid item xs={12} align={"center"} style={{margin: "30px"}}>
            <h6 component={'div'}>
                Indique el número de activos de cada clase que la empresa dispone actualmente:
            </h6>
        </Grid>
        <Grid item xs={6} md={6} align={"right"}>
            <Typography component={'div'}>
                Patentes y modelos de utilidad
            </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
            <TextField
                variant="outlined"
                type={"number"}
                inputRef={props.register}
                name="patents"
                defaultValue={props.patents}
            />
            {
                props.errors.patents &&
                <FormLabel className={classes.warmText}>El campo debe ser
                    numérico</FormLabel>
            }
        </Grid>


        <Grid item xs={6} md={6} align={"right"}>
            <Typography component={'div'}>
                Programas de software de desarrollo propio
            </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
            <TextField
                variant="outlined"
                type={"number"}
                inputRef={props.register}
                name="software"
                defaultValue={props.software}
            />
            {
                <p>{props.errors.software?.message}</p>
            }
        </Grid>


        <Grid item xs={6} md={6} align={"right"}>
            <Typography component={'div'}>
                Bases de datos de creación propia
            </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
            <TextField
                variant="outlined"
                type={"number"}
                inputRef={props.register}
                name="dataBases"
                defaultValue={props.dataBases}
            />
            {
                props.errors.dataBases &&
                <FormLabel className={classes.warmText}>El campo debe ser
                    numérico</FormLabel>
            }
        </Grid>

        <Grid item xs={6} md={6} align={"right"}>
            <Typography component={'div'}>
                Marcas (denominativas, gráficas, logotipos, tridimensionales, musicales)
            </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
            <TextField
                variant="outlined"
                type={"number"}
                inputRef={props.register}
                name="tradeMark"
                defaultValue={props.tradeMark}
            />
            {
                props.errors.tradeMark &&
                <FormLabel className={classes.warmText}>El campo debe ser
                    numérico</FormLabel>
            }
        </Grid>


        <Grid item xs={6} md={6} align={"right"}>
            <Typography component={'div'}>
                Certificaciones
            </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
            <TextField
                variant="outlined"
                type={"number"}
                inputRef={props.register}
                name="certifications"
                defaultValue={props.certifications}
            />
            {
                props.errors.certifications &&
                <FormLabel className={classes.warmText}>El campo debe ser
                    numérico</FormLabel>
            }
        </Grid>

    </Grid>;
}