import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import navBarStyles from "../../styles/commonArea/NavBarStyles";
import {Grid} from "@material-ui/core";
import {HamburgerMenu} from "../../components/commonComponents/burguerMenu/HamburguerMenu";
import appLogo from "../../resources/logo-header.png";

function NavBarView(props) {
    const classes = navBarStyles();
    return (
        <Grid container
              direction="row"
              className={classes.grid}
        >
            <AppBar position="static" className={classes.navBar}>
                <Toolbar>
                    <Grid item xs={4}>
                        <Link to={"/"}>
                            <img src={appLogo} style={{ height: 50 }}></img>
                        </Link>
                    </Grid>
                    <Grid item xs={4} style={{textAlign: "center"}}>
                        <Link className={classes.titleLink} to={"/"}>
                            <Typography variant="h6" className={classes.title}>
                                MAPITEC
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={4} style={{display: "grid", justifyContent: "right"}}>
                        <HamburgerMenu/>
                    </Grid>
                </Toolbar>
                <span className={classes.separator}>&nbsp;&nbsp;</span>
            </AppBar>
        </Grid>
    )
}

export default NavBarView;