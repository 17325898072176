import {makeStyles} from "@material-ui/core/styles";
import {colorPrimary, colorSecondary} from "../../../EnvironmentVars";

const companyCardStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0, 3),

    },
    companyCardWrapper: {
        boxShadow: "-9px 9px 18px #1d6465,9px -9px 18px #237879"
    },

    addCompanyButtonSection: {
        height: "52vh",
        overflowX: "hidden"
    },

    cardContent: {
        background: 'linear-gradient(45deg, ' + colorPrimary + ', ' + colorSecondary + ' 130%)',
        borderRadius: "10px",
        minWidth: "350px",
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.between('xs', 'sm')]: {
            borderRadius: "10px",
            minWidth: "230px"
        },
        [theme.breakpoints.between('sm', 'md')]: {
            borderRadius: "10px",
            minWidth: "230px"
        },
        [theme.breakpoints.between('md', 'lg')]: {
            borderRadius: "10px",
            minWidth: "230px"
        },
    },

    CardCompanyContent: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },

    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "300px",
    },

    buttonAddCompany: {
        width: 'calc(10% + 100px)',
        height: "60px",
        background: 'linear-gradient(45deg, ' + colorPrimary + ', ' + colorSecondary + ' 100%)',//colorPrimary,
    },

    editButtonWrapper: {
        display: "flex",
        justifyContent: "right",
        alignItems: "right",
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: '2px',
            right: '10',
            width: '100px'
        },
    },

    editButton: {
        text: "black"
    }

}));
export default companyCardStyles;