import {
    NavLink,
    NavList,
    NavMenuContainer,
    NavTitle,
    variants
} from "../../../styles/commonArea/burguerMenustyles/NavMenuStyles";
import {Link} from "react-router-dom";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import React from "react";
import {colorPrimary} from "../../../EnvironmentVars";
import ViewWeekTwoToneIcon from '@material-ui/icons/ViewWeekTwoTone';
import MergeTypeOutlinedIcon from '@material-ui/icons/MergeTypeOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import {ClearAllRounded, DoneOutlineRounded} from "@material-ui/icons";
import EqualizerRoundedIcon from '@material-ui/icons/EqualizerRounded';
import DataObjectIcon from '@mui/icons-material/DataObject';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';

export const NavMenuView = (props) => {
    return (
        <NavMenuContainer>
            <NavList>
                {!props.jwt ?
                    <>
                        <NavLink
                            initial={false}
                            animate={props.isOpen ? "show" : "hide"}
                            variants={{
                                show: {
                                    ...variants.show,
                                    transition: {delay: 0.1, duration: 0.8},
                                },
                                hide: {
                                    ...variants.hide,
                                    transition: {delay: 0, duration: 0.5},
                                },
                            }}
                            whileHover={{
                                scale: 1.3,
                                originX: 0
                            }}
                            transition={{type: "spring", stiffness: 300}}
                        >
                            <Link className={"link"} to={"/login"}>
                                <AccountCircleOutlinedIcon/>
                                <>&nbsp;&nbsp;</>
                                Accede
                            </Link>
                        </NavLink>
                    </>
                    :
                    <>
                        {
                            !props.userRoles.includes("ADMIN") && <NavTitle>
                                Gestión de entidad
                            </NavTitle>
                        }
                        <NavLink
                            initial={false}
                            animate={props.isOpen ? "show" : "hide"}
                            variants={{
                                show: {
                                    ...variants.show,
                                    transition: {delay: 0.2, duration: 0.8},
                                },
                                hide: {
                                    ...variants.hide,
                                    transition: {delay: 0.05, duration: 0.5},
                                },
                            }}
                            whileHover={{
                                scale: 1.3,
                                originX: 0,
                                color: colorPrimary
                            }}
                            transition={{
                                type: "spring",
                                stiffness: 300
                            }}
                        >

                            <Link className={"link"}
                                  to={props.userRoles.includes("ADMIN") ? "/admin" : "/company"}
                            >
                                <AccountBoxOutlinedIcon/>
                                <>&nbsp;&nbsp;</>
                                {props.userRoles.includes("ADMIN") ? "Home" : "Perfil"}
                            </Link>
                        </NavLink>
                        {props.userRoles.includes("ADMIN") ?
                            <NavLink
                                initial={false}
                                animate={props.isOpen ? "show" : "hide"}
                                variants={{
                                    show: {
                                        ...variants.show,
                                        transition: {delay: 0.3, duration: 0.8},
                                    },
                                    hide: {
                                        ...variants.hide,
                                        transition: {delay: 0.05, duration: 0.5},
                                    },
                                }}
                                whileHover={{
                                    scale: 1.3,
                                    originX: 0,
                                    color: colorPrimary
                                }}
                                transition={{
                                    type: "spring",
                                    stiffness: 300
                                }}
                            >

                                <Link className={"link"}
                                      to={"/matchespanel"}
                                >
                                    <ViewWeekTwoToneIcon/>
                                    <>&nbsp;&nbsp;</>
                                    Panel de matches
                                </Link>
                            </NavLink> : <></>
                        }
                        {
                            props.userRoles.includes("ADMIN") && <NavTitle
                                animate={props.isOpen ? "show" : "hide"}
                                variants={{
                                    show: {
                                        ...variants.show,
                                        transition: {delay: 0.4, duration: 0.8},
                                    },
                                    hide: {
                                        ...variants.hide,
                                        transition: {delay: 0.1, duration: 1},
                                    },
                                }}
                            >
                                Tu área empresarial
                            </NavTitle>
                        }
                        {props.userRoles.includes("ADMIN") ?
                            <>
                                <NavLink
                                    initial={false}
                                    animate={props.isOpen ? "show" : "hide"}
                                    variants={{
                                        show: {
                                            ...variants.show,
                                            transition: {delay: 0.3, duration: 0.8},
                                        },
                                        hide: {
                                            ...variants.hide,
                                            transition: {delay: 0.1, duration: 1},
                                        },
                                    }}
                                    whileHover={{
                                        scale: 1.3,
                                        originX: 0
                                    }}
                                    transition={{type: "spring", stiffness: 300}}
                                >
                                    <Link className={"link"} to={"/companiesdetailsscreen"}>
                                        <BusinessOutlinedIcon/>
                                        <>&nbsp;&nbsp;</>
                                        Compañias
                                    </Link>
                                </NavLink>
                                <NavLink
                                    initial={false}
                                    animate={props.isOpen ? "show" : "hide"}
                                    variants={{
                                        show: {
                                            ...variants.show,
                                            transition: {delay: 0.4, duration: 0.8},
                                        },
                                        hide: {
                                            ...variants.hide,
                                            transition: {delay: 0.1, duration: 1},
                                        },
                                    }}
                                    whileHover={{
                                        scale: 1.3,
                                        originX: 0
                                    }}
                                    transition={{type: "spring", stiffness: 300}}
                                >
                                    <Link className={"link"} to={"/matchesdetailsscreen"}>
                                        <MergeTypeOutlinedIcon/>
                                        <>&nbsp;&nbsp;</>
                                        Matches
                                    </Link>
                                </NavLink>
                            </>
                            : <></>
                        }
                        {props.userRoles.includes("ADMIN") ?
                            <>

                                <NavLink
                                    initial={false}
                                    animate={props.isOpen ? "show" : "hide"}
                                    variants={{
                                        show: {
                                            ...variants.show,
                                            transition: {delay: 0.5, duration: 0.8},
                                        },
                                        hide: {
                                            ...variants.hide,
                                            transition: {delay: 0.1, duration: 1},
                                        },
                                    }}
                                    whileHover={{
                                        scale: 1.3,
                                        originX: 0
                                    }}
                                    transition={{type: "spring", stiffness: 300}}
                                >
                                    <Link className={"link"} to={"/techlinesdetailsscreen"}>
                                        <ClearAllRounded/>
                                        <>&nbsp;&nbsp;</>
                                        Líneas
                                    </Link>
                                </NavLink>
                                <NavLink
                                    initial={false}
                                    animate={props.isOpen ? "show" : "hide"}
                                    variants={{
                                        show: {
                                            ...variants.show,
                                            transition: {delay: 0.6, duration: 0.8},
                                        },
                                        hide: {
                                            ...variants.hide,
                                            transition: {delay: 0.1, duration: 1},
                                        },
                                    }}
                                    whileHover={{
                                        scale: 1.3,
                                        originX: 0
                                    }}
                                    transition={{type: "spring", stiffness: 300}}
                                >
                                    <Link className={"link"} to={"/matchescompleteddetailsscreen"}>
                                        <DoneOutlineRounded/>
                                        <>&nbsp;&nbsp;</>
                                        Completados
                                    </Link>
                                </NavLink>
                                <NavLink
                                    initial={false}
                                    animate={props.isOpen ? "show" : "hide"}
                                    variants={{
                                        show: {
                                            ...variants.show,
                                            transition: {delay: 0.7, duration: 0.8},
                                        },
                                        hide: {
                                            ...variants.hide,
                                            transition: {delay: 0.1, duration: 1},
                                        },
                                    }}
                                    whileHover={{
                                        scale: 1.3,
                                        originX: 0
                                    }}
                                    transition={{type: "spring", stiffness: 300}}
                                >
                                    <Link className={"link"} to={"/statisticsscreen"}>
                                        <EqualizerRoundedIcon/>
                                        <>&nbsp;&nbsp;</>
                                        Estadísticas
                                    </Link>
                                </NavLink>
                            </>
                            : <></>
                        }{
                        props.userRoles.includes("ADMIN") && <NavTitle
                            animate={props.isOpen ? "show" : "hide"}
                            variants={{
                                show: {
                                    ...variants.show,
                                    transition: {delay: 0.8, duration: 0.8},
                                },
                                hide: {
                                    ...variants.hide,
                                    transition: {delay: 0.1, duration: 1},
                                },
                            }}
                        >
                            Fuentes de información
                        </NavTitle>
                    }
                        {/*{props.userRoles.includes("ADMIN") || props.userRoles.includes("PARTNER") ?
                            <>
                                <NavLink
                                    style={{marginTop: '20px'}}
                                    initial={false}
                                    animate={props.isOpen ? "show" : "hide"}
                                    variants={{
                                        show: {
                                            ...variants.show,
                                            transition: {delay: 0.9, duration: 0.8},
                                        },
                                        hide: {
                                            ...variants.hide,
                                            transition: {delay: 0.1, duration: 1},
                                        },
                                    }}
                                    whileHover={{
                                        scale: 1.3,
                                        originX: 0
                                    }}
                                    transition={{type: "spring", stiffness: 300}}
                                >
                                    <Link className={"link"} to={"/informationsources"}>
                                        <DataObjectIcon/>
                                        <>&nbsp;&nbsp;</>
                                        Patentes y marcas
                                    </Link>
                                </NavLink>

                            </>
                            : <></>
                        }*/}
                        {props.userRoles.includes("ADMIN") ?
                            <>
                                <NavLink
                                    style={{marginTop: '30px'}}
                                    initial={false}
                                    animate={props.isOpen ? "show" : "hide"}
                                    variants={{
                                        show: {
                                            ...variants.show,
                                            transition: {delay: 1, duration: 0.8},
                                        },
                                        hide: {
                                            ...variants.hide,
                                            transition: {delay: 0.1, duration: 1},
                                        },
                                    }}
                                    whileHover={{
                                        scale: 1.3,
                                        originX: 0
                                    }}
                                    transition={{type: "spring", stiffness: 300}}
                                >
                                    <Link className={"link"} to={"/capacitiesinfocompaniesdetail"}>
                                        <StorageRoundedIcon/>
                                        <>&nbsp;&nbsp;</>
                                        Buscador de capacidades
                                    </Link>
                                </NavLink>

                            </>
                            : <></>
                        }
                        <NavTitle
                            style={{marginTop: '20px'}}
                            animate={props.isOpen ? "show" : "hide"}
                            variants={{
                                show: {
                                    ...variants.show,
                                    transition: {delay: 0.9, duration: 0.8},
                                },
                                hide: {
                                    ...variants.hide,
                                    transition: {delay: 0.1, duration: 1},
                                },
                            }}
                        >
                            Sesión
                        </NavTitle>
                        <NavLink
                            initial={false}
                            animate={props.isOpen ? "show" : "hide"}
                            variants={{
                                show: {
                                    ...variants.show,
                                    transition: {delay: 1, duration: 0.8},
                                },
                                hide: {
                                    ...variants.hide,
                                    transition: {delay: 0.1, duration: 1},
                                },
                            }}
                            whileHover={{
                                scale: 1.3,
                                originX: 0
                            }}
                            transition={{type: "spring", stiffness: 300}}
                        >
                            <Link className={"link"} to={"/login"}
                                  onClick={props.handleLogout}>
                                <ExitToAppOutlinedIcon/>
                                <>&nbsp;&nbsp;</>
                                Desconectar
                            </Link>
                        </NavLink>
                    </>
                }
            </NavList>
        </NavMenuContainer>
    );
}