import React from "react";
import "aos/dist/aos.css"
import {
    BoldLink,
    BoxContainer,
    FormContainer,
    Input,
    SubmitButton,
    TextGrey
} from "../../../styles/commonArea/accountAccessStyles/CommonStyles";
import Typography from "@material-ui/core/Typography";
import signUpStyles from "../../../styles/commonArea/userManagementStyles/SignUpStyles";

function SignUpFormView(props) {

    const classes = signUpStyles();

    return (
        <BoxContainer>
            <FormContainer onSubmit={props.handleSubmit(props.customSubmit)}>
                <Input ref={props.register} type="text" name={"name"} placeholder="Nombre"/>
                <Typography component={'div'} className={classes.warmText}>
                    {props.errors.name?.message}
                </Typography>
                <Input ref={props.register} type="text" name={"lastName"} placeholder="Apellidos"/>
                <div>
                    <Typography component={'div'} className={classes.warmText}>
                        {props.errors.lastName?.message}
                    </Typography>
                </div>
                <Input ref={props.register} type="email" name={"email"} placeholder="Email"/>
                <div>
                    <Typography component={'div'} className={classes.warmText}>
                        {props.errors.email?.message}
                        {props.errorMessageMail}
                    </Typography>
                </div>
                <Input ref={props.register} type="text" name={"phone"} placeholder="Teléfono"/>
                <div>
                    <Typography component={'div'} className={classes.warmText}>
                        {props.errors.phone?.message}
                    </Typography>
                </div>
                <Input ref={props.register} type="password" name={"password"} placeholder="Password"/>
                <div>
                    <Typography component={'div'} className={classes.warmText}>
                        {props.errors.password?.message}
                    </Typography>
                </div>
                <div>&nbsp;&nbsp;</div>
                <SubmitButton type="submit">Regístrate</SubmitButton>
            </FormContainer>
            <TextGrey>
                ¿Ya tienes una cuenta?
                <BoldLink onClick={props.switchToSignin}
                          whileHover={{
                              boxShadow: "6px 6px 12px #bebebe, -6px -6px 12px #ffffff",
                          }}
                          transition={{type: "spring", stiffness: 300}}
                >
                    Accede
                </BoldLink>
            </TextGrey>
        </BoxContainer>
    )
        ;
}

export default SignUpFormView;