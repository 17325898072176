import React, {useEffect, useState} from "react";
import axios from "axios";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";
import {StatisticsScreenView} from "../../../views/adminArea/statisticsScreenViews/StatisticsScreenView";

export const StatisticsScreen = () => {
    const [companies, setCompanies] = useState();
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [chartHolderDiv, setChartHolderDiv] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [xAxisValue, setXAxisValue] = useState("");
    const [yAxisValue, setYAxisValue] = useState("");

    function insertXAxisCompaniesoOnMap(xAxisInfo, mapToCombineAxisInfo) {
        for (let i = 0; i < xAxisInfo.length; i++) {
            let companyName;
            for (let j = 0; j < selectedCompanies.length; j++) {
                if (selectedCompanies[j].id === parseInt(xAxisInfo[i].companyId)) {
                    companyName = selectedCompanies[j].name;
                    break
                }
            }
            let company = {companyId: xAxisInfo[i].companyId, name: companyName, x: xAxisInfo[i].averageValue}
            mapToCombineAxisInfo.set(xAxisInfo[i].companyId, company)
        }
    }

    function insertYaxisCompaniesOnMap(yAxisInfo, mapToCombineAxisInfo) {
        for (let i = 0; i < yAxisInfo.length; i++) {
            let companyInsertedOnMapAlready = mapToCombineAxisInfo.get(yAxisInfo[i].companyId);
            if (!companyInsertedOnMapAlready) {
                // should insert a company that has no pair y x or should I leave it out. (does not get print on the chart if has no pair , no error involved)?
            } else {
                companyInsertedOnMapAlready['y'] = yAxisInfo[i].averageValue;
                mapToCombineAxisInfo.set(yAxisInfo[i].companyId, companyInsertedOnMapAlready);
            }

        }
    }

    async function resultsFound(xAxisInfo, yAxisInfo) {
        if (xAxisInfo === undefined || yAxisInfo === undefined) {
            await Alert.fire({
                    title: "Ups...",
                    text: 'No se han encontrado resultados para la combinación seleccionada',
                    icon: 'warning',
                    timer: 2500,
                    showConfirmButton: false,
                    iconColor: colorPrimary
                }
            )
            setChartData([]);
            setXAxisValue('');
            setYAxisValue('');
            return false
        } else {
            return true
        }

    }

    useEffect(async () => {
        if (yAxisValue !== '' && xAxisValue !== '') {
            let mapToCombineAxisInfo = new Map();
            setChartData([]);

            let xAxisInfo = await getElementDataForAxis(xAxisValue, 'x').then(res => res);
            let yAxisInfo = await getElementDataForAxis(yAxisValue, 'y').then(res => res);

            if (await resultsFound(xAxisInfo, yAxisInfo)) {
                insertXAxisCompaniesoOnMap(xAxisInfo, mapToCombineAxisInfo);
                insertYaxisCompaniesOnMap(yAxisInfo, mapToCombineAxisInfo);
                setChartData(Array.from(mapToCombineAxisInfo.values()));
                setChartHolderDiv(false);
            }
        }
    }, [xAxisValue, yAxisValue])

    async function getXAxisValuesOnChange(event) {
        setXAxisValue(event.target.value);
        await getElementDataForAxis(event.target.value, "x");

    }

    async function getYAxisValuesOnChange(event) {
        setYAxisValue(event.target.value);
        await getElementDataForAxis(event.target.value, "y");
    }

    useEffect(async () => {
        await getAllCompanies()
    }, []);

    async function getAllCompanies() {
        const url = "company/allcompanies"
        await axios({
            method: 'GET',
            url,
            data: {},
        }).then(res => {
                setCompanies(res.data);
                setLoading(false)
            }, error => {
                Alert.fire({
                        title: "Ups...",
                        text: 'Ha ocurrido un error al cargar los matches, si el problema persiste contacta con soporte',
                        icon: 'warning',
                        timer: 2500,
                        showConfirmButton: false,
                        iconColor: colorPrimary
                    }
                )
            }
        ).catch((error) => {

        });
    }

    function getIdsFromCompaniesSelected() {
        let companyIds = []
        for (let i = 0; i < selectedCompanies.length; i++) {
            companyIds.push(selectedCompanies[i].id);
        }
        return companyIds
    }

    function ifNocompaniesSelected() {
        return selectedCompanies.length === 0 || selectedCompanies.length === undefined;
    }

    function resetAxisComboValues() {
        setXAxisValue('');
        setYAxisValue('');
    }

    async function throwAlertForUser() {
        await Alert.fire({
                title: "Ups...",
                text: 'Antes debes Seleccionar las entidades a consultar',
                icon: 'info',
                timer: 2000,
                showConfirmButton: false,
                iconColor: colorPrimary
            }
        )
    }

    async function getElementDataForAxis(optionSelected) {
        if (ifNocompaniesSelected()) {
            resetAxisComboValues();
            await throwAlertForUser();
            return
        }
        const url = "/statistics/charts/" + optionSelected
        let idsFromCompaniesSelected = getIdsFromCompaniesSelected();
        return await axios.put(url, idsFromCompaniesSelected).then(res => {
                return res.data;
            }, error => {
                return undefined
            }
        ).catch((error) => {
        });
    }

    function companiesSelected(selectedCompanies) {
        const selectedIDs = new Set(selectedCompanies);
        const selectedRowData = companies.filter((row) =>
            selectedIDs.has(row.id.toString())
        );
        setSelectedCompanies(selectedRowData);
    }


    return (
        <StatisticsScreenView
            loading={loading}
            getXAxisValuesOnChange={getXAxisValuesOnChange}
            getYAxisValuesOnChange={getYAxisValuesOnChange}
            companies={companies}
            companiesSelected={companiesSelected}
            xAxisValue={xAxisValue}
            yAxisValue={yAxisValue}
            chartData={chartData}
            chartHolderDiv={chartHolderDiv}
        />
    )
}
