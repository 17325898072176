import React, {useEffect} from "react";
import assetsCollaborationFormStyles from "../../../styles/userArea/assetsFormStyles/AssetsCollaborationFormStyles";
import {Controller} from "react-hook-form";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Slider,
    Typography
} from "@material-ui/core";

import CollaborationAgents from "../../../components/userArea/StepperComponents/CollaborationAgents";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import "aos/dist/aos.css"
import aos from "aos"

function AssetsCollaborationFormView(props) {

    const classes = assetsCollaborationFormStyles();

    useEffect(() => {
        aos.init({duration: 2000})
    }, []);

    const marksTechFrontierPosition = [
        {
            value: -3,
            label: 'Debilidad',
        },
        {
            value: 3,
            label: 'Fortaleza',
        }
    ];

    const marksPeopleHasKnowledge = [
        {
            value: 1,
            label: 'Muy pocas personas',
        },
        {
            value: 7,
            label: 'Toda la organización',
        }
    ];

    const marksCollaborationWillingness = [
        {
            value: 1,
            label: 'Muy baja',
        },
        {
            value: 7,
            label: 'Muy alta',
        }
    ];

    let isAssetApplicant = props.assetApplicantChosen
    return (
        <form
            onSubmit={props.handleSubmit(props.customSubmit)}>
            <Paper className={classes.paper} elevation={12} style={{overflowX: "hidden"}} data-aos={"fade-left"}>
                <br></br>
                <h3 className={classes.legendText} component="legend">
                    Información adicional y de colaboración
                </h3>
                <Grid container direction="row">
                    <Grid item xs={1}></Grid>
                    <Grid container direction="column" item xs>
                        <Grid item xs>
                            <div>
                                <Grid container>
                                    <Grid item>
                                        <Typography component={'div'} id="discrete-slider" gutterBottom>
                                            <h6>
                                                Indica la proporción de personas de la organización que disponen de
                                                dicho
                                                conocimiento
                                            </h6>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <h6 style={{color: "red"}}>
                                            *
                                        </h6>
                                    </Grid>
                                </Grid>
                                <Controller
                                    name="peopleHasKnowledge"
                                    control={props.control}
                                    defaultValue={props.peopleHasKnowledgeChosen}
                                    render={(props) => (
                                        <Slider
                                            {...props}
                                            onChange={(_, value) => {
                                                props.onChange(value);
                                            }}
                                            valueLabelDisplay="auto"
                                            max={7}
                                            step={1}
                                            min={1}
                                            marks={marksPeopleHasKnowledge}
                                            disabled={isAssetApplicant}
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        <div>&nbsp;</div>
                        <Grid item xs>
                            <div>
                                <Grid container>
                                    <Grid item>
                                        <Typography component={'div'} id="discrete-slider" gutterBottom>
                                            <h6>
                                                ¿Cuál crees que es el posicionamiento de la entidad en este activo
                                                intangible
                                                con respecto a
                                                la media del sector? Considera el 0 como la media
                                            </h6>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <h6 style={{color: "red"}}>
                                            *
                                        </h6>
                                    </Grid>
                                </Grid>

                                <Controller
                                    name="techFrontierPosition"
                                    control={props.control}
                                    defaultValue={isAssetApplicant ? -3 : props.techFrontierPositionChosen}
                                    render={(props) => (
                                        <Slider
                                            {...props}
                                            onChange={(_, value) => {
                                                props.onChange(value);
                                            }}
                                            valueLabelDisplay="auto"
                                            max={3}
                                            min={-3}
                                            step={1}
                                            marks={marksTechFrontierPosition}
                                            disabled={isAssetApplicant}
                                        />
                                    )}
                                />
                            </div>
                        </Grid>
                        <div>&nbsp;</div>
                        <Grid item xs>
                            <div>
                                <Typography component={'div'}>
                                    <h6>
                                        En caso de no ser tu entidad, indica el nombre del líder tecnológico del
                                        sector de actividad
                                    </h6>
                                </Typography>
                                <Grid container direction="row">
                                    <Grid item xs></Grid>
                                    <Grid container direction="column" wrap="nowrap" item xs>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="techLeader"
                                                    color="primary"
                                                    onChange={(event) => props.customChangeTechLeader(event)}
                                                    inputRef={props.register}
                                                />
                                            }
                                            label={"Nuestra organización lidera el sector"}
                                            name="techLeader"
                                        />
                                        <h6>Líder del sector</h6>
                                        <Controller
                                            defaultValue={props.techLeaderName.value}
                                            as={
                                                <TextareaAutosize
                                                    defaultValue={props.techLeaderName}
                                                    name="techLeaderName"
                                                    className={classes.textAreaAutosize}
                                                    rowsMin={2}
                                                    variant="outlined"
                                                    disabled={props.techLeader.toString() === 'true' ? true : false}
                                                />
                                            }
                                            name="techLeaderName"
                                            control={props.control}
                                        />
                                    </Grid>
                                    <Grid item xs></Grid>
                                </Grid>
                                <FormLabel className={classes.warmText}>
                                    {props.errors.techLeaderName?.message}
                                </FormLabel>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <div>&nbsp;</div>
                <Grid container direction="row">
                    <Grid item xs={1}></Grid>
                    <Grid container direction="row" item xs>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item>
                                    <h6>
                                        ¿Mantiene la entidad actualmente algún acuerdo de colaboración para la gestión o
                                        explotación de
                                        este activo?
                                    </h6>
                                </Grid>
                                <Grid item>
                                    <h6 style={{color: "red"}}>
                                        *
                                    </h6>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <section>
                                <Controller
                                    defaultValue={isAssetApplicant ? "0" : props.hasCollabsChosen}
                                    as={
                                        <RadioGroup aria-label="hasCollabs"
                                                    value={props.value}
                                                    required
                                        >
                                            <Grid container direction="row">
                                                <Grid item xs></Grid>
                                                <Grid container direction="column" wrap="nowrap" item xs>
                                                    <FormControlLabel
                                                        control=
                                                            {
                                                                <Radio inputRef={props.register}
                                                                       name="yes"
                                                                       value="1"
                                                                       onChange={(event) => props.setHasCollabs(event.target.value)}
                                                                       color="primary"
                                                                       disabled={isAssetApplicant}
                                                                />
                                                            }
                                                        label={
                                                            <Box className={classes.formControlLabel}>
                                                                Sí
                                                            </Box>
                                                        }
                                                    />
                                                    <FormControlLabel
                                                        control=
                                                            {
                                                                <Radio inputRef={props.register}
                                                                       name="no"
                                                                       value="0"
                                                                       onChange={(event) => props.setHasCollabs(event.target.value)}
                                                                       color="primary"
                                                                       disabled={isAssetApplicant}
                                                                />
                                                            }
                                                        label=
                                                            {
                                                                <Box className={classes.formControlLabel}>
                                                                    No
                                                                </Box>
                                                            }
                                                    />
                                                </Grid>
                                                <Grid item xs></Grid>
                                            </Grid>
                                        </RadioGroup>
                                    }
                                    name="hasCollabs"
                                    control={props.control}
                                />
                                {
                                    props.errors.hasCollabs &&
                                    <FormLabel className={classes.warmText}>
                                        Debes seleccionar si actualmente mantiene acuerdo de colaboración
                                    </FormLabel>
                                }
                                {props.hasCollabs === "1" &&
                                <div>
                                    <Grid item xs>
                                        <Grid container>
                                            <Grid item>
                                                <h6>Indica con cuál de los siguientes agentes</h6>
                                            </Grid>
                                            <Grid item>
                                                <h6 style={{color: "red"}}>
                                                    *
                                                </h6>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <CollaborationAgents
                                        register={props.register}
                                        nameOfOptions={"collabsWithAsset"}
                                        chosenOptions={props.collabsWithChosen}
                                        setStateOptions={props.setCollabsWith}
                                        name={"collabsWith"}
                                    />
                                </div>
                                }
                                {
                                    props.errors.collabsWithAsset &&
                                    <FormLabel className={classes.warmText}>
                                        Debes seleccionar con que tipo de entidad mantiene colaboración actualmente.
                                    </FormLabel>
                                }
                            </section>
                            <div>&nbsp;</div>
                            <Grid item xs>
                                <div>
                                    <Grid container>
                                        <Grid item>
                                            <Typography component={'div'} id="discrete-slider" gutterBottom>
                                                <h6>
                                                    Valora la predisposición de la entidad a colaborar con otros agentes
                                                    para el
                                                    desarrollo o la mejora de este activo, o su
                                                    explotación económica
                                                </h6>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <h6 style={{color: "red"}}>
                                                *
                                            </h6>
                                        </Grid>
                                    </Grid>

                                    <Controller
                                        defaultValue={props.collaborationWillingnessChosen}
                                        render={(renderProps) => (
                                            <Slider
                                                {...renderProps}
                                                onChange={(_, value) => {
                                                    renderProps.onChange(value);
                                                    props.setCollaborationWillingness(value);
                                                }}
                                                valueLabelDisplay="auto"
                                                max={7}
                                                step={1}
                                                min={1}
                                                marks={marksCollaborationWillingness}
                                            />
                                        )}
                                        name="collaborationWillingness"
                                        control={props.control}
                                    />
                                </div>
                                <div>&nbsp;</div>
                                {props.collaborationWillingness >= 4 &&
                                <div>
                                    <Grid item xs>
                                        <Grid container>
                                            <Grid item>
                                                <h6>Indica con qué tipo de entidad prefieres colaborar</h6>
                                            </Grid>
                                            <Grid item>
                                                <h6 style={{color: "red"}}>
                                                    *
                                                </h6>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <CollaborationAgents
                                        register={props.register}
                                        nameOfOptions={"collaborationWillingnessPreferenceAsset"}
                                        chosenOptions={props.collaborationWillingnessPreferenceChosen}
                                        setStateOptions={props.setCollaborationWillingnessPreference}
                                        name={"collaborationWillingnessPreference"}
                                    />

                                    {
                                        props.errors.collaborationWillingnessPreferenceAsset &&
                                        <FormLabel className={classes.warmText}>
                                            Debes seleccionar con que tipo de entidad prefieres
                                            colaborar</FormLabel>
                                    }
                                </div>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <hr className={classes.hrForm}></hr>
            <div>
                <Button variant="contained" type="submit" className={classes.saveButton}>Siguiente</Button>
            </div>
        </form>
    );
}

export default AssetsCollaborationFormView;