import Styled from "styled-components"
import {colorPrimary} from "../../../EnvironmentVars";
import React from "react";

export const TitleMatchDetails = Styled.h3`
margin : 30px;
color: ${colorPrimary};
`

export const SubTitleMatchDetails = Styled.h5`
margin : 30px;
color: ${colorPrimary};
`

export const InnerTitleMatchDetails = Styled.h6`
margin : 0px;
padding : 0px;
color: ${colorPrimary};
`