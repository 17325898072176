import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import homeCardStyles from "../../../styles/commonArea/homeStyles/HomeCardStyles";
import {colorPrimary} from "../../../EnvironmentVars";
import Tilty from "react-tilty";
import styled from 'styled-components'
import {motion} from "framer-motion";

const Img = styled(motion.img)`boxShadow: "10px 10px 0 rgba(0, 0, 0, 0.2)"`
const TypographyAnimated = styled(motion.div)``

export default function HomeCard(props) {
    const classes = homeCardStyles();

    return (
        <div>

            <Tilty whileHover={{scale: 1.01}}
                   transition={{duration: 0.8}}
            >
                <Card className={classes.root}>
                    <Img
                        className={classes.cardImage}
                        src={props.image}
                        alt={"abstract logo"}
                        whileHover={{scale: 1.1}}
                        transition={{duration: 0.5}}

                    />
                    <CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2" style={{color: colorPrimary}}>
                                {props.title}
                            </Typography>
                            <div className={classes.homeCardText}>
                                <TypographyAnimated variant="body2" style={{color: colorPrimary}}
                                                    whileHover={{scale: 1.1}}
                                                    transition={{duration: 0.5}}
                                                    component="p">
                                    {props.text}
                                </TypographyAnimated>
                            </div>
                        </CardContent>
                    </CardActionArea>
                    <div>&nbsp;&nbsp;</div>
                </Card>
            </Tilty>
        </div>
    );
}
