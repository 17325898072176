import React from "react";
import Grid from "@material-ui/core/Grid";
import {
    SubTitleMatchDetails,
    TitleMatchDetails
} from "../../../styles/adminArea/matchesDetailsStyles/MatchesDetailsScreenStyles";
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    colorBlue,
    colorBlueGreen,
    colorBluePurple,
    colorPrimary,
    colorPrimaryTranslucid,
    colorTernary
} from "../../../EnvironmentVars";
import {DataGridStyled} from "../../../styles/adminArea/companiesDetailsScreen/CompanyDetailsScreenStyles";
import IconButton from "@material-ui/core/IconButton";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const techlinesDataGridcolumns = [{
    field: 'id', headerName: 'id', description: 'techLineCompanyId', flex: 1, hide: true,
}, {
    field: 'companyName', headerName: 'Nombre entidad', description: 'Nombre entidad', flex: 1
}, {
    field: 'techLineId', headerName: 'id', description: 'id de la línea (TechLine)', flex: 1, hide: true,
}, {
    field: 'name', headerName: 'Línea', description: 'Línea tecnológica', flex: 1
}, {
    field: 'collaborationWillingness',
    headerName: 'Predisposición a colaborar',
    description: 'Predisposición a colaborar, escala de 1 a 7',
    flex: 1,
    valueGetter: (params) => {
        return params.row.collaborationWillingness !== null ? params.row.collaborationWillingness : "N/A"
    }
}, {
    field: 'techFrontierPosition',
    headerName: 'Posicionamiento',
    description: 'posicionamiento de la empresa frente a la frontera de conocimiento, escala de -3 a 3',
    flex: 1,
    valueGetter: (params) => {
        return params.row.techFrontierPosition !== null ? params.row.techFrontierPosition : "N/A"
    }
},

];

const barColors = [colorPrimary, colorTernary, colorBlueGreen, colorBlue, colorBluePurple]

export const TechLinesDetailsScreenView = (props) => {
    return (<Grid container spacing={0} justify={"center"} align={"center"}>
        {props.techLinesDetails && !props.loading ? <>
            <Grid item xs={12} md={6}>
                <Grid item xs={12}>
                    <TitleMatchDetails>Detalles de líneas</TitleMatchDetails>
                </Grid>
                <SubTitleMatchDetails>Líneas más utilizadas</SubTitleMatchDetails>
                <ResponsiveContainer width="80%" height="75%">
                    <BarChart
                        data={props.mostUsedTechLines}
                    >
                        <XAxis dataKey="name"/>
                        <YAxis dataKey="value"/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip cursor={{fill: colorPrimaryTranslucid}}/>
                        <Bar
                            dataKey="value"
                            strokeWidth={1}
                        >
                            {props.mostUsedTechLines.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={barColors[index % 20]}/>))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </> : <CircularProgress/>}
        {props.techLinesDetails && !props.loading && <Grid container spacing={0} justify={"center"} align={"center"}>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
                <IconButton variant="contained" onClick={props.handleCSVDownload}>
                    <DownloadForOfflineIcon sx={{color: colorPrimary}}/>&nbsp;CSV
                </IconButton>
            </Grid>
            <div style={{height: "100vh", width: '100%'}}>
                <DataGridStyled
                    rows={props.techLinesDetails}
                    columns={techlinesDataGridcolumns}
                    pageSize={22}
                    loading={props.loading}
                    color={'linear-gradient(52deg, ' + colorPrimary + ' 0%, ' + colorBlueGreen + '  100%)'}
                />
            </div>
        </Grid>}
    </Grid>)
}