import React, {useEffect} from "react";
import capacitiesFormStyles from "../../../styles/userArea/capacitiesFormStyles/CapacitiesFormStyles";
import {Controller} from "react-hook-form";
import {Box, Button, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import aos from "aos"
import "aos/dist/aos.css"

function CapacitiesFormView(props) {

    const classes = capacitiesFormStyles();

    useEffect(() => {
        aos.init({duration: 1000})
    }, []);

    return (
        <form component="fieldset" onSubmit={props.handleSubmit(props.customSubmit)} style={{overflowX: "hidden"}}>
            <Paper className={classes.paper} elevation={12} data-aos={"zoom-out"}>
                <div className={classes.capacities}>
                    <h3 className={classes.legendText} component="legend">
                        Capacidades
                    </h3>
                    <FormControlLabel

                        control={
                            <Checkbox
                                inputRef={props.register}
                                name="capacityApplicant"
                                color="primary"
                                onChange={(e) => props.handleCheckCapacityApplicant(e)}
                                icon={<RadioButtonUncheckedOutlinedIcon/>}
                                checkedIcon={<CheckCircleOutlineOutlinedIcon/>}
                                checked={props.capacityApplicant}
                            />
                        }
                        name="capacityApplicant"
                        label="No dispongo de esta capacidad pero me gustaría incorporarla"
                    />
                    <hr className={classes.hrCapacities}></hr>
                    <Controller
                        name="selected"
                        defaultValue={props.selectedCapacityId}

                        as={
                            <RadioGroup aria-label="selected"
                                        aria-required={true} required
                                        value={props.value}
                                        onChange={props.handleChange}
                            >
                                <Grid container direction="row">
                                    <Grid item xs={3}></Grid>
                                    <Grid container direction="column" item xs={9}>
                                        {
                                            props.capacities.map(
                                                capacity =>
                                                    <div key={capacity.id}>
                                                        <Grid item xs={10}>
                                                            <FormControlLabel
                                                                key={capacity.id}
                                                                control=
                                                                    {
                                                                        <Radio
                                                                            className={classes.formControlRadio}
                                                                            inputRef={props.register}
                                                                            name={capacity.id.toString()}
                                                                            value={capacity.name}
                                                                            onChange={(event) => props.handleChangeItemId(capacity.id)}
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                label={
                                                                    <Box className={classes.formControlLabel}>
                                                                        {capacity.name}
                                                                    </Box>
                                                                }
                                                            />
                                                        </Grid>
                                                    </div>
                                            )
                                        }
                                        <Grid item xs={2}></Grid>
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        }
                        control={props.control}>
                    </Controller>
                </div>
            </Paper>
            {
                props.errors.selected &&
                <FormLabel className={classes.warmText}
                           color='secondary'> Debes seleccionar una capacidad</FormLabel>
            }
            <hr className={classes.hrCapacities}></hr>
            <div>
                <Button variant="contained" type="submit" className={classes.saveButton}>Siguiente</Button>
            </div>
        </form>
    );
}

export default CapacitiesFormView;