import React from "react";
import {
    BoldLink,
    BoxContainer,
    FormContainer,
    Input,
    SubmitButton
} from "../../../styles/commonArea/accountAccessStyles/CommonStyles";
import {Typography} from "@material-ui/core";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import signUpStyles from "../../../styles/commonArea/userManagementStyles/SignUpStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

export const ResetPasswordSendMailView = (props) => {

    const classes = signUpStyles();


    return (
        <BoxContainer>
            {!props.loading ? <>
                    <FormContainer onSubmit={props.handleSubmit(props.customSubmit)}>
                        <Input ref={props.register} name="email" type={"email"} placeholder="Correo"/>
                        <div>&nbsp;&nbsp;</div>
                        <Typography component={'div'} className={classes.warmText}>
                            {props.errors.email?.message}
                        </Typography>
                        <div>&nbsp;&nbsp;</div>

                        <SubmitButton type="submit">
                            <div>
                                Envia<>&nbsp;&nbsp;</>
                                <SendOutlinedIcon color={"primary"}/>
                            </div>
                        </SubmitButton>

                    </FormContainer>
                    <div>&nbsp;&nbsp;</div>
                    <BoldLink onClick={props.switchToSignin}
                              whileHover={{
                                  boxShadow: "6px 6px 12px #bebebe, -6px -6px 12px #ffffff",
                              }}
                              transition={{type: "spring", stiffness: 300}}>Oh! recuerdo mi contraseña</BoldLink>
                </>
                :
                <Grid container align={"center"} justify={"center"}>
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            }
        </BoxContainer>
    )
}