import React, {useState} from "react";
import theme from "../../../Theme";
import {ThemeProvider} from "@material-ui/styles";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import axios from "axios";
import Alert from "sweetalert2";
import {colorPrimary} from "../../../EnvironmentVars";
import {useHistory} from "react-router-dom";
import ResetPasswordChangePasswordViewNew
    from "../../../views/commonArea/userManagementViews/ResetPasswordChangePasswordView";

export default function ResetPasswordChangePassword(props) {
    const history = useHistory();
    const [email, seteamil] = useState("");
    const [loading, setLoading] = useState(false);

    const passwordResetValidationSchema = yup.object().shape({
        password: yup.string()
            .required("Campo requerido")
            .min(4, "el password debe contener al menos 4 caracteres")
            .max(500, "El password no puede exceder de 500 caracteres"),
    })

    const {register, errors, handleSubmit} = useForm(
        {
            resolver: yupResolver(passwordResetValidationSchema),
        }
    );

    async function customSubmit(data) {
        setLoading(true);
        if (data.password !== data.confirmPass) {
            Alert.fire({
                    title: "Ups... ",
                    text: "lo sentimos, las contraseñas no coinciden",
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonText: "De acuerdo!"
                }
            )
            setLoading(false);
            return;
        }
        let url = window.location.href.split("/");
        let token = url[url.length - 1];
        await axios.post("/resetpassword/" + token, data.password)
            .then((res) => {
                    Alert.fire({
                            text: "Se ha modificado la contraseña con exito",
                            icon: 'success',
                            timer: 2500,
                            showConfirmButton: false,
                            iconColor: colorPrimary
                        }
                    )
                    setLoading(false);
                    history.push("/login");
                },
                error => {
                    Alert.fire({
                            title: "Lo sentimos, algo ha salido mal",
                            text: "No se ha podido modificar la contraseña, vuelve a intentarlo en unos minutos",
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: "De acuerdo!",
                            confirmButtonColor: colorPrimary
                        }
                    )
                    setLoading(false);
                }
            ).catch(error => {

                }
            );
    }

    return (
        <ThemeProvider theme={theme}>
            <ResetPasswordChangePasswordViewNew
                handleSubmit={handleSubmit}
                customSubmit={customSubmit}
                register={register}
                errors={errors}
                loading={loading}
            />
        </ThemeProvider>
    )
}