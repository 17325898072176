import {Controller} from "react-hook-form";
import React from "react";
import {Slider} from "@material-ui/core";

const marksSlider = [
    {
        value: 1,
        label: 'Muy baja',
    },
    {
        value: 7,
        label: 'Muy alta',
    }
];

export const SliderCustom = (props) => {

    return (<>
            <Controller
                defaultValue={props.value}
                render={(renderProps) => (
                    <Slider
                        {...renderProps}
                        onChange={(_, value) => {
                            renderProps.onChange(value);
                            if (props.valueSetter) {
                                props.valueSetter(value);
                            }
                        }}
                        valueLabelDisplay="auto"
                        max={7}
                        step={1}
                        min={1}
                        marks={marksSlider}
                    />
                )}
                name={props.name}
                control={props.control}
            />
        </>
    )
}